import { Button } from "@mui/material";
import { primary } from "../../../../../../../assets/styles/colors";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type LinkedContainerItemProps = {
    id: number;
    containerNumber: string;
    onRemove: (id: number) => void;
    storagePrice: number;
}


export const LinkedContainerItem = ({ id, containerNumber, onRemove, storagePrice }: LinkedContainerItemProps) => {
    return (
        <div style={{ display: 'flex', gap: '3px', alignItems: 'stretch' }}>
            <Button variant="outlined" color="primary" size="medium" onClick={() => onRemove(id)}>
                <DeleteOutlineIcon />
            </Button>
            <div style={{
                border: `2px solid ${primary[40]}`,
                borderRadius: '12px',
                padding: '5px 16px',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <span>{`№ ${containerNumber}`}</span>
                {!!storagePrice && <span style={{ fontSize: '14px' }}>{`Хранение: ${storagePrice} ₽`}</span>}
            </div>
        </div>
    )
}