import React from 'react';
import UniversalBlock from '../components/UniversalBlock';
import styles from './SideStyle.module.scss';
import { useSelector } from 'react-redux';
import { isBlockChoosen } from './helpers/isBlockChoosen';

function FloorSide({ feet, selectedSide, setSmthSelected, selectedAreas, selectedDamageDetails=[], damagesList = [] }) {
	// const { damagesList, selectedSide, entryOneReport } = useSelector((state) => state.containerAccept);
	// const { placement_code_description } = selectedSide;

	const { placement_code_description, code, components } = selectedSide;

	return (
		<div className={styles.mainBlock_new}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p>
				{placement_code_description[0].parts[`${feet}-feet`]
					.slice()
					.map((part) => (
						<p key={part} className={styles.topNumber_new}>
							{part}
						</p>
					))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>
					{parts[`${feet}-feet`].slice().map((part, index) => (
						<UniversalBlock
							key={index}
							type={getType(option, part)}
							sideIndex={'B'}
							sideName={'Пол'}
							divider={option}
							part={part.toString()}
							partCode={getPartCode(option, part)}
							choosen={isBlockChoosen(damagesList, 'B', option, part.toString())}
							onClick={setSmthSelected}
							selectedAreas={selectedAreas}
						/>
					))}
				</div>
			))}
		</div>
	);
}

function getType(option, part) {
	if (option === 'L' || option === 'R') {
		if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(part)) {
			return 'mainPanel';
		}
	}
}

function getPartCode(option, part) {
	// Здесь можно добавить логику для получения кода детали, если требуется
	return `${option}-${part}`;
}

export default FloorSide;
