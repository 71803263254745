import api from '../../api.js';

export const permissions = api.injectEndpoints({
	endpoints: (builder) => ({
		getPermissionsList: builder.query({
			query: () => {
				return {
					url: '/api/erp/permissions/list/',
					method: 'GET',
				};
			},
		}),
	}),
});

export const { useGetPermissionsListQuery, useLazyGetPermissionsListQuery } = permissions;
