import { Button, Typography } from "@mui/material"
import { RepairTasksInputAutocomplete } from '../../../../../../../newComponents/Form/Fields/RepairTasksInputAutocomplete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './ChooseServicesStep.module.css'
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText";

const isStepEditable = (stepName) => {
    return ['ТЕУ/ДФЭ', 'Штуки'].includes(stepName);
}

export const ChooseRepairTasks = ({
    repairTasks,
    disableEdit,
    handleChangeStep,
    showAddForm,
    setShowAddForm,
    totalPrice,
    containerInfo,
    handleAddRepairTask,
    handleChangePrice,
    price,
    handleChangePercent,
    discount,
    discountValue,
    handleUpdatePercent,
    handleUpdatePrice,
    handleDeleteTask,
    totalMaterialPrice,
    totalLaborPrice
}) => {
    return (
        <>
            <Typography variant="h6">
                Выбор услуги
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '12px' }}>
                    {repairTasks.map(rt => {
                        return (
                            <div className={styles.serviceItem} key={rt.id}>
                                <Button disabled={disableEdit} variant="outlined" color='primary' size="medium" onClick={() => handleDeleteTask(rt.id)}>
                                    <DeleteIcon />
                                </Button>
                                {/* @ts-ignore */}
                                <InputText
                                    value={rt.repair_task_display}
                                    labelText="Услуга"
                                    disabled
                                />
                                {/* @ts-ignore */}
                                <InputText
                                    defaultValue={rt.steps}
                                    labelText="Шаги"
                                    onBlur={(e) => handleChangeStep(rt.id, e.target.value)}
                                    disabled={!isStepEditable(rt.repair_task_unit) || disableEdit}
                                />
                            </div>
                        )
                    })}
                    {showAddForm && <div className={styles.serviceItem}>
                        <Button variant="outlined" color='primary' size="medium" onClick={() => setShowAddForm(false)}>
                            <DeleteIcon />
                        </Button>
                        <RepairTasksInputAutocomplete
                            searchParams={{ terminal_id: containerInfo?.terminal }}
                            labelText="Услуга"
                            onChange={handleAddRepairTask}
                        />
                    </div>}
                    {!showAddForm && !disableEdit && <Button variant="contained" color='primary' size="medium" sx={{ mt: '12px', width: '42px' }} onClick={() => setShowAddForm(true)}>
                        <AddIcon />
                    </Button>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {totalPrice && <div style={{ marginTop: '20px', display: 'flex', gap: '8px', alignItems: 'flex-end' }}>
                        <div style={{ width: '200px' }}>
                            {/* @ts-ignore */}
                            <InputText disabled={disableEdit} onChange={handleChangePrice} value={price} defaultValue={totalPrice} labelText='Сумма' onBlur={handleUpdatePrice} />
                        </div>
                        <div style={{ width: '100px' }}>
                            {/* @ts-ignore */}
                            <InputText disabled={disableEdit} onChange={handleChangePercent} value={discount} defaultValue={discountValue} labelText='Скидка' onBlur={handleUpdatePercent} icon="%" />
                        </div>
                    </div>}
                    {!!totalLaborPrice && <div>
                        {`Стоимость работ: ${totalLaborPrice} ₽`}
                    </div>}
                    {!!totalMaterialPrice && <div>
                        {`Стоимость материалов: ${totalMaterialPrice} ₽`}
                    </div>}
                </div>
            </div>
        </>
    )
}