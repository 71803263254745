import { useEffect, useMemo } from "react";
import { useGetVahicleByIdQuery } from "../../../../store/newApi/vehicles/vehicles";

export const useInitFormState = ({ ids, isReady, ...methods }) => {
    const { setValue } = methods;

    const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
    
    const { data } = useGetVahicleByIdQuery({ id: ids[0] }, {
        skip: !isSingleUpdate,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (data && isReady && isSingleUpdate) {
            setValue('registration_number', data.registration_number)
            setValue('trailer_number', data.trailer_number)
            setValue('driver', data.driver)
        }
    }, [data, setValue, isReady, isSingleUpdate]);
}