import { useCallback, useEffect, useMemo } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateTariffGroupMutation } from '../../../../store/newApi/service/service';

export const useHandleUpdate = ({ entityIds, handleClose, ...methods }) => {
	const isSingleUpdate = useMemo(() => entityIds.length === 1, [entityIds]);
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateTariff, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateTariffMutation }] =
		useUpdateTariffGroupMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			handleClose();
			resetUpdateTariffMutation();
			resetForm();
			// TODO: Вызвать нотификашку
		}

		return () => {
			resetForm();
		};
	}, [isUpdateSuccess, handleClose, resetUpdateTariffMutation, resetForm]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				services: entityIds,
				terminal: touchedFieldValues.terminal,
				name: touchedFieldValues.name,
				description: touchedFieldValues.description,
				price: touchedFieldValues.price && parseFloat(touchedFieldValues.price),
				unit: touchedFieldValues.unit,
			};
			updateTariff(payload);
		},
		[entityIds, updateTariff, touchedFields, isSingleUpdate],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
