/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import '../../assets/styles/Tables.scss';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Preloader } from '../../components';
import { tableComponent, toolbarAlertBanner } from './TableComponent.styles.js';
import { useSelector } from 'react-redux'; 
import useMediaQuery from '@mui/material/useMediaQuery';

//  rowSelection, setRowSelection - Выбор строк
//	hookName - Название хука
//  columnsList - Названия колонок и ключи
//  columnOrdering - Расположение колонок

const TableComponent = ({
	rowSelection,
	setRowSelection,
	columnsList,
	columnOrdering,
	filterName,
	fetchData,
	data,
	isLoading,
	isFetching = false,
	isError,
	resetFilters = () => {},
}) => {
	const filters = useSelector((state) => state[filterName]?.filter) ?? {};
	const [tableData, setTableData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [fetching, setFetching] = useState(false);
	const [sorting, setSorting] = useState([]);
	const [filtering, setFiltering] = useState(false);
	// прелоадер при бесконечном скролле
	const [isLoadingRows, setIsLoadingRows] = useState(false); 
	const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'));

	useEffect(() => {
		fetchData({ page: 1, page_size: 30 });
		setCurrentPage(1);
		resetFilters();
	}, []);

	useEffect(() => {
		fetchData({ ...filters, page: 1, page_size: 30, sorting });
		setCurrentPage(1);
		setRowSelection({});
	}, [sorting]);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		fetchData({ ...filters, page: 1, page_size: 30, uniq: Math.random(), sorting: [] });
		setCurrentPage(1);
		setRowSelection({});
		setFiltering(true);
		setSorting([]);
	}, [filters]);

	// бесконечная загрузка
	const tableContainerRef = useRef(null);

	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement) => {
			if (containerRefElement && !fetching && !isLoading) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
				if (scrollTop + clientHeight + 1 >= scrollHeight && scrollTop != 0) {
					if (data?.count > tableData?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						fetchData({ ...filters, page: nextPage, page_size: 30, sorting });
						setCurrentPage(nextPage);
						setIsLoadingRows(true);
					}
				}
			}
		},
		[currentPage, fetching, isLoading, sorting, data, tableData],
	);

	useEffect(() => {
		const handleScroll = () => fetchMoreOnBottomReached(tableContainerRef.current);
		const tableRef = tableContainerRef.current;

		if (tableRef) {
			tableRef.addEventListener('scroll', handleScroll);
			return () => tableRef.removeEventListener('scroll', handleScroll);
		}
	}, [fetchMoreOnBottomReached]);

	useEffect(() => {
		if (data && data.results) {
			setTableData((prevData) => {
				if (filtering || !data.previous) {
					setCurrentPage(1);
					// setSorting([]);
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
			setIsLoadingRows(false);
		}
	}, [data]);

	useEffect(() => {
		setIsLoadingRows(isLoading);
		setFetching(isLoading);
	}, [isLoading]);

	useEffect(() => {
		setIsLoadingRows(isFetching);
	}, [isFetching]);

	const table = useMaterialReactTable({
		data: tableData,
		columns: columnsList,

		// Cортировка
		enableSorting: true,
		manualSorting: true,
		onSortingChange: setSorting,

		// Отключение fullScreen режима
		enableFullScreenToggle: false,
		// Отключение скрытия колонок
		enableHiding: false,

		// Отключение фильтрации, поиска
		enableFilters: false,
		enableGlobalFilter: false,

		initialState: {
			// порядок колонок
			columnOrder: columnOrdering,
		},

		state: {
			rowSelection,
			sorting,
		},

		// отключить количество строк на странице
		enablePagination: false,

		// смена порядка колонок
		enableColumnOrdering: false,

		// русский язык
		localization: MRT_Localization_RU,

		// менять размер колонки кнопка в самой колонке
		enableColumnResizing: true,
		columnResizeMode: 'onChange',
		layoutMode: 'grid',

		// убрать кнопку меняющую ширину колонок в хедере
		enableDensityToggle: false,

		// выбор строк
		enableRowSelection: true,
		positionToolbarAlertBanner: 'bottom',
		onRowSelectionChange: setRowSelection,
		getRowId: (row) => row.id, 

		// стиль контейнера таблицы
		muiTablePaperProps: {
			sx: tableComponent.tablePaper,
		},

		// высота тела таблицы
		muiTableContainerProps: {
			ref: tableContainerRef,
			sx: tableComponent.tableContainer,

			onScroll: (event) => fetchMoreOnBottomReached(event.target),
		},

		// хедер
		muiTableHeadCellProps: {
			sx: tableComponent.header,
		},

		muiTopToolbarProps: {
			sx: tableComponent.topToolbar,
		},

		// липкий хедер
		enableStickyHeader: true,

		// футер таблицы
		muiBottomToolbarProps: {
			sx: tableComponent.footer,
		},

		// сообщение сколько выбрано строк
		muiToolbarAlertBannerProps: {
			sx: toolbarAlertBanner(isTablet),
		},

		renderBottomToolbarCustomActions: ({ table, row }) => (
			<div className="table__footer">
				{data?.count === 0 ? (
					<div>Нет результатов</div>
				) : (
					<div className="table__footer_preloader">
						{isLoadingRows && <Preloader small={true} />}
						Загружено {tableData?.length} из {data?.count}
					</div>
				)}
			</div>
		),
	});

	return (
		<>
			{isLoading ? (
				<Preloader bg={'true'} />
			) : isError ? (
				<div>Ошибка</div>
			) : (
				<div className="table__container">
					<MaterialReactTable table={table} />
				</div>
			)}
		</>
	);
};

export default TableComponent;
