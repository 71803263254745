import React, { useEffect, useState } from 'react';
import './Switcher.scss';

// function Switcher({ onChecked }) {
//   const [isChecked, setChecked] = useState(false);

//   const handleToggle = () => {
//     setChecked(!isChecked);
//   };

//   // функция при изменении
//   useEffect(() => {
//     onChecked(isChecked);
//   }, [isChecked, setChecked]);

//   return (
//     <label className="switcher">
//       <input type="checkbox" checked={isChecked} onChange={handleToggle} />
//       <span className="slider"></span>
//     </label>
//   );

function Switcher({ onChecked, externalChecked, disabled, right, left }) {
	const [isChecked, setChecked] = useState(externalChecked);

	const handleToggle = () => {
		const newChecked = !isChecked;
		setChecked(newChecked);
		onChecked(newChecked);
	};

	// обновление состояния, если значение извне изменилось
	useEffect(() => {
		setChecked(externalChecked);
	}, [externalChecked]);

	return (
		<div className='switcherContainer'>
			{left}
			<label className="switcher">
				<input type="checkbox" checked={isChecked} onChange={handleToggle} disabled={disabled} />
				<span className="slider"></span>
			</label>
			{right}
		</div>
	);
}

export default Switcher;
