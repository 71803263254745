import { Controller, useFormContext } from 'react-hook-form';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import { RepairComponentsInputAutocomplete } from './RepairComponentsInputAutocomplete';
import { Typography } from '@mui/material';

export const RepairComponentsInputAutocompleteController = ({ disabled, searchParams }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<CollapseBlock defaultOpen={!!errors?.repair_component} title={<span>Компонент</span>}>
			<Controller
				name="repair_component"
				control={control}
				render={({ field }) => (
					<>
						<RepairComponentsInputAutocomplete {...field} disabled={disabled} searchParams={searchParams}/>
						{errors?.repair_component && (
							<Typography color="error" variant="caption">
								{errors?.repair_component?.repair_component}
							</Typography>
						)}
					</>
				)}
			/>
		</CollapseBlock>
	);
};
