import { useCallback, useEffect } from 'react';
import { useUpdateOrgMutation } from '../../../../../../../../store/newApi/organizations/organizations';
import { filterTouchedFields } from '../../../../../../../../newComponents/Form/utils/filterTouchedFields';

export const useHandleUpdate = ({ ...methods }) => {
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateClient, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateOrgMutation();

	useEffect(() => {
		return () => {
			resetForm();
		};
	}, []);

	const handleUpdate = useCallback(
		({ orgId, value }) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = {
				full_name: touchedFieldValues.full_name,
				password: touchedFieldValues.password,
				organization_name: touchedFieldValues.organization_name,
				email: touchedFieldValues.email,
				inn: touchedFieldValues.inn,
				organization_address: touchedFieldValues.organization_address,
				can_confirm_repair: touchedFieldValues.isCan_confirm_repair,
				can_see_storage_price: touchedFieldValues.isCan_see_storage_price,
				terminals: touchedFieldValues.terminals,
			};
			updateClient({
				orgId: orgId,
				...payload,
			});
		},
		[updateClient, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
