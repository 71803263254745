import { header, headerBlock } from './Header.styles.js';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext } from 'react';
import { SideMenuContext } from '../../shared/providers/SideMenuProvider/SideMenuProvider';
import MenuIcon from '@mui/icons-material/Menu';

// text - Заголовок
// btnDesktop и btnMobile - Массивы кнопок декстоп и мобильной версий
// stepAll - Количество шагов
// stepNumber - Номер данного шага
// stepText - Текст данного шага

function Header({ text, btnDesktop, btnMobile, stepAll, stepNumber, stepText, filter }) {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const buttons = isDesktop ? btnDesktop : btnMobile;
	const { handleToggleMenu } = useContext(SideMenuContext);

	return (
		<Box sx={header(isDesktop).container}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Box sx={headerBlock(isDesktop)}>
					<Typography variant="subtitle1" sx={header(isDesktop).title}>
						{text}
					</Typography>

					{stepAll && (
						<Typography variant="p" sx={header(isDesktop).steps}>
							Шаг {stepNumber} из {stepAll}. {stepText}
						</Typography>
					)}
				</Box>

				<Box sx={header(isDesktop).buttonsContainer}>
					{(!!filter && isDesktop) && filter}
					{buttons?.map((item, ind) => (
						<Box key={ind} component="div">
							{item}
						</Box>
					))}
					{!isDesktop && <MenuIcon onClick={handleToggleMenu} />}
				</Box>
			</div>
			{(!isDesktop && !!filter) && <div>
				{filter}
			</div>}
		</Box>
	);
}

export default Header;
