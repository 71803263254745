export const IconUserTag = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12 23.7287C11.3 23.7287 10.59 23.4587 10.06 22.9287L8.34998 21.2387C7.92998 20.8187 7.35001 20.5887 6.76001 20.5887H6C3.93 20.5887 2.25 18.9187 2.25 16.8687V5.95868C2.25 3.90868 3.93 2.23871 6 2.23871H18C20.07 2.23871 21.75 3.90868 21.75 5.95868V16.8687C21.75 18.9187 20.07 20.5887 18 20.5887H17.24C16.65 20.5887 16.07 20.8287 15.65 21.2387L13.94 22.9287C13.41 23.4587 12.7 23.7287 12 23.7287ZM6 3.7287C4.76 3.7287 3.75 4.72867 3.75 5.94867V16.8587C3.75 18.0887 4.76 19.0787 6 19.0787H6.76001C7.75001 19.0787 8.70997 19.4787 9.40997 20.1687L11.12 21.8587C11.61 22.3387 12.4 22.3387 12.89 21.8587L14.6 20.1687C15.3 19.4787 16.26 19.0787 17.25 19.0787H18C19.24 19.0787 20.25 18.0787 20.25 16.8587V5.94867C20.25 4.71867 19.24 3.7287 18 3.7287H6Z"
					fill={color}
				/>
				<path
					d="M11.9999 11.7288C10.2999 11.7288 8.91992 10.3487 8.91992 8.64874C8.91992 6.94874 10.2999 5.56879 11.9999 5.56879C13.6999 5.56879 15.08 6.94874 15.08 8.64874C15.08 10.3487 13.6999 11.7288 11.9999 11.7288ZM11.9999 7.06879C11.1299 7.06879 10.4199 7.77874 10.4199 8.64874C10.4199 9.51874 11.1299 10.2288 11.9999 10.2288C12.8699 10.2288 13.58 9.51874 13.58 8.64874C13.58 7.77874 12.8699 7.06879 11.9999 7.06879Z"
					fill={color}
				/>
				<path
					d="M16 17.3888C15.59 17.3888 15.25 17.0488 15.25 16.6388C15.25 15.2588 13.79 14.1288 12 14.1288C10.21 14.1288 8.75 15.2588 8.75 16.6388C8.75 17.0488 8.41 17.3888 8 17.3888C7.59 17.3888 7.25 17.0488 7.25 16.6388C7.25 14.4288 9.38 12.6288 12 12.6288C14.62 12.6288 16.75 14.4288 16.75 16.6388C16.75 17.0488 16.41 17.3888 16 17.3888Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
