import { useCallback, useEffect } from "react";
import { useCreateVehicleMutation } from "../../../../store/newApi/vehicles/vehicles";

export const useHandleCreate = ({ handleClose, ...methods }) => {
    const { reset: resetForm } = methods;
    const [create, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreate }] = useCreateVehicleMutation();

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreate();
            resetForm();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isCreateSuccess, handleClose, resetCreate, resetForm]);

    const handleCreate = useCallback((value) => {
        console.log(value);
        create({ ...value });
    }, [create]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}