import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';
import { useCallback, useEffect } from 'react';
import { useLazyGetClientsListQuery } from '../../../store/newApi/organizations/organizations';
import { Box, IconButton } from '@mui/material';
import { IconLink } from '../../../components/Icons';
import RequireCheckRights from '../../../hoc/RequireCheckRights';
import { LINK_CLIENT_BY_ID } from '../../../shared/clients/pageUrls';

export const ClientInputAutocomplete = ({ value, onChange, ...rest }) => {
	const { disabled } = rest;
	const [trigger, { clientsOptions = [], loading: loadingClients }] = useLazyGetClientsListQuery({
		selectFromResult: ({ data }) => ({
			clientsOptions: data?.results?.map((t) => ({ label: t.organization_name, id: t.id })),
		}),
	});
	const handleSearchClients = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[trigger],
	);
	
	useEffect(() => {
		console.log(disabled, value);
		if (disabled && value) {
			trigger({ id: value });
		}
	}, [disabled, value, trigger]);

	const handleLinkClientId = () => {
		if (value) { 
			window.open(LINK_CLIENT_BY_ID(value), '_blank', 'noopener,noreferrer'); 
		}
	};
	return (
		<Box sx={{ width: '100%', display: 'flex', gap: '8px' }}>
			<InputAutocompleteAsync
				{...rest}
				rightActionButton={
					<RequireCheckRights path="clients" type="view">
						<IconButton color="primary" onClick={handleLinkClientId} disabled={!value}>
							<IconLink size={'medium'} color={'primary_white'} />
						</IconButton>
					</RequireCheckRights>
				}
				value={value}
				fetchFn={handleSearchClients}
				placeholder="Выберите или введите вручную"
				options={clientsOptions}
				loading={loadingClients}
				onChange={onChange}
			/>
		</Box>
	);
};
