import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useCallback, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetVehiclesListQuery } from '../../../../store/newApi/vehicles/vehicles';
import { Button } from '@mui/material';
 
import { resetFilter, setFilter } from '../../../../store/rtkSlices/vehicles.js';
import { useDispatch } from 'react-redux';
import { VehicleForm } from '../../../../newComponents/Form/VehicleForm/VehicleForm.jsx';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplTableVehicles() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isFormOpened, setIsFormOpened] = useState(false);

	const [fetchVehicles, { data, isLoading, isError }] = useLazyGetVehiclesListQuery();

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsFormOpened(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsFormOpened(true);
	}, []);

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.registration_number,
				id: 'registration_number',
				header: 'Номер',
				size: 120,
			},
			{
				accessorFn: (row) => row.trailer_number,
				id: 'trailer_number',
				header: 'Номер прицепа',
				size: 120,
			},
			{
				accessorFn: (row) => row.driver,
				id: 'driver',
				header: 'Водитель',
				size: 120,
			},
		],
		[],
	);

	// порядок колонок
	const columnOrdering = ['mrt-row-select', 'registration_number', 'trailer_number', 'driver'];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Автомобили'}
				btnDesktop={[
					selectedRows.length === 1 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<Button variant="contained" color="primary" onClick={handleCreate}>
						Добавить автомобиль
					</Button>,
				]}
				btnMobile={[
					selectedRows.length === 1 && (
						<Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<Button variant="contained" color="primary" onClick={handleCreate} size="small">
						Добавить автомобиль
					</Button>,
				]}
				filter={<Filters enabled={false} onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}> 
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchVehicles}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="vehiclesRtk"
					/>
					<VehicleForm isOpen={isFormOpened} handleClose={() => setIsFormOpened(false)} entityIds={selectedRows} />
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableVehicles;
