import styles from '../RegistrationClient.module.css';
import { Button } from '@mui/material';
import copy from 'copy-to-clipboard';
import { EmailIcon, TelegramIcon, WhatsappIcon } from 'react-share';

export const RegistrationClientManager = () => {
	return (
		<div className={styles.modalContainer}>
			<div>
				<p className={styles.modalTitle}>Свяжитесь с менеджером по номеру телефона</p>
				<div className={styles.modalStack}>
					<Button variant="contained" color="primary" size="medium" onClick={() => copy('+79999999999')}>
						Скопировать
					</Button>
					<a href="tel:+79999999999">
						<Button variant="contained" color="primary" size="medium">
							Позвонить
						</Button>
					</a>
				</div>
			</div>
			<div>
				<p>Или другим удобным способом</p>
				<div className={styles.modalRow}>
					<a href="tg://resolve?domain=username">
						<TelegramIcon size={44} round={true} />
					</a>
					<a href="https://wa.me/<PHONE>">
						<WhatsappIcon size={44} round={true} />
					</a>
					<a href="mailto:mail@example.com">
						<EmailIcon size={44} round={true} />
					</a>
				</div>
			</div>
		</div>
	);
};
