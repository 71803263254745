export const IconTagUser = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12 23.7326C11.3 23.7326 10.59 23.4626 10.06 22.9326L8.34998 21.2426C7.92998 20.8226 7.35001 20.5926 6.76001 20.5926H6C3.93 20.5926 2.25 18.9226 2.25 16.8726V5.9526C2.25 3.9026 3.93 2.2326 6 2.2326H18C20.07 2.2326 21.75 3.9026 21.75 5.9526V16.8626C21.75 18.9126 20.07 20.5826 18 20.5826H17.24C16.65 20.5826 16.07 20.8226 15.65 21.2326L13.94 22.9226C13.41 23.4626 12.7 23.7326 12 23.7326ZM6 3.7326C4.76 3.7326 3.75 4.7326 3.75 5.9526V16.8626C3.75 18.0926 4.76 19.0826 6 19.0826H6.76001C7.75001 19.0826 8.70997 19.4826 9.40997 20.1726L11.12 21.8626C11.61 22.3426 12.4 22.3426 12.89 21.8626L14.6 20.1726C15.3 19.4826 16.26 19.0826 17.25 19.0826H18C19.24 19.0826 20.25 18.0826 20.25 16.8626V5.9526C20.25 4.7226 19.24 3.7326 18 3.7326H6Z"
					fill={color}
				/>
				<path
					d="M12.0698 10.6826C12.0498 10.6826 12.0198 10.6826 11.9998 10.6826C11.9698 10.6826 11.9298 10.6826 11.8998 10.6826C10.4398 10.6326 9.2998 9.45259 9.2998 7.98259C9.2998 6.49259 10.5098 5.28259 11.9998 5.28259C13.4898 5.28259 14.6998 6.49259 14.6998 7.98259C14.6898 9.45259 13.5498 10.6326 12.0898 10.6826C12.0898 10.6826 12.0798 10.6826 12.0698 10.6826ZM11.9998 6.78259C11.3398 6.78259 10.7998 7.32259 10.7998 7.98259C10.7998 8.63259 11.3098 9.16259 11.9498 9.18259C11.9498 9.17259 12.0098 9.17259 12.0798 9.18259C12.7098 9.14259 13.1998 8.62259 13.1998 7.98259C13.1998 7.32259 12.6598 6.78259 11.9998 6.78259Z"
					fill={color}
				/>
				<path
					d="M12 17.6826C10.86 17.6826 9.72002 17.3826 8.83002 16.7926C7.99002 16.2326 7.5 15.4226 7.5 14.5626C7.5 13.7026 7.98002 12.8826 8.83002 12.3226C10.61 11.1426 13.39 11.1426 15.16 12.3226C16 12.8826 16.49 13.7026 16.49 14.5526C16.49 15.4126 16.01 16.2226 15.16 16.7926C14.28 17.3926 13.14 17.6826 12 17.6826ZM9.65997 13.5726C9.22997 13.8626 9 14.2126 9 14.5626C9 14.9126 9.23997 15.2626 9.65997 15.5526C10.93 16.4026 13.06 16.4026 14.33 15.5526C14.76 15.2626 15 14.9126 14.99 14.5626C14.99 14.2126 14.75 13.8626 14.33 13.5726C13.07 12.7226 10.93 12.7226 9.65997 13.5726Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
