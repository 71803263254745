import { useNavigate, useParams } from "react-router-dom";
import { useGetClientUserData } from "../../../../../shared/auth/hooks/useGetClientUserData";
import { useMemo } from "react";
import { OrgForm } from "./components/OrgForm";
import { Button, Container } from "@mui/material";
import styles from '../../AccountEmpl.module.scss';
import { account } from '../Account.styles';
import Header from "../../../../../newComponents/Header/Header";
import { ORGS_PAGE_URL } from "../../../../../shared/organizations/pageUrls";

export const ClientOrganizationInfo = () => {
    const navigate = useNavigate();
    const { getOrgById } = useGetClientUserData();
    const { orgId } = useParams();

    const orgData = useMemo(() => getOrgById(orgId), [orgId, getOrgById]);
    console.log(orgData);
    return (
        <div className={styles.account__wrap} style={{ display: 'flex', flexDirection: 'column' }}>
            {/* @ts-ignore */}
            <Header
                text={orgData?.organization_name || ""}
            />
            <div className={styles.account__container} style={{ height: 'auto', flexGrow: 1 }}>
                <Container className={styles.account__collection} sx={account.container}> 
                    <OrgForm data={orgData} />
                </Container>
            </div>
            <div style={{ width: '100%', display: 'flex', position: 'fixed', bottom: 0, padding: '10px' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={() => navigate(ORGS_PAGE_URL())}>Назад</Button>
			</div>
        </div>
    )
}