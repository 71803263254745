import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import instance from '../api/login/configAxios';
import { useDispatch } from 'react-redux';
import { setUserPermissions } from '../store/slices/meSlice';

// userType это тип (1 - сотрудник(Права только у сотрудников(Permisitions)), 2 - клиент, 3 - перевозчик)

const RequireAuth = ({ children, userType, link }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	let { clientId } = useParams();
	const { terminalId } = useParams();
	const { inspectionId } = useParams();
	let { orderId } = useParams();
	let { repairOrderId } = useParams();
	let { entryId } = useParams();
	let { actId } = useParams();
	let { reportId } = useParams();
	let { confirmed } = useParams();
	let { transporterId } = useParams();
	let { userId } = useParams();
	let { jobId } = useParams();
	let { orgId } = useParams();

	const [auth, setAuth] = useState(!!Cookies.get('csrftoken'));
	const [user, setUser] = useState({
		userType: Cookies.get('userType'),
		permissions: [],
	});

	const privateRoute = (userType = user.userType) => {
		const allowedRoutesForClient = [
			'/accountClient/main',
			'/accountClient/summary',
			'/accountClient/containers',

			'/accountClient/entries',
			`/accountClient/entry/${terminalId}/${orderId}/${entryId}`,
			`/accountClient/entry`,
			'/accountClient/vehicles',

			'/accountClient/tableEntries',
			'/accountClient/tableLogs',
			'/accountClient/reports',
			'/accountClient/orders',
			'/accountClient/orders/create',
			`/accountClient/orders/${terminalId}/${orderId}`,
			'/accountClient/createImportOrder',
			'/accountClient/createExportOrder',
			`/accountClient/containerAcceptance/${reportId}`,
			'/accountClient/cargos',
			'/accountClient/company',
			`/accountClient/company/${orgId}`,
			'/accountClient/historyEntries',
			'/accountClient/inspection',
			`/accountClient/inspection/${inspectionId}`,
			'/accountClient/storage',
			'/accountClient/repair',
			'/accountClient/createRepair',
			`/accountClient/createRepair/${repairOrderId}`,
			`/accountClient/createRepair/${repairOrderId}/`,
			'/accountClient/repack',
			'/accountClient/repairTasks',
			'/accountClient/tariffs',
			'/accountClient/company',
			'/accountClient/clients',
			'/accountClient/transporters',
			`/accountClient/transporters/${transporterId}`,
			`/accountClient/transporters/create`,
			'/accountClient/empls',
			`/accountClient/empls/${userId}`,
			`/accountClient/empls/create`,
		];
		const allowedRoutesForEmployee = [
			'/accountEmpl/main',
			'/accountEmpl/summary',
			'/accountEmpl/containers',

			'/accountEmpl/clients',
			`/accountEmpl/clients/${clientId}`,
			`/accountEmpl/clients/create`,
			'/accountEmpl/empls',
			`/accountEmpl/empls/${userId}`,
			`/accountEmpl/empls/create`,
			'/accountEmpl/cars',
			'/accountEmpl/transporters',
			`/accountEmpl/transporters/${transporterId}`,
			`/accountEmpl/transporters/create`,
			'/accountEmpl/terminals',
			`/accountEmpl/terminals/${terminalId}`,
			'/accountEmpl/terminals/create',

			'/accountEmpl/orders',
			'/accountEmpl/orders/create',
			`/accountEmpl/orders/${terminalId}/${orderId}`,
			'/accountEmpl/createImportOrder',
			'/accountEmpl/createExportOrder',
			'/accountEmpl/createTerminal',
			'/accountEmpl/createJob',
			`/accountEmpl/createJob/${jobId}`,
			'/accountEmpl/createJob/create',

			'/accountEmpl/entries',
			`/accountEmpl/entry/${terminalId}/${orderId}/${entryId}`,
			`/accountEmpl/entry`,
			'/accountEmpl/containerAcceptance',
			`/accountEmpl/receptionTransmission/${confirmed}`,
			`/accountEmpl/receptionTransmission/act/${actId}`,
			`/accountEmpl/containerAcceptance/${reportId}`,

			'/accountEmpl/tableLogs',
			'/accountEmpl/vehicles',

			'/accountEmpl/reports',
			'/accountEmpl/cargos',
			'/accountEmpl/historyEntries',
			'/accountEmpl/inspection',
			'/accountEmpl/inspection/create',
			`/accountEmpl/inspection/${inspectionId}`,
			'/accountEmpl/storage',
			'/accountEmpl/repair',
			'/accountEmpl/repack',
			'/accountEmpl/repairTasks',
			'/accountEmpl/createRepair',
			`/accountEmpl/createRepair/${repairOrderId}`,
			`/accountEmpl/createRepair/${repairOrderId}/`,
			'/accountEmpl/tariffs',
			'/accountEmpl/company',
		];
		if (userType === '1') {
			return allowedRoutesForEmployee;
		} else if (userType === '2') {
			return allowedRoutesForClient;
		}
	};

	const allowedRoutes = privateRoute();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const csrfToken = Cookies.get('csrftoken');
				if (csrfToken) {
					const response = await instance.get('/me/', {
						withCredentials: true,
						headers: {
							'X-CSRFToken': csrfToken,
						},
					});
					if (response.data) {
						// const newPermissions = response.data.permissions;
						// setUser((prev) => ({
						// 	...prev,
						// 	permissions: [...prev.permissions, ...newPermissions],
						// }));
						dispatch(setUserPermissions(response.data));
					}
				} else {
					setAuth(false);
				}
			} catch (error) {
				if (error.response.status === 401) {
					setAuth(false);
				}
			}
		};
		fetchData();
	}, []);

	if (!auth) {
		return <Navigate to={`${link}`} />;
	}
	if (auth && String(userType) === '1') {
		if (allowedRoutes?.includes(location.pathname)) {
			return React.cloneElement(children, {
				userPermissions: user.permissions,
			});
		} else {
			return <Navigate to={`${link}`} />;
		}
	} else if (auth && String(userType) === '2') {
		if (allowedRoutes.includes(location.pathname)) {
			return children;
		} else {
			return <Navigate to={`${link}`} />;
		}
	}
};

export default RequireAuth;
