import React, { useEffect, useState } from 'react';
import styles from './componentsStyles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { damageTypeToName } from '../Sides/helpers/damageTypeToName';
import {
	clearChoosenDetailsForChoosingDamage,
	setCurrentSideDamage,
	setTemporaryActiveBlocks,
} from '../../../../../../../../../../store/slices/containerAcceptanceSlice';

function UniversalBlock({ type, sideIndex, sideName, divider, part, partCode, choosen, selectedAreas = [], onClick = () => {} }) {
	const { activeBlockType, page } = useSelector((state) => state.containerAccept);
	const dispatch = useDispatch();

	const isActive = selectedAreas.some(
		(block) => block.sideIndex === sideIndex && block.divider === divider && block.part === part && block.type === type,
	);

	const handleActive = () => {
		let updatedTemporaryActiveBlocks;
		if (isActive || !activeBlockType || activeBlockType === type) {
			if (isActive) {
				updatedTemporaryActiveBlocks = selectedAreas.filter(
					(block) =>
						!(block.sideIndex === sideIndex && block.divider === divider && block.part === part && block.type === type),
				);
			} else {
				updatedTemporaryActiveBlocks = [...selectedAreas, { sideIndex, sideName, type, divider, part }];
			}
			// dispatch(setTemporaryActiveBlocks(updatedTemporaryActiveBlocks));
			onClick(updatedTemporaryActiveBlocks);
		}
	};

	useEffect(() => {
		// console.log({
		// 	sideIndex,
		// 	sideName,
		// 	type,
		// 	divider,
		// 	part,
		// 	active: true,
		// 	partCode,
		// 	damageName: damageTypeToName(type),
		// })
		// if (isActive) {
		// 	dispatch(
		// 		setCurrentSideDamage({
		// 			sideIndex,
		// 			sideName,
		// 			type,
		// 			divider,
		// 			part,
		// 			active: true,
		// 			partCode,
		// 			damageName: damageTypeToName(type),
		// 		}),
		// 	);
		// 	dispatch(clearChoosenDetailsForChoosingDamage());
		// } else {
		// 	dispatch(
		// 		setCurrentSideDamage({
		// 			sideIndex,
		// 			sideName,
		// 			type,
		// 			divider,
		// 			part,
		// 			partCode,
		// 			active: false,
		// 			damageName: damageTypeToName(type),
		// 		}),
		// 	);
		// }
	}, [isActive, sideIndex, sideName, type, divider, part, dispatch]);

	return (
		<div
			className={`${styles.mainStyle} ${styles[type]} ${isActive ? styles.active : ''} ${choosen ? styles.choosen : ''}`}
			onClick={() => handleActive()}
		>
			{choosen ? choosen : null}
		</div>
	);
}

export default UniversalBlock;
