export const IconCardCoin = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M12 17.8005H2C1.59 17.8005 1.25 17.4605 1.25 17.0505C1.25 16.6405 1.59 16.3005 2 16.3005H12C12.41 16.3005 12.75 16.6405 12.75 17.0505C12.75 17.4705 12.41 17.8005 12 17.8005Z"
					fill={color}
				/>
				<path
					d="M9.77979 22.7405H4.22974C2.03974 22.7405 1.25977 21.9705 1.25977 19.7905V15.6905C1.25977 13.5105 2.03974 12.7405 4.22974 12.7405H9.77979C11.9698 12.7405 12.7498 13.5105 12.7498 15.6905V19.8005C12.7498 21.9705 11.9698 22.7405 9.77979 22.7405ZM4.21973 14.2405C2.85973 14.2405 2.74976 14.3505 2.74976 15.6905V19.8005C2.74976 21.1405 2.85973 21.2505 4.21973 21.2505H9.76978C11.1298 21.2505 11.2397 21.1405 11.2397 19.8005V15.6905C11.2397 14.3505 11.1298 14.2405 9.76978 14.2405H4.21973Z"
					fill={color}
				/>
				<path
					d="M15.0002 22.7405C14.7302 22.7405 14.4802 22.5905 14.3502 22.3605C14.2202 22.1205 14.2202 21.8405 14.3602 21.6005L15.4102 19.8505C15.6202 19.5005 16.0802 19.3805 16.4402 19.5905C16.8002 19.8005 16.9102 20.2605 16.7002 20.6205L16.4302 21.0705C19.1902 20.4205 21.2602 17.9405 21.2602 14.9805C21.2602 14.5705 21.6002 14.2305 22.0102 14.2305C22.4202 14.2305 22.7602 14.5705 22.7602 14.9805C22.7502 19.2605 19.2702 22.7405 15.0002 22.7405Z"
					fill={color}
				/>
				<path
					d="M2 9.74048C1.59 9.74048 1.25 9.40048 1.25 8.99048C1.25 4.72048 4.73 1.24048 9 1.24048C9.27 1.24048 9.52002 1.39048 9.65002 1.62048C9.78002 1.86048 9.78001 2.14048 9.64001 2.38048L8.59003 4.13048C8.38003 4.48048 7.92 4.60048 7.56 4.39048C7.2 4.18048 7.08999 3.72048 7.29999 3.36048L7.57001 2.91048C4.81001 3.56048 2.73999 6.04048 2.73999 9.00048C2.74999 9.40048 2.41 9.74048 2 9.74048Z"
					fill={color}
				/>
				<path
					d="M18.5 11.7405C15.61 11.7405 13.25 9.39048 13.25 6.49048C13.25 3.59048 15.6 1.24048 18.5 1.24048C21.4 1.24048 23.75 3.59048 23.75 6.49048C23.75 9.39048 21.39 11.7405 18.5 11.7405ZM18.5 2.74048C16.43 2.74048 14.75 4.42048 14.75 6.49048C14.75 8.56048 16.43 10.2405 18.5 10.2405C20.57 10.2405 22.25 8.56048 22.25 6.49048C22.25 4.42048 20.57 2.74048 18.5 2.74048Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
