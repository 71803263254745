import { useCallback, useEffect } from "react"; 
import { useCreateStorageMutation } from "../../../../store/newApi/service/service";

export const useHandleCreate = ({ handleClose, ...methods }) => {
    const { reset: resetForm } = methods;
    const [createStorage, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateStorageMutation }] = useCreateStorageMutation();

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreateStorageMutation();
            resetForm();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isCreateSuccess, handleClose, resetCreateStorageMutation, resetForm]);

    const handleCreate = useCallback((value) => {
        createStorage({
            orgId: value.client, 
            container: value.containerNumber, 
			free_days_storage: value.free_days_storage, 
			client_tariff: value.client_tariff, 
			date_from: value.date_from, 
			date_to: value.date_to, 
        });
    }, [createStorage]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}