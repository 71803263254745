import { useCallback, useEffect } from "react";
import { filterTouchedFields } from "../../utils/filterTouchedFields";
import { useUpdateCargosMutation } from "../../../../store/newApi/cargos/cargos";

export const useHandleUpdate = ({ entityIds, handleClose, refetchData, ...methods }) => {
    const { formState: { touchedFields }, reset: resetForm } = methods;
    const [updateCargo, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateCargoMutation }] = useUpdateCargosMutation();

    useEffect(() => {
        if (isUpdateSuccess) {
            handleClose();
            resetUpdateCargoMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isUpdateSuccess, handleClose, resetUpdateCargoMutation, resetForm, refetchData]);

    const handleUpdate = useCallback((value) => {
        const touchedFieldValues = filterTouchedFields(value, touchedFields);
        updateCargo({
            ids: entityIds,
            ...touchedFieldValues
        })
    }, [entityIds, updateCargo, touchedFields]);

    return {
        handleUpdate,
        isUpdateSuccess,
        isUpdateLoading
    }
}