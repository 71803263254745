export const ENTRYREPORT_TYPE = {
    IN: {
        id: 1
    },
    OUT: {
        id: 2
    }
}

export const ENTRYREPORT_TYPE_TEXT = {
    [ENTRYREPORT_TYPE.IN.id]: 'Прием контейнера',
    [ENTRYREPORT_TYPE.OUT.id]: 'Выдача контейнера'
}

export const ENTRYREPORT_STATUSES = {
    DRAFT: {
        id: 1
    },
    ACCESSED: {
        id: 2
    }
}