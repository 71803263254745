export const IconCar = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M19.9994 12.7317H3.99937C3.76937 12.7317 3.55937 12.6317 3.41937 12.4517C3.27937 12.2817 3.21937 12.0417 3.26937 11.8217L4.39937 6.42171C4.76937 4.67171 5.51937 3.06171 8.48937 3.06171H15.5194C18.4894 3.06171 19.2394 4.68171 19.6094 6.42171L20.7394 11.8317C20.7894 12.0517 20.7294 12.2817 20.5894 12.4617C20.4394 12.6317 20.2294 12.7317 19.9994 12.7317ZM4.91937 11.2317H19.0694L18.1294 6.73171C17.8494 5.42171 17.5194 4.56171 15.5094 4.56171H8.48937C6.47937 4.56171 6.14937 5.42171 5.86937 6.73171L4.91937 11.2317Z"
					fill={color}
				/>
				<path
					d="M19.9596 23.7317H18.0796C16.4596 23.7317 16.1496 22.8017 15.9496 22.1917L15.7496 21.5917C15.4896 20.8317 15.4596 20.7317 14.5596 20.7317H9.4396C8.5396 20.7317 8.4796 20.9017 8.2496 21.5917L8.0496 22.1917C7.8396 22.8117 7.5396 23.7317 5.9196 23.7317H4.0396C3.2496 23.7317 2.4896 23.4017 1.9596 22.8217C1.4396 22.2517 1.1896 21.4917 1.2596 20.7317L1.8196 14.6417C1.9696 12.9917 2.4096 11.2317 5.6196 11.2317H18.3796C21.5896 11.2317 22.0196 12.9917 22.1796 14.6417L22.7396 20.7317C22.8096 21.4917 22.5596 22.2517 22.0396 22.8217C21.5096 23.4017 20.7496 23.7317 19.9596 23.7317ZM9.4396 19.2317H14.5596C16.3796 19.2317 16.8096 20.0417 17.1696 21.1017L17.3796 21.7217C17.5496 22.2317 17.5496 22.2417 18.0896 22.2417H19.9696C20.3396 22.2417 20.6896 22.0917 20.9396 21.8217C21.1796 21.5617 21.2896 21.2317 21.2596 20.8817L20.6996 14.7917C20.5696 13.4417 20.4096 12.7417 18.3996 12.7417H5.6196C3.5996 12.7417 3.4396 13.4417 3.3196 14.7917L2.7596 20.8817C2.7296 21.2317 2.8396 21.5617 3.0796 21.8217C3.3196 22.0917 3.6796 22.2417 4.0496 22.2417H5.9296C6.4696 22.2417 6.4696 22.2317 6.6396 21.7317L6.8396 21.1217C7.0896 20.3217 7.4596 19.2317 9.4396 19.2317Z"
					fill={color}
				/>
				<path
					d="M4 9.73163H3C2.59 9.73163 2.25 9.39163 2.25 8.98163C2.25 8.57163 2.59 8.23163 3 8.23163H4C4.41 8.23163 4.75 8.57163 4.75 8.98163C4.75 9.39163 4.41 9.73163 4 9.73163Z"
					fill={color}
				/>
				<path
					d="M21 9.73163H20C19.59 9.73163 19.25 9.39163 19.25 8.98163C19.25 8.57163 19.59 8.23163 20 8.23163H21C21.41 8.23163 21.75 8.57163 21.75 8.98163C21.75 9.39163 21.41 9.73163 21 9.73163Z"
					fill={color}
				/>
				<path
					d="M12 6.73163C11.59 6.73163 11.25 6.39163 11.25 5.98163V3.98163C11.25 3.57163 11.59 3.23163 12 3.23163C12.41 3.23163 12.75 3.57163 12.75 3.98163V5.98163C12.75 6.39163 12.41 6.73163 12 6.73163Z"
					fill={color}
				/>
				<path
					d="M13.5 6.73163H10.5C10.09 6.73163 9.75 6.39163 9.75 5.98163C9.75 5.57163 10.09 5.23163 10.5 5.23163H13.5C13.91 5.23163 14.25 5.57163 14.25 5.98163C14.25 6.39163 13.91 6.73163 13.5 6.73163Z"
					fill={color}
				/>
				<path
					d="M9 16.7316H6C5.59 16.7316 5.25 16.3916 5.25 15.9816C5.25 15.5716 5.59 15.2316 6 15.2316H9C9.41 15.2316 9.75 15.5716 9.75 15.9816C9.75 16.3916 9.41 16.7316 9 16.7316Z"
					fill={color}
				/>
				<path
					d="M18 16.7316H15C14.59 16.7316 14.25 16.3916 14.25 15.9816C14.25 15.5716 14.59 15.2316 15 15.2316H18C18.41 15.2316 18.75 15.5716 18.75 15.9816C18.75 16.3916 18.41 16.7316 18 16.7316Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
