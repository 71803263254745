import { InputAutocomplete } from "../../Inputs/InputAutocomplete/InputAutocomplete"
import { useGetTerminalsListQuery } from "../../../store/newApi/terminals/terminals"

export const TerminalInputAutocomplete = ({ ...rest }) => {
    const { terminalOptions = [] } = useGetTerminalsListQuery({}, {
        selectFromResult: ({ data }) => ({
            terminalOptions: data?.map((t) => ({ label: t.name, id: t.id }))
        })
    });

    return (
        <InputAutocomplete
            {...rest}
            placeholder="Выберите или введите вручную"
            options={terminalOptions}
        />
    )
}