import React from 'react';
import { TextField, Grid } from '@mui/material';
import { numberRangeInput } from './NumberRangeInput.styles.js';

const NumberRangeInput = ({ minNumber, maxNumber, onMinNumberChange, onMaxNumberChange, text }) => {
	return (
		<Grid container sx={{ width: '100%', margin: 0, display: 'flex', gap: '8px' }}>
			<Grid item sx={{ width: 'calc(50% - 4px)' }}>
				<TextField
					variant="outlined"
					placeholder={`Мин. ${text}`}
					type="number"
					value={minNumber}
					onChange={(event) => onMinNumberChange(event.target.value)}
					fullWidth
					inputProps={{ min: 0 }}
					sx={{
						...numberRangeInput.textField,
						...(minNumber && numberRangeInput.valueTextField),
					}}
				/>
			</Grid>
			<Grid item sx={{ width: 'calc(50% - 4px)' }}>
				<TextField
					variant="outlined"
					placeholder={`Макс. ${text}`}
					type="number"
					value={maxNumber}
					onChange={(event) => onMaxNumberChange(event.target.value)}
					fullWidth
					inputProps={{ min: 0 }}
					sx={{
						...numberRangeInput.textField,
						...(maxNumber && numberRangeInput.valueTextField),
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default NumberRangeInput;
