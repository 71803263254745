 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const cargos = api.injectEndpoints({
	endpoints: (builder) => ({
		getCargosList: builder.query({
			query: (params) => {
				let queryParams = { ...params };
				const sortParam = params.sorting?.length
					? params.sorting
							.map(({ id, desc }) => {
								let field;
								return desc ? `-${field}` : field;
							})
							.join(',')
					: null;

				if (sortParam) {
					queryParams.ordering = sortParam;
				}

				delete queryParams.uniq;
				delete queryParams.sorting;

				return {
					url: `/api/cargo/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getCargoById: builder.query({
			query: ({ id }) => {
				return {
					url: `/api/cargo/${id}/`,
					method: 'GET',
				}
			}
		}),
		updateCargos: builder.mutation({
			query: ({ ids, ...payload }) => {
				console.log('data, ', payload);
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/cargo/batch-update/`,
					method: 'PUT',
					body: {
						cargos: ids,
						...validatedData
					},
				}
			}
		}),
		createCargos: builder.mutation({
			query: ({ ...payload }) => {
				console.log('data, ', payload);
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/cargo/create/`,
					method: 'POST',
					body: {
						...validatedData
					},
				}
			}
		})
	}),
});

export const {
	useGetCargosListQuery,
	useLazyGetCargosListQuery,
	useCreateCargosMutation,
	useUpdateCargosMutation,
	useGetCargoByIdQuery,
} = cargos;
