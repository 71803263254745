import { Container, Grid, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import { Link } from 'react-router-dom';
import styles from '../../AccountEmpl.module.scss';
import { useGetClientUserData } from '../../../../../shared/auth/hooks/useGetClientUserData';
import { OrgCard } from '../../../../../entities/organization/ui/OrgCard/OrgCard';
import { PAGE_ORG_BY_ID } from '../../../../../shared/organizations/pageUrls';
import { account, accountGridItemThird } from '../Account.styles';

export const ClientOrganizationsList = () => {
    const { orgs } = useGetClientUserData();
    // @ts-ignore
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    return (
        <div className={styles.account__wrap}>
            {/* @ts-ignore */}
            <Header
				text="Список организаций"
			/>
            <div className={styles.account__container}>
				<Container className={styles.account__collection} sx={account.container}> 
                    <Grid container>
                        {orgs?.map((org, index) => (
                            <Grid item sx={accountGridItemThird(isDesktop)} key={index}>
                                <Link to={PAGE_ORG_BY_ID(org?.id)}> 
                                    <OrgCard
                                        name={org.organization_name}
                                        address={org.organization_address}
                                        inn={org.inn}
                                    />
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
				</Container>
			</div>
        </div>
    )
}