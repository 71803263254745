import { useCallback, useEffect, useMemo, useState } from "react";
import { ContainerInputAutocomplete } from "../../../../../../../newComponents/Form/Fields/ContainerInputAutocomplete"
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText"

import styles from './InspectionInfoStep.module.css';
import { useLazyGetContainerByIdQuery } from "../../../../../../../store/newApi/containers/containers";
import { useCreateEntryReportMutation } from "../../../../../../../store/newApi/entryreports/entryreports";
import { useNavigate } from "react-router-dom";
import { INSPECTION_INFO_URL } from "../../../../../../../shared/inspection/pageUrls";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";

type InspectionInfoStepProps = {
    containerId: number | null;
    entryReportId?: number;
    orderType: string;
    disableDamages: boolean;
    onBack: () => void;
}

export const InspectionInfoStep = ({
    containerId,
    onBack,
    orderType,
    entryReportId,
    disableDamages
}: InspectionInfoStepProps) => {
    const navigate = useNavigate();
    const [currentContainerId, setCurrentContainerId] = useState(null);

    const [fetchContainer, { data: container }] = useLazyGetContainerByIdQuery();
    const [createEntryReport, { data: entryReportData, isSuccess: createEntryReportSucess, reset: resetCreate }] = useCreateEntryReportMutation();

    const isEditMode = useMemo(() => !!entryReportId, [entryReportId]);

    useEffect(() => {
        if (containerId)
            setCurrentContainerId(containerId)
    }, [containerId]);

    useEffect(() => {
        if (currentContainerId)
            fetchContainer({ id: currentContainerId })
    }, [currentContainerId]);

    const handleContainerChoose = useCallback((value: number) => {
        setCurrentContainerId(value);
    }, []);

    const handleNext = useCallback(() => {
        if (!isEditMode)
            createEntryReport({ containerId: currentContainerId, type: +orderType })
        else
            navigate(INSPECTION_INFO_URL(entryReportId, disableDamages ? 3 : 1))
    }, [createEntryReport, orderType, currentContainerId, isEditMode, entryReportId, navigate, disableDamages]);

    useEffect(() => {
        if (createEntryReportSucess && entryReportData) {
            navigate(INSPECTION_INFO_URL(entryReportData.id, disableDamages ? 3 : 1))
            resetCreate();
        }
    }, [createEntryReportSucess, entryReportData, resetCreate, disableDamages]);

    const containerFilters = useMemo(() => {
        if (orderType === '1')
            return { report_available: true }
        if (orderType === '2')
            return { report_out_available: true }
        return {}
    }, [orderType]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
            <div className={styles.containerFormTemplate}>
                <div className={styles.item}>
                    {!isEditMode && <ContainerInputAutocomplete
                        searchParams={containerFilters}
                        value={currentContainerId}
                        onChange={handleContainerChoose}
                        labelText="Контейнер" />
                    }
                    {/* @ts-ignore */}
                    {isEditMode && <InputText labelText="Контейнер" disabled value={container?.container_number} />}
                </div>
                {container && (
                    <>
                        {/* @ts-ignore */}
                        <InputText labelText="Тип" disabled value={container.type_display} />
                        {/* @ts-ignore */}
                        <InputText labelText="Размер" disabled value={container.size_display} />
                        <div className={styles.item}>
                            {/* @ts-ignore */}
                            <InputText labelText="Номер машины" disabled value={container?.entrypass[0]?.vehicle_display} />
                        </div>
                        <div className={styles.item}>
                            {/* @ts-ignore */}
                            <InputText labelText="Водитель" disabled value={container?.entrypass[0]?.vehicle_driver} />
                        </div>
                    </>
                )}
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Назад',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Продолжить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        disabled: !currentContainerId,
                        onClick: handleNext
                    }
                ]}
            />
        </div>
    )
}