import styles from '../../UploadImage.module.scss';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

type UploadedImageItemProps = {
    disabled: boolean,
    handleDelete: (filename: string) => void,
    url: string,
    filename: string,
}

export const UploadedImageItem = ({ disabled, handleDelete, url, filename }: UploadedImageItemProps) => {
    return (
        <div key={filename} className={styles.onePhotoCard}>
            {!disabled && <CancelRoundedIcon onClick={() => handleDelete(filename) } />}
            <img src={url} alt="example" />
        </div>
    )
}