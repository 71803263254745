// Функция для сопоставления пермишенов с терминала и группы
export const getTerminalPermissions = (terminals, terminalId) => {
	// Найти терминал по id
	const terminal = terminals.find((t) => t.id === terminalId);

	// Если терминал не найден, вывести сообщение
	if (!terminal) { 
		return;
	}

	// Функция для обработки массива пермишенов
	const processPermissions = (permissions, selectedPermissions) => {
		permissions.forEach((permission) => {
			const model = permission.model_ru;

			// Если модели еще нет в selectedPermissions, инициализировать объект пермишенов
			if (!selectedPermissions[model]) {
				selectedPermissions[model] = {
					add: false,
					change: false,
					delete: false,
					view: false,
				};
			}

			// Проверяем наличие каждого типа разрешения
			if (permission.permission_type.includes('add')) {
				selectedPermissions[model].add = true;
			}
			if (permission.permission_type.includes('change')) {
				selectedPermissions[model].change = true;
			}
			if (permission.permission_type.includes('delete')) {
				selectedPermissions[model].delete = true;
			}
			if (permission.permission_type.includes('view')) {
				selectedPermissions[model].view = true;
			}
		});
	};

	// Инициализируем объект для сохранения итоговых пермишенов
	const selectedPermissions = {};

	// Обрабатываем пермишены терминала
	if (terminal.permissions) {
		processPermissions(terminal.permissions, selectedPermissions);
	}

	// Обрабатываем пермишены группы, если они существуют
	if (terminal.group && terminal.group.permissions) {
		processPermissions(terminal.group.permissions, selectedPermissions);
	} 

	return selectedPermissions;
};

// Функция для обработки пермишенов группы
export const getPermissionsMap = (permissions = [], allSections = [], disabled = false) => { 

	// Функция для обработки массива пермишенов
	const processPermissions = (permissions, selectedPermissions) => {
		permissions.forEach((permission) => {
			const model = permission.model_ru;

			// Если модели еще нет в selectedPermissions, инициализировать объект пермишенов
			if (!selectedPermissions[model]) {
				selectedPermissions[model] = {
					add: false,
					change: false,
					delete: false,
					view: false,
				};
			}

			// Проверяем наличие каждого типа разрешения
			if (permission.permission_type.includes('add')) {
				selectedPermissions[model].add = true;
			}
			if (permission.permission_type.includes('change')) {
				selectedPermissions[model].change = true;
			}
			if (permission.permission_type.includes('delete')) {
				selectedPermissions[model].delete = true;
			}
			if (permission.permission_type.includes('view')) {
				selectedPermissions[model].view = true;
			}
		});
	};

	// Инициализируем объект для сохранения итоговых пермишенов
	const selectedPermissions = {};

	allSections?.forEach((section) => {
		selectedPermissions[section] = {
			add: false,
			change: false,
			delete: false,
			view: false,
		};
	});

	// Обрабатываем пермишены терминала
	if (permissions) {
		processPermissions(permissions, selectedPermissions);
	} 

	
	return { permissionsMap: selectedPermissions, disabled };
};

export const getDefaultPermissions = (currentUserTerminal, selectedGroup) => {
	console.log('selectedGroup', selectedGroup);
	if (!selectedGroup) return { permissionsMap: {}, disabled: true };
	if (currentUserTerminal && currentUserTerminal.group.name === selectedGroup.name) {
		return getPermissionsMap(currentUserTerminal.group.permissions, [], true)
	}
	return getPermissionsMap(selectedGroup.permissions, [], true)
}


// Функция для нахождения должности на терминале
export const getUserTerminalGroup = (data, terminalId) => {
	const terminal = data.find((terminal) => terminal.id === terminalId); 
	return terminal.group;
};