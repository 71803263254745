import api from '../../api.js';

export const registration = api.injectEndpoints({
	endpoints: (builder) => ({
		regClient: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/accounts/register/`,
					method: 'POST',
					body: {
						...payload,
					},
				};
			},
		}),
		postCode: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/accounts/send_code/`,
					method: 'POST',
					body: {
						...payload,
					},
				};
			},
		}),
		postCheckCode: builder.mutation({
			query: ({ ...payload }) => {
				return {
					url: `/api/accounts/check_code/`,
					method: 'POST',
					body: {
						...payload,
					},
				};
			},
		}),
	}),
});

export const { useRegClientMutation, usePostCodeMutation, usePostCheckCodeMutation } = registration;
