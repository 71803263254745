 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const organizations = api.injectEndpoints({
	endpoints: (builder) => ({
		getClientsList: builder.query({
			query: (params, id) => {
				let queryParams = { ...params };
				if (params?.id) {
					queryParams.id = params.id;
				}
				delete queryParams.uniq;
				return {
					url: `/api/erp/organization/list/?type=2&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getClientById: builder.query({
			query: (id) => {
				return {
					url: `/api/erp/organization/list/?type=2&id=${id}`,
					method: 'GET',
				};
			},
		}),
		getTransportersList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/erp/organization/list/?type=3&${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getTransporterById: builder.query({
			query: (id) => {
				return {
					url: `/api/erp/organization/list/?type=3&id=${id}`,
					method: 'GET',
				};
			},
		}),
		getContractsList: builder.query({
			query: (orgId) => {
				return {
					url: `/api/erp/organization/${orgId}/contract/list/`,
					method: 'GET',
				};
			},
		}),
		getOneContract: builder.query({
			query: ({ orgId, contractId }) => {
				return {
					url: `/api/erp/organization/${orgId}/contract/${contractId}/file/`,
					method: 'GET',
				};
			},
		}),
		updateOrg: builder.mutation({
			query: ({ orgId, ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/organization/${orgId}/update/`,
					method: 'PUT',
					body: { 
						...validatedData
					},
				}
			}
		}),
		createClient: builder.mutation({
			query: ({ ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/client/create/`,
					method: 'POST',
					body: {
						...validatedData
					},
				}
			}
		}),
		createOrgClient: builder.mutation({
			query: ({ ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/organization/worker/create/`,
					method: 'POST',
					body: {
						...validatedData
					},
				}
			}
		}),
		updateOrgClient: builder.mutation({
			query: ({ userId, ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/organization/worker/${userId}/update/`,
					method: 'PUT',
					body: {
						...validatedData
					},
				}
			}
		}),
		createContract: builder.mutation({
			query: ({ orgId, formData }) => { 
				return {
					url: `/api/erp/organization/${orgId}/contract/create/`,
					method: 'POST',
					body: formData
				}
			}
		})
	}),
});

export const {
	useGetClientsListQuery,
	useLazyGetClientsListQuery,
	useLazyGetTransportersListQuery,
	useLazyGetClientByIdQuery,
	useLazyGetContractsListQuery,
	useLazyGetOneContractQuery,
	useUpdateOrgMutation,
	useCreateClientMutation,
	useCreateContractMutation,
	useLazyGetTransporterByIdQuery,
	useCreateOrgClientMutation,
	useUpdateOrgClientMutation,
} = organizations;
