import { useCallback, useEffect } from "react";
import { filterTouchedFields } from "../../utils/filterTouchedFields";
import { useUpdateVehiclesMutation } from "../../../../store/newApi/vehicles/vehicles";

export const useHandleUpdate = ({ entityIds, handleClose, ...methods }) => {
    const { formState: { touchedFields }, reset: resetForm } = methods;
    const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateVehiclesMutation();

    useEffect(() => {
        if (isUpdateSuccess) {
            handleClose();
            resetUpdate();
            resetForm();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isUpdateSuccess, handleClose, resetUpdate, resetForm]);

    const handleUpdate = useCallback((value) => {
        const touchedFieldValues = filterTouchedFields(value, touchedFields);
        update({
            ids: entityIds,
            ...touchedFieldValues
        })
    }, [entityIds, update, touchedFields]);

    return {
        handleUpdate,
        isUpdateSuccess,
        isUpdateLoading
    }
}