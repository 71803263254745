import { useCallback, useEffect, useState } from "react";
import { useUpdateOrderMutation } from "../../../../../../../store/newApi/orders/orders";
import InputText from "../../../../../../../newComponents/Inputs/InputText/InputText";
import { Button } from "@mui/material";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";

export const OrderCommentStep = ({ onCancel, onBack, defaultComment, orderId, terminalId }) => {
    const [updateOrder, { data, isSuccess, reset }] = useUpdateOrderMutation();
    const [comment, setComment] = useState("");

    const handleFinish = useCallback(() => {
        if (orderId && terminalId)
            updateOrder({
                terminalId,
                orderId,
                comment
            });
    }, [updateOrder, comment, orderId, terminalId]);

    useEffect(() => {
        if (isSuccess) {
            reset();
            onCancel();
        }
    }, [isSuccess, reset, onCancel]);
    
    useEffect(() => {
        if (defaultComment)
            setComment(defaultComment)
    }, [defaultComment]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
            <div style={{ padding: '10px' }}>
                {/* @ts-ignore */}
                <InputText
                    defaultValue={defaultComment}
                    value={comment}
                    placeholder="Введите текст"
                    height='200px'
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Отмена',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onCancel
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Назад',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    },
                    {
                        text: 'Сохранить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: handleFinish
                    }
                ]}
            />
        </div>
    )
}