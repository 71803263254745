import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import api from '../../api.js';

export enum DocTemplates {
    ACT_ACCEPTANCE = 1,
    ACT_OUT = 2,
    ENTRANCE = 3,
}

type PrintDocPayload = {
    template_id: DocTemplates,
    instance_id: number,
}

export const containers = api.injectEndpoints({
    endpoints: (builder) => ({
        printDoc: builder.mutation({
            query: ({ template_id, instance_id }: PrintDocPayload) => {
                return {
                    url: `/api/print/`,
                    method: 'POST',
                    body: {
                        template_id,
                        instance_id,
                    },
                    responseHandler: (response) => response.blob(),
                };
            },
            transformResponse: (response) => {
                // @ts-ignore
                const blob = new Blob([response]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'document.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        }),
    }),
});

export const { usePrintDocMutation } = containers;
