import styles from '../RegistrationClient.module.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const RegistrationClientSuccess = () => {
	const navigate = useNavigate();

	return (
		<div className={styles.modalContainer}>
			<div>
				<p className={styles.modalTitle}>Поздравляем!</p>
				<p>Вы успешно прошли регистрацию!</p>
				<div className={styles.modalStack}>
					<Button variant="contained" color="primary" size="medium" onClick={() => navigate('/authClient')}>
						Войти
					</Button>
				</div>
			</div>
		</div>
	);
};
