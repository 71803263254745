import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';
import MenuDesktop from './Menu/MenuDesktop/MenuDesktop';
import MenuMobile from './Menu/MenuMobile/MenuMobile';
import SideBarModal from '../../../components/Modal/SideBarModal';
import { useContext } from 'react';
import { SideMenuContext } from '../../../shared/providers/SideMenuProvider/SideMenuProvider';
import { useMediaQuery } from '@mui/material';

function Layout() {

    // отслеживание ширины окна
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    const { opened } = useContext(SideMenuContext);

    return (
        <div className={styles.layout}>
            {/* меню декстоп версии */}
            {isDesktop ? (
                <div className={styles.layout__aside}>
                    <MenuDesktop />
                </div>
            ) : ""}

            {!isDesktop && opened ? (
                <div className={styles.mobileSideMenu}>
                    <MenuDesktop />
                </div>
            ) : ""}

            {/* контент */}
            <Outlet />

            {/* меню моб версии */}
            {/* {isDesktop ? "" : (
                <>
                    <MenuMobile />
                </>
            )} */}

            {/* модальное окно */}
            <SideBarModal />
        </div>
    );
}

export default Layout;
