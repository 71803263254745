import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { getInitialState } from './utils.js';
import { filter } from './Filters.styles.js';
import { SearchInput } from '../SearchInput/SearchInput';
import { FilterModal } from './FilterModal'; 
import { IconFilter, IconCross } from '../../../assets/Icons'; 

type FiltersProps = {
	enabled: boolean;
	parameters: any;
	onSetFilter: (updatedState: any) => void;
	onResetFilter: () => void;
};

export const Filters = ({ enabled = true, parameters = [], onSetFilter, onResetFilter }: FiltersProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const [filterState, setFilterState] = useState(getInitialState(parameters));

	useEffect(() => {
		return () => {
			onResetFilter();
		};
	}, []);

	const toggleDrawer = (open) => {
		setIsDrawerOpen(open);
	};

	const handleSearch = (term) => {
		setFilterState((prevState) => {
			const updatedState = { ...prevState };
			updatedState['search'] = term;
			onSetFilter(updatedState);
			return updatedState;
		});

		setIsDrawerOpen(false);
	};

	return (
		<Box sx={filter.wrapper}>
			<Box sx={filter.row}>
				<SearchInput onSearch={handleSearch} />

				{enabled && (
					<IconButton
						sx={{ backgroundColor: '#F5F7FA', borderRadius: '8px', padding: '8px 12px' }}
						onClick={() => toggleDrawer(true)}
					>
						<IconFilter size={'24'} color={'#718EBF'} />
					</IconButton>
				)}
			</Box>

			{isDrawerOpen && (
				<IconButton sx={filter.btnClose} onClick={() => toggleDrawer(false)}>
					<IconCross size={'17px'} color={'#718EBF'} />
				</IconButton>
			)}

			<FilterModal
				parameters={parameters}
				onSetFilter={onSetFilter}
				onResetFilter={onResetFilter}
				isDrawerOpen={isDrawerOpen}
				setIsDrawerOpen={setIsDrawerOpen}
			/>
		</Box>
	);
};
