import { Typography } from "@mui/material"

import styles from './RepairItem.module.css';

const getStatusColor = (statusId) => {
    switch(statusId) {
        case 0:
        case 3:
            return '#BA1A1A';
        case 1:
        case 4:
            return '#5C53A7';
        case 2:
        case 5:
            return '#149527';
        default:
            return 'black';
    }
}

export type RepairItemProps = {
    component: string,
    side: string,
    damageName: string,
    displayStatus?: string,
    stepCount?: number,
    totalPrice?: number,
    status?: number,
    onClick: () => void,
}

export const RepairItem = ({
    component,
    side,
    damageName,
    displayStatus,
    stepCount,
    totalPrice,
    status,
    onClick
}: RepairItemProps) => {
    return (
        <div className={styles.repairItem} onClick={onClick}>
            <div className={styles.repairItemLeft}>
                {/* @ts-ignore */}
                <Typography variant="alertTitle">
                    {component}
                </Typography>
                <div style={{ marginTop: '12px', fontSize: '12px' }}>
                    <b>Сторона:</b> {side}
                </div>
                <div style={{ fontSize: '12px' }}>
                    <b>Названия повреждений:</b> {damageName}
                </div>
            </div>
            <div className={styles.repairItemRight}>
                {/* @ts-ignore */}
                {!!displayStatus && <Typography variant="alertTitle" color={getStatusColor(status)}>
                    {displayStatus}
                </Typography>}
                {!!stepCount && <div style={{ marginTop: '12px', fontSize: '12px' }}>
                    Кол-во шагов: {stepCount}
                </div>}
                {!!totalPrice && <div style={{ fontSize: '12px' }}>
                    Цена: {totalPrice}
                </div>}
            </div>
        </div>
    )
}