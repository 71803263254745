export const IconHomeHashtag = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M17.79 22.7395H6.21C3.47 22.7395 1.25 20.5095 1.25 17.7695V10.3595C1.25 8.99948 2.09 7.28948 3.17 6.44948L8.56 2.24948C10.18 0.989476 12.77 0.929476 14.45 2.10948L20.63 6.43948C21.82 7.26948 22.75 9.04948 22.75 10.4995V17.7795C22.75 20.5095 20.53 22.7395 17.79 22.7395ZM9.48 3.42948L4.09 7.62948C3.38 8.18948 2.75 9.45948 2.75 10.3595V17.7695C2.75 19.6795 4.3 21.2395 6.21 21.2395H17.79C19.7 21.2395 21.25 19.6895 21.25 17.7795V10.4995C21.25 9.53948 20.56 8.20948 19.77 7.66948L13.59 3.33948C12.45 2.53948 10.57 2.57948 9.48 3.42948Z"
					fill={color}
				/>
				<path
					d="M13.5 18.7495H10.5C8.43 18.7495 6.75 17.0695 6.75 14.9995V11.9995C6.75 9.92951 8.43 8.24951 10.5 8.24951H13.5C15.57 8.24951 17.25 9.92951 17.25 11.9995V14.9995C17.25 17.0695 15.57 18.7495 13.5 18.7495ZM10.5 9.74951C9.26 9.74951 8.25 10.7595 8.25 11.9995V14.9995C8.25 16.2395 9.26 17.2495 10.5 17.2495H13.5C14.74 17.2495 15.75 16.2395 15.75 14.9995V11.9995C15.75 10.7595 14.74 9.74951 13.5 9.74951H10.5Z"
					fill={color}
				/>
				<path
					d="M12 18.7495C11.59 18.7495 11.25 18.4095 11.25 17.9995V8.99951C11.25 8.58951 11.59 8.24951 12 8.24951C12.41 8.24951 12.75 8.58951 12.75 8.99951V17.9995C12.75 18.4095 12.41 18.7495 12 18.7495Z"
					fill={color}
				/>
				<path
					d="M16.5 14.2495H7.5C7.09 14.2495 6.75 13.9095 6.75 13.4995C6.75 13.0895 7.09 12.7495 7.5 12.7495H16.5C16.91 12.7495 17.25 13.0895 17.25 13.4995C17.25 13.9095 16.91 14.2495 16.5 14.2495Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
