export const IconUserSquare = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M15,22.74H9a11.66,11.66,0,0,1-3.35-.41.77.77,0,0,1-.54-.78c.25-3,3.28-5.34,6.89-5.34s6.63,2.34,6.89,5.34a.74.74,0,0,1-.54.78A11.66,11.66,0,0,1,15,22.74ZM6.72,21.05A11.85,11.85,0,0,0,9,21.24h6a11.85,11.85,0,0,0,2.28-.19c-.53-1.92-2.72-3.34-5.28-3.34S7.25,19.13,6.72,21.05Z"
					fill={color}
				/>
				<path
					d="M12.18,14.91a4.34,4.34,0,1,1,4.33-4.34A4.34,4.34,0,0,1,12.18,14.91Zm0-7.17A2.84,2.84,0,1,0,15,10.57,2.83,2.83,0,0,0,12.18,7.74Z"
					fill={color}
				/>
				<path
					d="M16.64,2.27H7.72A5.93,5.93,0,0,0,1.79,8.2v8.92a5.92,5.92,0,0,0,5.93,5.93h8.92a5.92,5.92,0,0,0,5.93-5.93V8.2A5.93,5.93,0,0,0,16.64,2.27ZM21.27,16.7a4.93,4.93,0,0,1-4.92,4.93H8A4.93,4.93,0,0,1,3.09,16.7V8.62A4.93,4.93,0,0,1,8,3.69h8.34a4.93,4.93,0,0,1,4.92,4.93Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
