export const primary = {
	10: '#170362',
	20: '#2D2276',
	30: '#443A8E',
	40: '#5C53A7',
	50: '#756CC2',
	60: '#8F86DE',
	70: '#AAA0FB',
	80: '#C7BFFF',
	90: '#E4DFFF',
	95: '#F3EEFF',
};

export const secondary = {
	10: '#101C2B',
	20: '#263141',
	30: '#3C4858',
	40: '#535F70',
	50: '#6C788A',
	60: '#D47B00',
	70: '#FF9500',
	80: '#BBC7DB',
	90: '#D7E3F8',
	95: '#EAF1FF',
};

export const error = {
	10: '#410002',
	20: '#690005',
	30: '#93000A',
	40: '#BA1A1A',
	50: '#DE3730',
	60: '#FF5449',
	70: '#FF897D',
	80: '#FFB4AB',
	90: '#FFDAD6',
	95: '#FFEDEA',
};

export const warning = {
	10: '#2D1600',
	20: '#4B2800',
	30: '#6A3B00',
	40: '#8C5000',
	50: '#AF6500',
	60: '#D47B00',
	70: '#FF9500',
	80: '#FFB874',
	90: '#FFDCBF',
	95: '#FFEEE1',
};

export const successful = {
	10: '#001501',
	20: '#003907',
	30: '#00530E',
	40: '#006E17',
	50: '#008A1F',
	60: '#2EA636',
	70: '#4DC24F',
	80: '#6ADF67',
	90: '#86FC80',
	95: '#C9FFBE',
};

export const gray = {
	20: '#AEAEB2',
	30: '#C7C7CC',
	40: '#D1D1D6',
	50: '#E5E5EA',
	60: '#F2F2F7',
	70: '#F9F9FB',
};

export const dark = {
	0: '#000000',
	5: '#1E1E1E',
	10: '#1C1C1E',
	20: '#2C2C2E',
	30: '#3A3A3C',
	40: '#48484A',
	50: '#636366',
	60: '#8E8E93',
	100: '#FFFFFF',
};
