import { useCallback, useEffect } from 'react';
import { useCreateRepairTariffMutation } from '../../../../store/newApi/service/service';

export const useHandleCreate = ({ handleClose, ...methods }) => {
	const { reset: resetForm } = methods;
	const [createRepairTariff, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateRepairTariffMutation }] =
		useCreateRepairTariffMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			handleClose();
			resetCreateRepairTariffMutation();
			resetForm();
			// TODO: Вызвать нотификашку
		}

		return () => {
			resetForm();
		};
	}, [isCreateSuccess, handleClose, resetCreateRepairTariffMutation, resetForm]);

	const handleCreate = useCallback(
		(value) => {
			createRepairTariff({
				terminal: value.terminal,
				name: value.name,
				repair_component: value.repair_component,
				unit: value.unit,
				labor_rate_per_hour: parseFloat(value.labor_rate_per_hour),
				size_step: parseFloat(value.size_step),
				hour_first_step: parseFloat(value.hour_first_step),
				material_first_step: parseFloat(value.material_first_step),
				size_next_step: parseFloat(value.size_next_step),
				hour_next_step: parseFloat(value.hour_next_step),
				material_next_step: parseFloat(value.material_next_step),
			});
		},
		[createRepairTariff],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
