import { useCallback, useEffect } from 'react';
import { useLazyGetRepairComponentsQuery } from '../../../store/newApi/service/service';
import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';

export const RepairComponentsInputAutocomplete = ({ value, onChange, searchParams, ...field }) => {
	const [trigger, { repairComponentsOptions = [], loading: loadingComp }] = useLazyGetRepairComponentsQuery({
		selectFromResult: ({ data }) => ({
			repairComponentsOptions: data?.results?.map((e) => ({ label: e.name, id: e.id })) || [],
		}),
	});

	const handleSearchComp = useCallback(
		(value) => {
			trigger({ search: value });
		},
		[trigger],
	);

	useEffect(() => { 
		trigger({ ...searchParams });
	}, [searchParams, trigger]) 

	return (
		<InputAutocompleteAsync
			{...field}
			value={value}
			fetchFn={handleSearchComp}
			placeholder="Выберите или введите вручную"
			options={repairComponentsOptions}
			loading={loadingComp}
			onChange={onChange}
		/>
	);
};
