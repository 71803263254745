import { Button, useMediaQuery } from '@mui/material';
import Header from '../../../../../newComponents/Header/Header';
import styles from './AccountEmplOneClient.module.css';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabInfoTransporter } from './components/TabInfo/TabInfoTransporter';
import { useLazyGetTransporterByIdQuery } from '../../../../../store/newApi/organizations/organizations'; 
import { PAGE_TRANSPORTERS } from '../../../../../shared/transporters/pageUrls';

export const AccountEmplOneTransporter = () => {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();
	const { transporterId } = useParams();
	const [getTransporter, { data: dataTransporter }] = useLazyGetTransporterByIdQuery(); 

	useEffect(() => {
		if (transporterId) {
			getTransporter(transporterId);
		}
	}, [transporterId]);

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
			<Header text={dataTransporter ? `Перевозчик ${dataTransporter?.results?.[0].organization_name}` : 'Создать перевозчика'} />
			<div className={styles.content}>
				<div className={styles.tabs}>
					<Button variant="contained" color="secondary" size={isDesktop ? 'medium' : 'small'}>
						Общее
					</Button>
				</div>
				<div className={styles.steps}>
					<TabInfoTransporter data={dataTransporter?.results[0]} onBack={() => navigate(PAGE_TRANSPORTERS)} />
				</div>
			</div>
		</div>
	);
};
