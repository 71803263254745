import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { Button } from '@mui/material';
import { useLazyGetStorageOrdersListQuery } from '../../../../store/newApi/service/service';
 
import { formatDate } from '../../../../helpers/formatDate';
import { formatPrice } from '../../../../helpers/formatText';
import { FILTER_TYPES } from '../../../../config/filter';
import { resetFilter, setFilter, getExportExcel } from '../../../../store/rtkSlices/storage.js';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { StorageForm } from '../../../../newComponents/Form/StorageForm/StorageForm.jsx';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { LINK_CLIENT_BY_ID } from '../../../../shared/clients/pageUrls';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplTableStorage() {
	const userType = Cookies.get('userType');
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});
	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
	const [fetchStorageOrders, { data, isLoading, isError }] = useLazyGetStorageOrdersListQuery();
	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsCreateFormOpen(true);
	}, []);

	// колонки
	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => row.container_number,
				id: 'container_number',
				header: 'Контейнер',
				size: 200,
			},
			{
				accessorFn: (row) => (row.date_from ? formatDate(row.date_from) : '-'),
				id: 'date_from',
				header: 'Дата начала',
				size: 200,
			},
			{
				accessorFn: (row) => (row.date_to ? formatDate(row.date_to) : '-'),
				id: 'date_to',
				header: 'Дата окончания',
				size: 200,
			},
			{
				accessorFn: (row) => {
					const tariff = row.client_tariff;
					if (tariff && tariff.includes('/')) {
						const [price] = tariff.split('/');
						return formatPrice(parseFloat(price));
					}
					return formatPrice(row.client_tariff);
				},
				id: 'client_tariff',
				header: 'Тариф клиента, ₽/день',
				size: 280,
			},
			{
				accessorFn: (row) => (row.total_price ? formatPrice(row.total_price) : '-'),
				id: 'service_order__service_order_prices__total_price',
				header: 'Стоимость',
				size: 200,
			},
			{
				accessorFn: (row) => row.free_days_storage,
				id: 'free_days_storage',
				header: 'Бесплатные дни',
				size: 200,
				sortDescFirst: false,
			},
		];

		if (userType == 1) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = LINK_CLIENT_BY_ID(row.client_id);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client}
						</a>
					);
				},
				id: 'client',
				header: 'Клиент',
				size: 250,
				sortDescFirst: false,
			});
		}

		return baseColumns;
	}, [userType]);

	// порядок колонок
	const columnOrdering = [
		'mrt-row-select',
		'container_number',
		'client',
		'client_tariff',
		'service_order__service_order_prices__total_price',
		'free_days_storage',
		'date_from',
		'date_to',
	];

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'id=' + Object.keys(rowSelection).join(',');
		}
		dispatch(getExportExcel(viewCols));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Хранение'}
				btnDesktop={[
					isEmpl() && selectedRows.length === 1 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					isEmpl() && (
						<Button variant="contained" color="primary" size="medium" onClick={handleCreate}>
							Добавить акт
						</Button>
					),
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,
				]}

				btnMobile={[
					isEmpl() && selectedRows.length === 1 && (
						<Button variant="contained" color="secondary" size="small" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					isEmpl() && (
						<Button variant="contained" color="primary" size="small" onClick={handleCreate}>
							Добавить
						</Button>
					),
					<Button variant="contained" color="primary" size="small" onClick={handleExportExcel}>
						Экспорт
					</Button>,
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: 'client_ids',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
							{
								name: ['created_at_after', 'created_at_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['client_tariff_gte', 'client_tariff_lte'],
								title: 'Тариф клиента',
								text: 'тариф',
								type: FILTER_TYPES.INPUTNUMRANGE,
								elements: [],
							},
							{
								name: ['date_from_after', 'date_from_before'],
								title: 'Дата начала',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
							{
								name: ['date_to_after', 'date_to_before'],
								title: 'Дата окончания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}> 
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchStorageOrders}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="storageRtk"
					/>
					<StorageForm isOpen={isCreateFormOpen} handleClose={() => setIsCreateFormOpen(false)} entityIds={selectedRows} />
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableStorage;
