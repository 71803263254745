import { useEffect, useRef, useState } from "react";

export const useInfiniteScrollFetching = ({ useMutation, filters }) => {
    const [trigger, { data, isLoading, isFetching }] = useMutation({});

    const containerRef = useRef(null);
    const currentPage = useRef(1);

    const [renderedItems, setRenderedItems] = useState([]);

    useEffect(() => {
		trigger({ ...filters, page: 1, page_size: 30 });
		currentPage.current = 1
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 30, uniq: Math.random() });
		currentPage.current = 1
	}, [filters]);

    useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !isFetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > renderedItems?.length) {
						const nextPage = currentPage.current + 1;
						trigger({ ...filters, page: nextPage, page_size: 30 });
						currentPage.current = nextPage
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [isFetching, isLoading, data, renderedItems]);

    // Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setRenderedItems((prevData) => {
                if (currentPage.current === 1)
                    return [...data.results]
                return [...prevData, ...data.results];
			});
		}
	}, [data]);

    return {
        containerRef,
        renderedItems,
        isLoading,
        isFetching
    }
}