export const IconTruckTime = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M13 14.7424H2C1.59 14.7424 1.25 14.4024 1.25 13.9924V7.61243C1.25 7.29243 1.44999 7.0124 1.73999 6.9024C2.03999 6.7924 2.37001 6.88244 2.57001 7.12244C3.18001 7.85244 4.13001 8.26242 5.07001 8.23242C5.87001 8.21242 6.60998 7.91245 7.16998 7.38245C7.42998 7.16245 7.63999 6.90243 7.79999 6.61243C8.10999 6.08243 8.26 5.50241 8.25 4.91241C8.23 3.99241 7.83002 3.15242 7.15002 2.54242C6.91002 2.33242 6.83 2.0024 6.94 1.7124C7.05 1.4224 7.33001 1.22241 7.64001 1.22241H15C15.41 1.22241 15.75 1.56241 15.75 1.97241V11.9724C15.75 13.5124 14.52 14.7424 13 14.7424ZM2.75 13.2424H13C13.69 13.2424 14.25 12.6824 14.25 11.9924V2.74243H9.19C9.54 3.39243 9.73 4.12245 9.75 4.88245C9.77 5.75245 9.54998 6.61243 9.10999 7.36243C8.86999 7.79243 8.53999 8.20244 8.17999 8.50244C7.37999 9.26244 6.27999 9.71243 5.10999 9.74243C4.27999 9.77243 3.46001 9.56243 2.76001 9.18243V13.2424H2.75Z"
					fill={color}
				/>
				<path
					d="M19 20.7424H18C17.59 20.7424 17.25 20.4024 17.25 19.9924C17.25 19.3024 16.69 18.7424 16 18.7424C15.31 18.7424 14.75 19.3024 14.75 19.9924C14.75 20.4024 14.41 20.7424 14 20.7424H10C9.59 20.7424 9.25 20.4024 9.25 19.9924C9.25 19.3024 8.69 18.7424 8 18.7424C7.31 18.7424 6.75 19.3024 6.75 19.9924C6.75 20.4024 6.41 20.7424 6 20.7424H5C2.93 20.7424 1.25 19.0624 1.25 16.9924V13.9924C1.25 13.5824 1.59 13.2424 2 13.2424H13C13.69 13.2424 14.25 12.6824 14.25 11.9924V4.99243C14.25 4.58243 14.59 4.24243 15 4.24243H16.84C17.83 4.24243 18.74 4.77245 19.23 5.63245L20.94 8.62244C21.07 8.85244 21.07 9.14244 20.94 9.37244C20.81 9.60244 20.56 9.74243 20.29 9.74243H19C18.86 9.74243 18.75 9.85243 18.75 9.99243V12.9924C18.75 13.1324 18.86 13.2424 19 13.2424H22C22.41 13.2424 22.75 13.5824 22.75 13.9924V16.9924C22.75 19.0624 21.07 20.7424 19 20.7424ZM18.65 19.2424H19C20.24 19.2424 21.25 18.2324 21.25 16.9924V14.7424H19C18.04 14.7424 17.25 13.9524 17.25 12.9924V9.99243C17.25 9.03243 18.03 8.24243 19 8.24243L17.93 6.37244C17.71 5.98244 17.29 5.74243 16.84 5.74243H15.75V11.9924C15.75 13.5124 14.52 14.7424 13 14.7424H2.75V16.9924C2.75 18.2324 3.76 19.2424 5 19.2424H5.34998C5.67998 18.0924 6.74 17.2424 8 17.2424C9.26 17.2424 10.32 18.0924 10.65 19.2424H13.36C13.69 18.0924 14.75 17.2424 16.01 17.2424C17.27 17.2424 18.32 18.0924 18.65 19.2424Z"
					fill={color}
				/>
				<path
					d="M8 22.7424C6.48 22.7424 5.25 21.5124 5.25 19.9924C5.25 18.4724 6.48 17.2424 8 17.2424C9.52 17.2424 10.75 18.4724 10.75 19.9924C10.75 21.5124 9.52 22.7424 8 22.7424ZM8 18.7424C7.31 18.7424 6.75 19.3024 6.75 19.9924C6.75 20.6824 7.31 21.2424 8 21.2424C8.69 21.2424 9.25 20.6824 9.25 19.9924C9.25 19.3024 8.69 18.7424 8 18.7424Z"
					fill={color}
				/>
				<path
					d="M16 22.7424C14.48 22.7424 13.25 21.5124 13.25 19.9924C13.25 18.4724 14.48 17.2424 16 17.2424C17.52 17.2424 18.75 18.4724 18.75 19.9924C18.75 21.5124 17.52 22.7424 16 22.7424ZM16 18.7424C15.31 18.7424 14.75 19.3024 14.75 19.9924C14.75 20.6824 15.31 21.2424 16 21.2424C16.69 21.2424 17.25 20.6824 17.25 19.9924C17.25 19.3024 16.69 18.7424 16 18.7424Z"
					fill={color}
				/>
				<path
					d="M22 14.7424H19C18.04 14.7424 17.25 13.9524 17.25 12.9924V9.99243C17.25 9.03243 18.04 8.24243 19 8.24243H20.29C20.56 8.24243 20.81 8.38244 20.94 8.62244L22.65 11.6224C22.71 11.7324 22.75 11.8624 22.75 11.9924V13.9924C22.75 14.4024 22.41 14.7424 22 14.7424ZM19 9.74243C18.86 9.74243 18.75 9.85243 18.75 9.99243V12.9924C18.75 13.1324 18.86 13.2424 19 13.2424H21.25V12.1924L19.85 9.74243H19Z"
					fill={color}
				/>
				<path
					d="M5 9.74243C2.38 9.74243 0.25 7.61243 0.25 4.99243C0.25 3.53243 0.900029 2.18241 2.03003 1.28241C2.87003 0.61241 3.93 0.242432 5 0.242432C7.62 0.242432 9.75 2.37243 9.75 4.99243C9.75 6.35243 9.16 7.65243 8.13 8.55243C7.26 9.32243 6.15 9.74243 5 9.74243ZM5 1.74243C4.26 1.74243 3.55997 1.9924 2.96997 2.4624C2.19997 3.0724 1.75 4.00243 1.75 4.99243C1.75 6.78243 3.21 8.24243 5 8.24243C5.78 8.24243 6.54002 7.95243 7.15002 7.43243C7.85002 6.81243 8.25 5.93243 8.25 4.99243C8.25 3.20243 6.79 1.74243 5 1.74243Z"
					fill={color}
				/>
				<path
					d="M3.99989 6.74243C3.74989 6.74243 3.49988 6.61245 3.35988 6.38245C3.14988 6.02245 3.25989 5.56242 3.61989 5.35242L4.5099 4.82245V3.74243C4.5099 3.33243 4.8499 2.99243 5.2599 2.99243C5.6699 2.99243 6.0099 3.33243 6.0099 3.74243V5.24243C6.0099 5.50243 5.86992 5.75245 5.64992 5.88245L4.39992 6.63245C4.25992 6.71245 4.12989 6.74243 3.99989 6.74243Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
