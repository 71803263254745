import styles from './TabInfo.module.css';
import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useHandleUpdate } from '../hooks/useHandleUpdate';
import { useInitFormState } from '../hooks/useInitFormState';
import { FormWrapper } from '../../../../../../../newComponents/Form/FormWrapper';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';

const createSchema = z.object({
	full_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	password: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	username: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

const updateSchema = z.object({
	full_name: z.string().optional(),
	password: z.string().optional(),
	username: z.string().optional(),
});

export const TerminalEmplForm = ({ data, onBack, setCurrentStep, setDataInputs }) => {
	const resolver = useMemo(() => (data ? zodResolver(updateSchema) : zodResolver(createSchema)), [data]);

	const methods = useForm({
		defaultValues: {
			full_name: '',
			password: '',
			username: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods });

	useInitFormState({ data, ...methods });

	const onSubmit = useCallback(
		(value) => {
			if (data) handleUpdate({ userId: data.id, value });
			else {
				setDataInputs(value)
				setCurrentStep(1);
			} 
		},
		[ handleUpdate, data],
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<div className={styles.containerFormTemplate}>
					<Controller
						name="username"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="Логин" placeholder="Логин" />
								{errors?.username && (
									<Typography color="error" variant="caption">
										{errors?.username?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="full_name"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="Имя пользователя" placeholder="Имя пользователя" />
								{errors?.full_name && (
									<Typography color="error" variant="caption">
										{errors?.full_name?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="Пароль" placeholder="Пароль" />
								{errors?.password && (
									<Typography color="error" variant="caption">
										{errors?.password?.message}
									</Typography>
								)}
							</div>
						)}
					/>
				</div>
			</FormWrapper>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				{data && <Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>Сохранить</Button>}
				{!data && <Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>Далее</Button>}
			</div>
		</div>
	);
};
