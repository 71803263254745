import { Typography, Box, Grid } from '@mui/material'; 
import { Card } from '../../../../shared/ui/Card/Card';

type TerminalCardProps = {
	name: string;
	address: string;
};

export const TerminalCard = ({ name, address }: TerminalCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Grid container sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center', flexWrap: 'nowrap' }}>
						<Grid item>
							<Typography variant="subtitle1">{name}</Typography>
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2">Адрес: {address}</Typography>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
