import { useCallback, useEffect } from 'react';
import { useCreateStaffMutation } from '../../../../../../../store/newApi/users/users';

export const useHandleCreate = ({ successCallback = () => {}, ...methods }) => {

	const [createStaff, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateStaffMutation }] =
		useCreateStaffMutation();

	useEffect(() => {
		if (isCreateSuccess) {
			resetCreateStaffMutation(); 
			successCallback()
		} 
	}, [isCreateSuccess, resetCreateStaffMutation, successCallback]);

	const handleCreate = useCallback(
		({ value, staff_terminal }) => {
			createStaff({
				full_name: value.full_name,
				password: value.password,
				username: value.username,
				staff_terminal: staff_terminal,
			});
		},
		[createStaff],
	);

	return {
		handleCreate,
		isCreateSuccess,
		isCreateLoading,
	};
};
