import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { Switcher } from '../../../../../../components';
import { useCreateTerminalMutation, useUpdateTerminalMutation } from '../../../../../../store/newApi/terminals/terminals';
import RequireCheckRights from '../../../../../../hoc/RequireCheckRights';

const defaultSwitches = [
	{ key: 'transport_hub', label: 'Автотранспорт' },
	{ key: 'railway_hub', label: 'Железнодорожный транспорт' },
	{ key: 'port_hub', label: 'Порт' },
	{ key: 'cargo_storage_module', label: 'Хранение грузов' },
	{ key: 'repacking_module', label: 'Перетарка грузов' },
	{ key: 'container_storage_module', label: 'Хранение контейнеров' },
	{ key: 'repair_module', label: 'Ремонт контейнеров' },
	{ key: 'bookkeeping_module', label: 'Бухгалтерия' },
];

export const TabTerminalModules = ({ data, onBack, values }) => {
	const [createTerminal] = useCreateTerminalMutation();
	const [updateTerminal] = useUpdateTerminalMutation();
	const [switchState, setSwitchState] = useState({});
	const [initialSwitchState, setInitialSwitchState] = useState({});

	useEffect(() => {
		// Устанавливаем начальное состояние свитчеров при получении данных
		const initialState = defaultSwitches.reduce((acc, { key }) => {
			acc[key] = data ? !!data[key] : false;
			return acc;
		}, {});
		setSwitchState(initialState);
		setInitialSwitchState(initialState);
	}, [data]);

	const handleSwitchChange = (name) => (newChecked) => {
		setSwitchState((prevState) => ({
			...prevState,
			[name]: newChecked,
		}));
	};

	const handleSave = () => {
		if (data) {
			updateTerminal({ terminalId: data?.id, ...switchState });
			onBack();
		} else {
			const combinedData = {
				name: values.name,
				address: values.address,
				max_teu: values.max_teu,
				contact_number: values.contact_number,
				schedule: {
					additionalProp1: values.additionalProp1,
					additionalProp2: values.additionalProp2,
					additionalProp3: values.additionalProp3,
				},
				...switchState,
			};
			createTerminal({ ...combinedData });
			onBack();
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				<Typography variant="btnMedium">Активные модули на терминале</Typography>
				{defaultSwitches.map(({ key, label }) => (
					<div key={key} className="switcherItem">
						<Switcher externalChecked={switchState[key]} onChecked={handleSwitchChange(key)} right={<span>{label}</span>} />
					</div>
				))}
			</div>
			<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				<RequireCheckRights path="terminals" type="change">
					<Button variant="contained" color="primary" size="medium" onClick={handleSave}>
						Сохранить
					</Button>
				</RequireCheckRights>
			</div>
		</div>
	);
};
