export const FILTER_TYPES = {
	CHECKBOX: 'checkbox',
	INPUTNUMRANGE: 'inputnumrange',
	SWITCH: 'switch',
	INPUTDROPDOWN: 'inputdropdown',
	INPUTDROPDOWNCLIENT: 'inputdropdownclient',
	INPUTDROPDOWNCOMP: 'inputdropdowncomp',
	INPUTDATERANGE: 'inputdaterange',
	// ... остальные типы фильтров
};
