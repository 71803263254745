export const IconProfileCircle = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12.1205 14.5145C12.1005 14.5145 12.0705 14.5145 12.0505 14.5145C12.0205 14.5145 11.9805 14.5145 11.9505 14.5145C9.68047 14.4445 7.98047 12.6745 7.98047 10.4945C7.98047 8.27454 9.79047 6.46454 12.0105 6.46454C14.2305 6.46454 16.0405 8.27454 16.0405 10.4945C16.0305 12.6845 14.3205 14.4445 12.1505 14.5145C12.1305 14.5145 12.1305 14.5145 12.1205 14.5145ZM12.0005 7.95454C10.6005 7.95454 9.47047 9.09454 9.47047 10.4845C9.47047 11.8545 10.5405 12.9645 11.9005 13.0145C11.9305 13.0045 12.0305 13.0045 12.1305 13.0145C13.4705 12.9445 14.5205 11.8445 14.5305 10.4845C14.5305 9.09454 13.4005 7.95454 12.0005 7.95454Z"
					fill={color}
				/>
				<path
					d="M11.9998 23.7346C9.30984 23.7346 6.73984 22.7346 4.74984 20.9146C4.56984 20.7546 4.48984 20.5146 4.50984 20.2846C4.63984 19.0946 5.37984 17.9846 6.60984 17.1646C9.58984 15.1846 14.4198 15.1846 17.3898 17.1646C18.6198 17.9946 19.3598 19.0946 19.4898 20.2846C19.5198 20.5246 19.4298 20.7546 19.2498 20.9146C17.2598 22.7346 14.6898 23.7346 11.9998 23.7346ZM6.07984 20.0846C7.73984 21.4746 9.82984 22.2346 11.9998 22.2346C14.1698 22.2346 16.2598 21.4746 17.9198 20.0846C17.7398 19.4746 17.2598 18.8846 16.5498 18.4046C14.0898 16.7646 9.91984 16.7646 7.43984 18.4046C6.72984 18.8846 6.25984 19.4746 6.07984 20.0846Z"
					fill={color}
				/>
				<path
					d="M12 23.7346C6.07 23.7346 1.25 18.9146 1.25 12.9846C1.25 7.05456 6.07 2.23456 12 2.23456C17.93 2.23456 22.75 7.05456 22.75 12.9846C22.75 18.9146 17.93 23.7346 12 23.7346ZM12 3.73456C6.9 3.73456 2.75 7.88456 2.75 12.9846C2.75 18.0846 6.9 22.2346 12 22.2346C17.1 22.2346 21.25 18.0846 21.25 12.9846C21.25 7.88456 17.1 3.73456 12 3.73456Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
