import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { createContainerSchema, importContainerSchema, updateContainerSchema } from "../utils";

export const useGetFormSchema = ({ isCreate, isImport }) => {

    const resolver = useMemo(() => {
        if (isCreate)
            return zodResolver(createContainerSchema);
        if (isImport)
            return zodResolver(importContainerSchema)
        return zodResolver(updateContainerSchema)
    }, [isCreate, isImport]);

    return {
        resolver
    }
}