import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import TableRowsRoundedIcon from '@mui/icons-material/TableRowsRounded';
import { useCallback, useState } from 'react';
import { useMediaQuery } from '@mui/material';

type ContentViewType = 'block' | 'table';

type SwitchContentViewTypeProps = {
    onClick: (viewType: ContentViewType) => void;
    defaultViewType: ContentViewType;
} 

export const SwitchContentViewType = ({ onClick, defaultViewType }: SwitchContentViewTypeProps) => {
    // @ts-ignore
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

    const [viewType, setViewType] = useState(() => defaultViewType);

    const handleChangeViewType = useCallback((viewType: ContentViewType) => {
        setViewType(viewType);
        onClick(viewType);
    }, [])

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <button onClick={() => handleChangeViewType('block')} style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: viewType === 'block' ? '#5C53A7' : '#F5F7FA',
                padding: isDesktop ? '8px' : '6px',
                borderRadius: '8px 0 0 8px'
            }}>
                <ViewModuleRoundedIcon fontSize='small' htmlColor={viewType === 'block' ? '#fff' : '#000'} />
            </button>
            <button onClick={() => handleChangeViewType('table')} style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: viewType === 'table' ? '#5C53A7' : '#F5F7FA',
                padding: isDesktop ? '8px' : '6px',
                borderRadius: '0 8px 8px 0'
            }}>
                <TableRowsRoundedIcon fontSize='small' htmlColor={viewType === 'table' ? '#fff' : '#000'} />
            </button>
        </div>
    )
}