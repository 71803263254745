import styles from './DraftAct.module.scss';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import Header from '../Header/Header';
import ContainerAcceptanceContent from './components/ContainerAcceptanceContent/ContainerAcceptanceContent';
import CheckAct from './steps/CheckAct/CheckAct';
import DamagePage from './components/DamagePage/DamagePage';
import DamageList from './components/DamageList/DamageList';
import ChoiceDamageSide from './steps/ChoiceDamageSide/ChoiceDamageSide';
import ChoiceDamageArea from './steps/ChoiceDamageArea/ChoiceDamageArea';
import DescriptionOfDamage from './steps/DescriptionOfDamage/DescriptionOfDamage';
// import DescrDamagePhoto from '../../../../../shared/ui/UploadImage/UploadImage';
import PhotoFix from './components/PhotoFix/PhotoFix';

const DraftAct = () => {
	const { reportId } = useParams(); // id акта
	const isDesktop = useMediaQuery({ maxWidth: 768 });
	const { page, damagesList, selectedDamage, currentSideDamage } = useSelector((state) => state.containerAccept);

	const pageComponents = {
		2: <ContainerAcceptanceContent title={null} child={<CheckAct />} />,
		3: <ContainerAcceptanceContent title={'Выберите сторону повреждения'} child={<ChoiceDamageSide />} />,
		4: <ContainerAcceptanceContent title={'Отметьте области повреждений'} child={<ChoiceDamageArea />} />,
		5: (
			<ContainerAcceptanceContent
				title={`${currentSideDamage?.side} - ${currentSideDamage?.damageName} ${currentSideDamage?.damagedComponentCode?.name ? ` - ${currentSideDamage?.damagedComponentCode?.name}` : ''}`}
				child={<DescriptionOfDamage />}
			/>
		),
		// 6: (
		// 	<ContainerAcceptanceContent
		// 		title={`${currentSideDamage.damageType?.name} - ${currentSideDamage.damageName}`}
		// 		child={<DescrDamagePhoto />}
		// 	/>
		// ),
		8: <ContainerAcceptanceContent title={damagesList?.find((damage) => damage?.id === selectedDamage)?.side} child={<DamagePage />} />,
		9: <ContainerAcceptanceContent title={null} child={<DamageList />} />,
		10: <ContainerAcceptanceContent title={null} child={<PhotoFix />} />,
	};

	const displayContainerAcceptance = () => pageComponents[page] || null;

	const pageTexts = {
		1: 'Проверьте информацию',
		2: `Черновик акта № ${reportId}`,
		3: 'Опишите повреждения',
		4: 'Опишите повреждения',
		5: 'Опишите повреждения',
		6: 'Сделайте фото повреждений',
		9: 'Фиксация повреждений',
		10: 'Фотофиксация контейнера',
		// 7: 'Проверьте повреждения',
		8: 'Информация о повреждении',
		// 9: 'Проверьте данные',
	};
	console.log(pageTexts[page], 'pageTexts[page]');
	return (
		<div className={styles.account__wrap}>
			<Header text={pageTexts[page] || ''} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>{isDesktop ? displayContainerAcceptance() : displayContainerAcceptance()}</div>
			</div>
		</div>
	);
};

export default DraftAct;
