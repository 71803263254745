import { Controller, useFormContext } from "react-hook-form"
import { CollapseBlock } from "../../CollapseBlock/CollapseBlock"
import { InputAutocomplete } from "../../Inputs/InputAutocomplete/InputAutocomplete";
import { useGetLinesListQuery } from "../../../store/newApi/containers/containers";

export const LineInputAutocomplete = () => {
    const { control } = useFormContext();

    const { linesOptions = [] } = useGetLinesListQuery({}, {
        selectFromResult: ({ data }) => ({
            linesOptions: data?.map((t) => ({ label: t.line_name, id: t.id }))
        })
    });

    return (
        <CollapseBlock title="Линия">
            <Controller
                name="line"
                control={control}
                render={({ field }) => (
                    <InputAutocomplete
                        {...field}
                        placeholder="Выберите или введите вручную"
                        options={linesOptions}
                    />
                )}
            />
        </CollapseBlock>
    )
}