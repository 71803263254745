export const inputText = {
	textField: {
		backgroundColor: (theme) => theme.palette.background.input,
		borderRadius: '12px',
		border: '2px solid',
		borderColor: (theme) => theme.palette.border.primary,
		padding: '5px 16px',
		color: (theme) => theme.palette.text.secondary,
		transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
		input: {
			padding: '0px',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.08px',
			color: (theme) => theme.palette.text.placeholder,
		},

		// '& .Mui-disabled': {
		// 	backgroundColor: '#8E8E93',
		// 	color: '#ffffff',
		// 	border: '1px solid #8E8E93',
		// },

		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover': {
			backgroundColor: (theme) => theme.palette.background.inputHover,
			borderColor: (theme) => theme.palette.border.primaryHover,
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme) => theme.palette.border.primaryFocus,
		},
		'& input::placeholder': {
			color: (theme) => theme.palette.text.placeholder,
			opacity: 1,
		},

		'& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},

		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
	},
	icon: {
		color: (theme) => theme.palette.text.secondary,
	},
	valueTextField: {
		backgroundColor: (theme) => theme.palette.background.inputFocus,
		input: {
			padding: '0px',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.08px',
			color: (theme) => theme.palette.text.secondary,
		},
	},
};
