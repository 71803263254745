export const IconProfile2user = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M9.15957 12.5997C9.12957 12.5997 9.10957 12.5997 9.07957 12.5997C9.02957 12.5897 8.95957 12.5897 8.89957 12.5997C5.99957 12.5097 3.80957 10.2297 3.80957 7.41968C3.80957 4.55968 6.13957 2.22968 8.99957 2.22968C11.8596 2.22968 14.1896 4.55968 14.1896 7.41968C14.1796 10.2297 11.9796 12.5097 9.18957 12.5997C9.17957 12.5997 9.16957 12.5997 9.15957 12.5997ZM8.99957 3.72968C6.96957 3.72968 5.30957 5.38968 5.30957 7.41968C5.30957 9.41968 6.86957 11.0297 8.85957 11.0997C8.91957 11.0897 9.04957 11.0897 9.17957 11.0997C11.1396 11.0097 12.6796 9.39968 12.6896 7.41968C12.6896 5.38968 11.0296 3.72968 8.99957 3.72968Z"
					fill={color}
				/>
				<path
					d="M16.5394 12.7297C16.5094 12.7297 16.4794 12.7297 16.4494 12.7197C16.0394 12.7597 15.6194 12.4697 15.5794 12.0597C15.5394 11.6497 15.7894 11.2797 16.1994 11.2297C16.3194 11.2197 16.4494 11.2197 16.5594 11.2197C18.0194 11.1397 19.1594 9.93968 19.1594 8.46968C19.1594 6.94968 17.9294 5.71968 16.4094 5.71968C15.9994 5.72968 15.6594 5.38968 15.6594 4.97968C15.6594 4.56968 15.9994 4.22968 16.4094 4.22968C18.7494 4.22968 20.6594 6.13968 20.6594 8.47968C20.6594 10.7797 18.8594 12.6397 16.5694 12.7297C16.5594 12.7297 16.5494 12.7297 16.5394 12.7297Z"
					fill={color}
				/>
				<path
					d="M9.16961 23.5297C7.20961 23.5297 5.23961 23.0297 3.74961 22.0297C2.35961 21.1097 1.59961 19.8497 1.59961 18.4797C1.59961 17.1097 2.35961 15.8397 3.74961 14.9097C6.74961 12.9197 11.6096 12.9197 14.5896 14.9097C15.9696 15.8297 16.7396 17.0897 16.7396 18.4597C16.7396 19.8297 15.9796 21.0997 14.5896 22.0297C13.0896 23.0297 11.1296 23.5297 9.16961 23.5297ZM4.57961 16.1697C3.61961 16.8097 3.09961 17.6297 3.09961 18.4897C3.09961 19.3397 3.62961 20.1597 4.57961 20.7897C7.06961 22.4597 11.2696 22.4597 13.7596 20.7897C14.7196 20.1497 15.2396 19.3297 15.2396 18.4697C15.2396 17.6197 14.7096 16.7997 13.7596 16.1697C11.2696 14.5097 7.06961 14.5097 4.57961 16.1697Z"
					fill={color}
				/>
				<path
					d="M18.3392 21.7297C17.9892 21.7297 17.6792 21.4897 17.6092 21.1297C17.5292 20.7197 17.7892 20.3297 18.1892 20.2397C18.8192 20.1097 19.3992 19.8597 19.8492 19.5097C20.4192 19.0797 20.7292 18.5397 20.7292 17.9697C20.7292 17.3997 20.4192 16.8597 19.8592 16.4397C19.4192 16.0997 18.8692 15.8597 18.2192 15.7097C17.8192 15.6197 17.5592 15.2197 17.6492 14.8097C17.7392 14.4097 18.1392 14.1497 18.5492 14.2397C19.4092 14.4297 20.1592 14.7697 20.7692 15.2397C21.6992 15.9397 22.2292 16.9297 22.2292 17.9697C22.2292 19.0097 21.6892 19.9997 20.7592 20.7097C20.1392 21.1897 19.3592 21.5397 18.4992 21.7097C18.4392 21.7297 18.3892 21.7297 18.3392 21.7297Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
