import React, { useCallback, useEffect, useState } from 'react';
import styles from './UploadImage.module.scss';
import ImageUploading from 'react-images-uploading';
import { IconPlusCircle } from '../../../components/Icons';
import RViewerJS from 'viewerjs-react';
import { useDeleteImageMutation, useUploadImageMutation } from '../../../store/newApi/images/images';
import { ImageFilePathValues } from './constants';
import { UploadedImageItem } from './components/UploadedImageItem/UploadedImageItem';

export type UploadedImage = {
	filename: string;
	url: string;
}

type UploadImageProps = {
	currentPictures: UploadedImage[],
	setDamageImages: (images: UploadedImage[]) => void,
	disable: boolean,
	filePath: ImageFilePathValues,
}

export const UploadImage = ({ currentPictures, setDamageImages, disable, filePath }: UploadImageProps) => {
	const [uploadImage, { data, isLoading, reset: resetUpload }] = useUploadImageMutation();
	const [deleteImage] = useDeleteImageMutation();

	const [images, setImages] = useState([]);

	useEffect(() => {
		if (data) {
			setDamageImages([...currentPictures, { filename: data.filename, url: data.url }]);
			resetUpload()
		}
	}, [data, currentPictures]);

	const onChange = async (imageList, addUpdateIndex) => {
		if (imageList.length > 0) {
			const formData = new FormData();
			// @ts-ignore
			formData.append('file_path', filePath);
			formData.append('file', imageList[0]?.file);
			uploadImage({ formData })
		}
	};

	const handleDelete = useCallback((filename) => {
		deleteImage({ filename }).then(() => {
			setDamageImages(currentPictures.filter(i => i.filename !== filename))
		});
	}, [deleteImage]);

    useEffect(() => {
		setImages(currentPictures)
    }, [currentPictures]);

	return (
		<div className={styles.mainCont}>
			{/* @ts-ignore */}
			<ImageUploading
				multiple
				onChange={onChange}
				maxNumber={10}
				dataURLKey="data_url"
				acceptType={['jpg', 'png', 'jpeg']}
			>
				{({ imageList, onImageUpload, isDragging, dragProps }) => (
					<>
						{/* @ts-ignore */}
						<RViewerJS className={styles.viewer}>
							{images?.map((image) => (
								<UploadedImageItem disabled={disable} filename={image.filename} url={image.url} handleDelete={handleDelete} />
							))}
						</RViewerJS>
						{!disable && <div
							className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''}`}
							{...dragProps}
						>
							<button className={styles.uploadButton} onClick={onImageUpload}>
								Загрузить фото
								{/* @ts-ignore */}
								<IconPlusCircle color={'primary'} size={'large'} />
							</button>
						</div>}
					</>
				)}
			</ImageUploading>
		</div>
	);
}
