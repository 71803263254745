import { Grid, Container, Box, useMediaQuery } from "@mui/material"
import { useLazyGetContainersListQuery } from "../../../../store/newApi/containers/containers";
import { ContainerCard } from "../ContainerCard/ContainerCard";
import { useInfiniteScrollFetching } from "../../../../shared/hooks/useInfiniteScrollFetching";
import { useSelector } from "react-redux";
import { Preloader } from "../../../../components";
import { memo } from "react";

type ContainerCardsListProps = {
    onItemClick: (id: number) => void;
}

export const ContainersCardsList = memo(({ onItemClick }: ContainerCardsListProps) => {
    // @ts-ignore
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    const filters = useSelector((state) => state['containersRtk']?.filter) ?? {};

    const { containerRef, renderedItems, isFetching } = useInfiniteScrollFetching({ useMutation: useLazyGetContainersListQuery, filters })

    return (
        <Container ref={containerRef} sx={{ padding: '8px', maxHeight: '100%', overflowY: 'auto', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {isFetching && (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {/* @ts-ignore */}
                    <Preloader />
                </Box>
            )}
            {(renderedItems || []).map(d => (
                <Grid key={d.id} sx={{ width: isDesktop ? '49%' : '100%' }} onClick={() => onItemClick(d.id)}>
                    <ContainerCard
                        id={d.id}
                        number={d.container_number}
                        status={d.status_display}
                        line={d.line_display}
                        size={d.size_display}
                        type={d.type_display}
                        client={d.client_display}
                        booking={d.booking}
                        isFilled={d.is_filled}
                        isDangerous={d.is_dangerous}
                        isDamaged={d.is_damaged}
                        terminal={d.terminal_display}
                        createdAt={d.created_at}
                    />
                </Grid>
            ))}
        </Container>
    )
})