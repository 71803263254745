export const IconLogout = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M15.2395 22.2685H15.1095C10.6695 22.2685 8.52953 20.5185 8.15953 16.5985C8.11953 16.1885 8.41953 15.8185 8.83953 15.7785C9.23953 15.7385 9.61953 16.0485 9.65953 16.4585C9.94953 19.5985 11.4295 20.7685 15.1195 20.7685H15.2495C19.3195 20.7685 20.7595 19.3285 20.7595 15.2585V8.73848C20.7595 4.66848 19.3195 3.22849 15.2495 3.22849H15.1195C11.4095 3.22849 9.92953 4.41848 9.65953 7.61848C9.60953 8.02848 9.25953 8.33849 8.83953 8.29849C8.41953 8.26849 8.11953 7.89848 8.14953 7.48848C8.48953 3.50848 10.6395 1.72849 15.1095 1.72849H15.2395C20.1495 1.72849 22.2495 3.82849 22.2495 8.73848V15.2585C22.2495 20.1685 20.1495 22.2685 15.2395 22.2685Z"
					fill={color}
				/>
				<path
					d="M15.0001 12.7485H3.62012C3.21012 12.7485 2.87012 12.4085 2.87012 11.9985C2.87012 11.5885 3.21012 11.2485 3.62012 11.2485H15.0001C15.4101 11.2485 15.7501 11.5885 15.7501 11.9985C15.7501 12.4085 15.4101 12.7485 15.0001 12.7485Z"
					fill={color}
				/>
				<path
					d="M5.85043 16.0986C5.66043 16.0986 5.47043 16.0286 5.32043 15.8786L1.97043 12.5286C1.68043 12.2386 1.68043 11.7586 1.97043 11.4686L5.32043 8.11859C5.61043 7.82859 6.09043 7.82859 6.38043 8.11859C6.67043 8.40859 6.67043 8.88859 6.38043 9.17859L3.56043 11.9986L6.38043 14.8186C6.67043 15.1086 6.67043 15.5886 6.38043 15.8786C6.24043 16.0286 6.04043 16.0986 5.85043 16.0986Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
