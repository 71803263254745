import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Typography, IconButton, Switch, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SwitchGroup = ({ name, title, elements, state, onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [switchState, setSwitchState] = useState(
		elements.reduce((acc, item) => {
			acc[item.value] = !!state[name[item.value]];
			return acc;
		}, {}),
	);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleChange = (e) => {
		const { id, checked } = e.target;
		setSwitchState((prevState) => ({
			...prevState,
			[id]: checked,
		}));
		onChange(name[id], checked);
	};

	useEffect(() => {
		setSwitchState(
			elements.reduce((acc, item) => {
				acc[item.value] = !!state[name[item.value]];
				return acc;
			}, {}),
		);
	}, [state, elements, name]);

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
				<Typography variant="alertTitle" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
			</Box>
			{isOpen && (
				<FormGroup sx={{ marginLeft: 1 }}>
					{elements.map((item) => (
						<FormControlLabel
							key={item.value}
							label={item.label}
							sx={{
								'.MuiTypography-root': {
									fontSize: '14px',
									lineHeight: '20px',
									letterSpacing: '0.035px',
								},
							}}
							control={<Switch id={item.value.toString()} checked={!!switchState[item.value]} onChange={handleChange} />}
						/>
					))}
				</FormGroup>
			)}
		</>
	);
};

export default SwitchGroup;
