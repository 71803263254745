export const IconCross = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
				<g id="X">
					<path
						id="Vector"
						d="M13.7812 4.21875L4.21875 13.7812"
						stroke={color}
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						id="Vector_2"
						d="M13.7812 13.7812L4.21875 4.21875"
						stroke={color}
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</svg>
		</div>
	);
};
