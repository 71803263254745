import { useEffect, useState, useCallback } from 'react';
import styles from './TabDocuments.module.css';
import { Button, Typography } from '@mui/material';
import {
	useCreateContractMutation,
	useLazyGetContractsListQuery, 
} from '../../../../../../../store/newApi/organizations/organizations';
import { DrawerModal } from '../../../../../../../newComponents/DrawerModal/DrawerModal';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { useDropzone } from 'react-dropzone';
import { DateInput } from '../../../../../../../newComponents/Inputs/DateInput/DateInput';
import { format } from 'date-fns';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';

export const TabDocuments = ({ data, onBack }) => {
	const [getContractsList, { data: dataContractsList }] = useLazyGetContractsListQuery(); ;
	const [createContract, { isSuccess, reset: resetCreate }] = useCreateContractMutation()

	const [isOpen, setOpen] = useState(false);
	const [documentName, setDocumentName] = useState('');
	const [documentDate, setDocumentDate] = useState(''); // Поле для хранения даты
	const [documentType, setDocumentType] = useState(data ? 2 : 1); // Поле для типа документа
	const [file, setFile] = useState(null);

	const fetchContracts = useCallback(() => {
		if (data) {
			getContractsList(data.id);
		}
	}, [data]);

	// Получение списка контрактов при загрузке данных
	useEffect(() => {
		fetchContracts()
	}, [fetchContracts]); 

	// Обработка загрузки файла через dropzone (только один файл)
	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			setFile(acceptedFiles[0]);
		}
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 });

	// Удаление загруженного файла
	const removeFile = () => {
		setFile(null);
	};

	// Очистка инпутов
	const clearInputs = () => {
		setDocumentName('');
		setDocumentDate('');
		setDocumentType('');
		setFile(null);
	};

	// Закрытие модального окна и очистка данных
	const handleClose = () => {
		clearInputs();
		setOpen(false);
	}; 

	// Обработка загрузки
	const handleUpload = async () => { 
		const formData = new FormData();
		formData.append('contract_number', documentName);
		formData.append('contract_file', file);
		formData.append('date_created', format(new Date(documentDate), 'yyyy-MM-dd'));
		formData.append('file_path', 0);
		formData.append('contract_type', 1);
		createContract({
			orgId: data.id,
			formData
		})
	};

	useEffect(() => {
		if (isSuccess) {
			resetCreate()
			handleClose()
			fetchContracts();
		}
	}, [isSuccess, fetchContracts]);

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
				<div className={styles.containerFormTemplate}>
					<Button variant="contained" color="primary" size="medium" onClick={() => setOpen(true)}>
						Добавить документ
					</Button>
					<div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
						{dataContractsList &&
							dataContractsList?.results?.map((el, ind) => (
								<div key={ind}>
									<a href={el.file_link} target='_blank' style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
										<FilePresentOutlinedIcon />
										<span>{el.contract_type} №{el.contract_number} от {el.date_created.split('-').reverse().join('.')}</span>
									</a>
								</div>
							))}
					</div>
				</div>
				<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
					<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
						К списку клиентов
					</Button>
				</div>
			</div>
			<DrawerModal
				isOpen={isOpen}
				handleClose={handleClose}
				actions={[
					<Button
						key="1"
						variant="contained"
						color="primary"
						disabled={!documentName || !documentDate || !file}
						onClick={handleUpload}
					>
						Загрузить
					</Button>,
					<Button key="2" variant="outlined" color="primary" onClick={handleClose}>
						Отменить
					</Button>,
				]}
			>
				<Typography variant="subtitle1" sx={{ marginBottom: '16px' }}>
					Добавить документ
				</Typography>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
					<InputText placeholder="Название документа" value={documentName} onChange={(e) => setDocumentName(e.target.value)} />
					<DateInput value={documentDate} onChange={setDocumentDate} placeholder="Дата создания" />

					{/* Дропзона */}
					<div {...getRootProps({ className: styles.dropzone })}>
						<input {...getInputProps()} />
						<p>Перетащите файл сюда или нажмите для выбора</p>
					</div>

					{/* Отображение загруженного файла */}
					{file && (
						<div className={styles.fileContainer}>
							<Typography variant="body1">Добавленный файл:</Typography>
							<p>{file.name}</p>
							<Button variant="outlined" color="primary" size="small" onClick={removeFile}>
								Удалить
							</Button>
						</div>
					)}
				</div>
			</DrawerModal>
		</>
	);
};
