import { TextField, InputAdornment, Typography } from '@mui/material';
import { inputText } from './InputText.styles.js';
import { useCallback, useRef } from 'react';

function InputText({ type = 'text', placeholder, value, onChange, icon, iconPosition = 'end', errorMessage, height = '40px', labelText, ...rest }) {
	const ref = useRef(null);

	const handleInputClick = useCallback(() => {
		if (ref.current)
			ref.current.focus();
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
			{labelText && <span>{labelText}</span>}
			<TextField
				inputRef={ref}
				type={type}
				variant="outlined"
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				fullWidth
				sx={{
					...inputText.textField,
					...(value && inputText.valueTextField),
					height
				}}
				InputProps={{
					startAdornment:
						iconPosition === 'start' ? (
							<InputAdornment position="start">
								<div style={inputText.icon}>{icon}</div>
							</InputAdornment>
						) : null,
					endAdornment:
						iconPosition === 'end' ? (
							<InputAdornment position="end">
								<div style={inputText.icon}>{icon}</div>
							</InputAdornment>
						) : null,
				}}
				onClick={(e) => {
					rest.onClick && rest.onClick();
					handleInputClick();
				}}
				{...rest}
			/>
			{errorMessage && (
				<Typography color="error" variant="caption">{errorMessage}</Typography>
			)}
		</div>
	);
}

export default InputText;
