import { Typography, Box, Grid } from '@mui/material';
import { IconUser } from '../../../../components/Icons';
import { Card } from '../../../../shared/ui/Card/Card';

type JobCardProps = {
	name: string;
};

export const JobCard = ({ name }: JobCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Grid container sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center', flexWrap: 'nowrap' }}>
						<Grid item>
							<Box
								sx={{
									backgroundColor: '#F5F7FA',
									borderRadius: '8px',
									minWidth: '48px',
									height: '48px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<IconUser size={'largeS'} color={'core-dark-60'} />
							</Box>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{name}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
