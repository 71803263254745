export const IconCube = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12 13.2395C11.56 13.2395 11.12 13.1295 10.72 12.9195L4.21 9.40948C3.62 9.08948 3.25 8.45948 3.25 7.75948C3.25 7.05948 3.62 6.42948 4.21 6.10948L10.72 2.59948C11.52 2.16948 12.48 2.16948 13.27 2.59948L19.78 6.10948C20.37 6.42948 20.74 7.05948 20.74 7.75948C20.74 8.45948 20.37 9.08948 19.78 9.40948L13.27 12.9195C12.88 13.1395 12.44 13.2395 12 13.2395ZM12 3.76948C11.81 3.76948 11.61 3.81948 11.43 3.91948L4.93 7.41948C4.76 7.50948 4.75 7.69948 4.75 7.75948C4.75 7.81948 4.76 8.00948 4.93 8.08948L11.44 11.5995C11.8 11.7895 12.21 11.7895 12.57 11.5995L19.08 8.08948C19.24 7.99948 19.26 7.80948 19.26 7.75948C19.26 7.69948 19.25 7.50948 19.08 7.42948L12.57 3.91948C12.39 3.81948 12.19 3.76948 12 3.76948Z"
					fill={color}
				/>
				<path
					d="M9.78 23.7095C9.5 23.7095 9.21 23.6395 8.95 23.5095L2.89 20.4895C1.88 19.9795 1.25 18.9595 1.25 17.8295V12.1095C1.25 11.4595 1.58 10.8695 2.13 10.5295C2.69 10.1895 3.36 10.1595 3.94 10.4495L10 13.4695C11.01 13.9795 11.64 14.9895 11.64 16.1295V21.8495C11.64 22.4995 11.31 23.0895 10.76 23.4295C10.46 23.6195 10.12 23.7095 9.78 23.7095ZM3.11 11.7495C3.03 11.7495 2.96 11.7795 2.92 11.7995C2.86 11.8395 2.75 11.9295 2.75 12.1095V17.8295C2.75 18.3895 3.06 18.8995 3.56 19.1495L9.61 22.1795C9.77 22.2595 9.9 22.1995 9.96 22.1695C10.02 22.1295 10.13 22.0395 10.13 21.8595V16.1395C10.13 15.5795 9.82 15.0695 9.32 14.8195L3.27 11.7895C3.21 11.7595 3.16 11.7495 3.11 11.7495Z"
					fill={color}
				/>
				<path
					d="M14.2204 23.7095C13.8804 23.7095 13.5404 23.6195 13.2404 23.4295C12.6904 23.0895 12.3604 22.4995 12.3604 21.8495V16.1295C12.3604 14.9995 12.9904 13.9795 14.0004 13.4695L20.0504 10.4395C20.6304 10.1495 21.3104 10.1795 21.8604 10.5195C22.4104 10.8595 22.7404 11.4495 22.7404 12.0995V17.8195C22.7404 18.9495 22.1104 19.9695 21.1004 20.4795L15.0504 23.5095C14.7904 23.6495 14.5004 23.7095 14.2204 23.7095ZM20.8904 11.7495C20.8404 11.7495 20.7904 11.7595 20.7304 11.7895L14.6804 14.8195C14.1804 15.0695 13.8704 15.5695 13.8704 16.1395V21.8595C13.8704 22.0395 13.9804 22.1295 14.0404 22.1695C14.1004 22.2095 14.2304 22.2595 14.3904 22.1795L20.4404 19.1495C20.9404 18.8995 21.2504 18.3895 21.2504 17.8295V12.1095C21.2504 11.9295 21.1404 11.8395 21.0804 11.7995C21.0404 11.7795 20.9704 11.7495 20.8904 11.7495Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
