import { Controller, useFormContext } from "react-hook-form"
import { CollapseBlock } from "../../CollapseBlock/CollapseBlock"
import { Typography } from "@mui/material"
import { TerminalInputAutocomplete } from "./TerminalInputAutocomplete"

export const TerminalInputAutocompleteController = () => {
    const { control, formState: { errors } } = useFormContext();

    return (
        <CollapseBlock defaultOpen={!!errors?.terminal} title={<span>Терминал</span>}>
            <Controller
                name="terminal"
                control={control}
                render={({ field }) => (
                    <>
                        <TerminalInputAutocomplete
                            { ...field }
                            placeholder="Выберите или введите вручную"
                        />
                        {errors?.terminal && (
                            <Typography color="error" variant="caption">{errors?.terminal?.message}</Typography>
                        )}
                    </>
                )}
            />
        </CollapseBlock>
    )
}