type SelectedPermissionType = Record<string, {
    add: boolean;
    change: boolean;
    delete: boolean;
    view: boolean;
}>

type PermissionType = {
    name: string;
    permissions: { id: number, name: string; type: string }[];
}

export const getSelectedPermissionIds = (selectedPermissions: SelectedPermissionType, permissionsList: PermissionType[]) => {
    const matchingIds = [];

    // Проходим по первому массиву
    Object.entries(selectedPermissions).forEach(([name, permissions]) => {
        // Ищем соответствие во втором массиве по 'section' и 'name'
        const matchingItem2 = permissionsList.find(item2 => item2.name === name);
        
        if (matchingItem2 && matchingItem2.permissions) {
            // Проверяем права (permissions) на совпадение по типу 'add'
            matchingItem2.permissions.forEach(permission => {
                if (permissions[permission.type]) {
                    // Если тип 'add' и оба true, добавляем id в список
                    matchingIds.push(permission.id);
                }
            });
        }
    });

    // Выводим найденные id
    return matchingIds;
}