import React, { createContext, useContext, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const ModalContext = createContext({
    openModal: (content, confirmationCallback = () => { }) => { },
    closeModal: () => { },
});

export const ConfirmationModalProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [onConfirm, setOnConfirm] = useState(null);

    const openModal = (content, confirmCallback) => {
        setModalContent(content);
        setOnConfirm(() => confirmCallback);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setModalContent(null);
        setOnConfirm(null);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        closeModal();
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <Dialog
                open={open}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {modalContent}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={closeModal}>Отмена</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </ModalContext.Provider>
    );
};

export const useConfirmationModal = () => {
    return useContext(ModalContext);
};