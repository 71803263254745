import { Typography, Box, Grid, Divider, Chip } from '@mui/material';
import { Card } from '../../../../shared/ui/Card/Card';

type RepackActCardProps = {
	id: number;
	status: string;
	colorStatus: any;
	client: string; 
	terminal: string; 
	totalPrice: number;
	dateComplited: string;
	dateCreated: string;
	cargosCount: number;
	operationsCount: number;
};

export const RepackActCard = ({
	id,
	status,
	colorStatus,
	client,
	terminal,
	totalPrice,
	dateComplited,
	dateCreated,
	cargosCount,
	operationsCount,
}: RepackActCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px 24px 12px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item>
							<Typography variant="subtitle1">Заявка №{id}</Typography>
						</Grid>

						<Grid item>
							<Chip label={status} color={colorStatus} />
						</Grid>
					</Grid>

					<Grid container sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
						<Grid item>
							<Typography variant="body2">{operationsCount} операций</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">{cargosCount} грузов</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Цена:</Typography>
							<Typography variant="body2">{totalPrice}</Typography>
						</Grid>
					</Grid>
					<Grid container sx={{ gap: '12px' }}>
						<Grid item>
							<Chip label={`Терминал: ${terminal}`} />
						</Grid>
						<Grid item>
							<Chip label={`Клиент: ${client}`} />
						</Grid>
					</Grid>
				</Box>
				<Divider />
				<Box
					sx={{
						padding: '16px 24px',
					}}
				>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Создана</Typography>
							<Typography variant="overline">{dateCreated}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Завершена</Typography>
							<Typography variant="overline">{dateComplited}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
