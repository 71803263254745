import { useEffect } from 'react';
import { useGetEntryQuery } from '../../../../store/newApi/entries/entries';
import { useParams } from 'react-router-dom';

export const useInitFormState = ({ isCreate, ...methods }) => {
	const { setValue } = methods;
	let { terminalId, orderId, entryId } = useParams();
	const { data } = useGetEntryQuery(
		{ terminalId, orderId, entryId },
		{
			skip: isCreate,
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data) {
			setValue('container_number', data?.container?.container_number);
			setValue('vehicle_driver', data?.vehicle_driver);
			setValue('vehicle', data?.vehicle);
			setValue('vehicle_trailer_number', data?.vehicle_trailer_number);
			setValue('seal_number', data?.seal_number);
		}
	}, [data, setValue]);
};
