import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Grid, IconButton, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateInput } from './DateInput.styles.js';
import ru from 'date-fns/locale/ru';
import IconCalendar from '../../../components/Icons/IconCalendar.jsx';
import { format, parse, parseISO } from 'date-fns';

const formatDateToString = (date) => {
	return date ? format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssXXX") : '';
};

const parseStringToDate = (str) => {
	const parsedDate = parse(str, 'dd.MM.yyyy', new Date());
	return isNaN(parsedDate) ? null : parsedDate;
};

export const DateInput = ({ value, onChange, placeholder = 'Выберите дату' }) => {
	const [isCalendarOpen, setIsCalendarOpen] = useState(false);
	const [currentValue, setCurrentValue] = useState('');

	useEffect(() => {
		if (value === '') {
			setCurrentValue('');
		}
	}, [value]);

	const displayedValue = useMemo(() => {
		return value ? format(parseISO(value), "yyyy-MM-dd") : ''
	}, [value]);

	const handleDateChange = (date) => {
        onChange(formatDateToString(date))
		setIsCalendarOpen(false)
	};

	const handleInputChange = (e) => {
		const inputValue = e.target.value;
		setCurrentValue(inputValue);

		const parsedDate = parseStringToDate(inputValue);
		if (parsedDate) {
			onChange(parsedDate);
		}
	};

	return (
		<Box sx={{ display: 'flex', position: 'relative', gap: '8px' }}>
			<Grid container sx={{ width: '100%', margin: 0, display: 'flex', gap: '8px' }}>
                <TextField
                    variant="outlined"
                    placeholder={placeholder}
                    value={displayedValue}
                    fullWidth
                    onChange={handleInputChange}
                    sx={{
                        ...dateInput.textField,
                        ...(value && dateInput.valueTextField),
                    }}
                />
			</Grid>
			<IconButton sx={dateInput.btnCalendar} onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
				<IconCalendar color={'white'} />
			</IconButton>

			{isCalendarOpen && (
				<Box sx={dateInput.calendar}>
					<DatePicker
						onClickOutside={() => setIsCalendarOpen(false)}
						onCalendarClose={() => setIsCalendarOpen(false)}
						selected={currentValue}
						onChange={handleDateChange}
						value={value}
						inline
						dateFormat="dd.MM.yyyy"
						locale={ru}
						className="custom-datepicker"
					/>
				</Box>
			)}
		</Box>
	);
};
