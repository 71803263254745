import { createContext, PropsWithChildren, useCallback, useState } from "react";

export const SideMenuContext = createContext({
    handleClose: () => {},
    handleOpen: () => {},
    handleToggleMenu: () => {},
    opened: false,
});

type SideMenuProviderProps = PropsWithChildren<{}>;

export const SideMenuProvider = ({ children }: SideMenuProviderProps) => {
    const [opened, setOpened] = useState(false);

    const handleToggleMenu = useCallback(() => {
        setOpened(!opened);
    }, [opened])

    const handleClose = useCallback(() => {
        setOpened(false);
    }, []);

    const handleOpen = useCallback(() => {
        setOpened(true);
    }, []);

    return <SideMenuContext.Provider value={{ handleClose, handleOpen, handleToggleMenu, opened  }}>
        {children}
    </SideMenuContext.Provider>
}