export const CONTAINER_SIZE = {
    TWENTY: { value: 20, id: 1 },
    FORTY: { value: 40, id: 2 },
    FORTYFIVE: { value: 45, id: 3 },
}

export const CONTAINER_SIZES_BY_VALUE = {
    20: 1,
    40: 2,
    45: 3,
};

export const CONTAINER_TYPE = {
    DC: { value: 'DC', id: 1 },
    DV: { value: 'DV', id: 2 },
    HC: { value: 'HC', id: 3 },
    PW: { value: 'PW', id: 4 },
    OT: { value: 'OT', id: 5 },
    OTHC: { value: 'OTHC', id: 6 },
    RF: { value: 'RF', id: 7 },
    RFHC: { value: 'RFHC', id: 8 },
    FR: { value: 'FR', id: 9 },
    FRHC: { value: 'FRHC', id: 10 },
    TC: { value: 'TC', id: 11 },
    HR: { value: 'HR', id: 12 },
}