import { InputAutocomplete } from '../../Inputs/InputAutocomplete/InputAutocomplete';
import { useGetGroupsListQuery } from '../../../store/newApi/groups/groups';
import { useCallback, useMemo } from 'react';

export const JobInputAutocomplete = ({ value, onChange, ...rest }) => {
	const { jobOptions = [], data } = useGetGroupsListQuery(
		{},
		{
			selectFromResult: ({ data }) => {
				return {
					jobOptions: data?.map((t) => ({ label: t.name, id: t.id })),
					data
				}
			},
		},
	);

	const valueId = useMemo(() => {
		const foundOption = jobOptions.find((option) => option.id === value);
		return foundOption ? foundOption.id : null;
	}, [value, jobOptions]);

	const handleChange = useCallback((id) => {
		const entity = data?.find(j => j.id === id);
		onChange(id, entity);
	}, [onChange, data]);

	return <InputAutocomplete {...rest} value={valueId} placeholder="Выберите должность" options={jobOptions} onChange={handleChange}/>;
};
