export const IconSearch = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					fill={color}
					d="M20.4697 21.5304C20.7626 21.8232 21.2375 21.8232 21.5304 21.5304C21.8232 21.2375 21.8232 20.7626 21.5304 20.4697L20.4697 21.5304ZM17.1804 16.1197C16.8875 15.8268 16.4126 15.8268 16.1197 16.1197C15.8268 16.4126 15.8268 16.8875 16.1197 17.1804L17.1804 16.1197ZM18.25 11C18.25 15.0041 15.0041 18.25 11 18.25V19.75C15.8325 19.75 19.75 15.8325 19.75 11H18.25ZM11 18.25C6.99594 18.25 3.75 15.0041 3.75 11H2.25C2.25 15.8325 6.16751 19.75 11 19.75V18.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75V2.25C6.16751 2.25 2.25 6.16751 2.25 11H3.75ZM11 3.75C15.0041 3.75 18.25 6.99594 18.25 11H19.75C19.75 6.16751 15.8325 2.25 11 2.25V3.75ZM21.5304 20.4697L17.1804 16.1197L16.1197 17.1804L20.4697 21.5304L21.5304 20.4697Z"
				/>
			</svg>
		</div>
	);
};
