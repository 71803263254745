import { Button } from '@mui/material';
import styles from './ChooseRepairStep.module.css';
import { useCallback, useEffect, useMemo } from 'react';
import { useUpdateRepairGroupMutation } from '../../../../../../../store/newApi/service/service';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { isClient } from '../../../../../../../shared/auth/isClient';
import { REPAIR_STATUSES } from '../../../../../../../shared/repairs/constants';
import { RepairItem } from '../../../../../../../shared/ui/RepairItem/RepairItem';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';

export const ChooseRepairStep = ({ refetchRepairOrder, onChooseRepair, repairOrderData, onNext, onBack, onCancel }) => {
    const [updateRepairGroup, { data: updatedRepairGroup, isSuccess, reset: resetUpdate }] = useUpdateRepairGroupMutation();

    const repairGroups = useMemo(() => repairOrderData?.repair_group || [], [repairOrderData]);

    const showAcceptButtons = useCallback((status) => {
        return isClient() && (status === REPAIR_STATUSES.ASSESSED.id || status === REPAIR_STATUSES.REPAIRED.id);
    }, []);

    const handleAccept = useCallback((id, currentStatus) => {
        updateRepairGroup({id, status: currentStatus === REPAIR_STATUSES.REPAIRED.id ? 6 : 2 })
    }, [updateRepairGroup]);

    const handleDecline = useCallback((id, currentStatus) => {
        updateRepairGroup({id, status: currentStatus === REPAIR_STATUSES.REPAIRED.id ? 7 : 3 })
    }, [updateRepairGroup]);

    useEffect(() => {
        if (isSuccess) {
            refetchRepairOrder();
            resetUpdate();
        }
    }, [isSuccess, refetchRepairOrder, resetUpdate]);

    return (
        <div className={styles.container} style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'space-between', flexGrow: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '10px' }}>
                {repairGroups.map(rg => {
                    return (
                        <div style={{ display: 'flex', flexGrow: 1, gap: '8px' }}>
                            <RepairItem
                                component={rg?.damage?.component[0]}
                                side={rg.damage?.side}
                                damageName={rg?.damage?.damage[0]?.name}
                                displayStatus={rg.status_display}
                                stepCount={rg.step_count}
                                totalPrice={rg.total_price}
                                status={rg.status}
                                onClick={() => onChooseRepair(rg?.id)}
                            />
                            {showAcceptButtons(rg.status) && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Button variant="contained" color='primary' size="medium" onClick={() => handleAccept(rg.id, rg.status)}>
                                    <CheckIcon />
                                </Button>
                                <Button variant="contained" color='primary' size="medium" onClick={() => handleDecline(rg.id, rg.status)}>
                                    <CloseIcon />
                                </Button>
                            </div>}
                        </div>
                    )
                })}
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Отмена',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onCancel
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Назад',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    },
                    {
                        text: 'Продолжить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onNext
                    }
                ]}
            />
        </div>
    )
}