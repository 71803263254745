export const entriesForm = (isDesktop) => {
	return [
		{
			width: isDesktop ? 'calc(100% - 32px)' : 'calc(100% - 16px)',
			position: 'absolute',
			bottom: '16px',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	];
};
