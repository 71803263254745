import { useEffect } from 'react';

export const useInitFormState = ({ data, ...methods }) => {
	const { setValue } = methods;

	useEffect(() => {
		if (data) {
			if (data?.full_name) setValue('full_name', data.full_name);
			if (data?.password) setValue('password', data.password);
			if (data?.username) setValue('username', data.username); 
		}
	}, [data, setValue]);
};
