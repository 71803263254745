import axios from 'axios';
import Cookies from 'js-cookie';

const handleLogOut = async () => {
	const userType = Cookies.get('userType');

	try {
		await axios.post('/api/accounts/logout/', null, {
			withCredentials: true,
			xsrfHeaderName: 'X-CSRFToken',
			xsrfCookieName: 'csrftoken',
		});

		if (userType == 1) {
			window.location.href = '/authEmpl';
		} else if (userType == 2) {
			window.location.href = '/authClient';
		}
	} catch (error) {}
};

export { handleLogOut };
