import { Controller, useForm } from 'react-hook-form';
import styles from '../RegistrationClient.module.css';
import { FormWrapper } from '../../../../../newComponents/Form/FormWrapper';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import InputText from '../../../../../newComponents/Inputs/InputText/InputText';
import { Button, Typography } from '@mui/material';
import { usePostCheckCodeMutation, usePostCodeMutation, useRegClientMutation } from '../../../../../store/newApi/registration/registration';
import { useEffect } from 'react';

const createSchema = z.object({
	full_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	password: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	email: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	organization_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	organization_address: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	inn: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

export const RegistrationClientForm = ({ setPage }) => {
	const [postCode, { isSuccess: isSuccessPostCode, isError: isErrorPostCode, error: postCodeError }] = usePostCodeMutation();
	const [postCheckCode, { isSuccess: isSuccessCheckCode, isError: isErrorCheckCode }] = usePostCheckCodeMutation();
	const [regClient, { isSuccess: isSuccessRegClient }] = useRegClientMutation();

	const resolver = zodResolver(createSchema);
	const methods = useForm({
		defaultValues: {
			full_name: '',
			password: '',
			email: '',
			organization_name: '',
			organization_address: '',
			inn: '', 
			code: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	let isEmail = watch('email');

	const onSubmit = (value) => {
		const requestData = {
			full_name: value.full_name,
			password: value.password,
			user_type: 2,
			email: value.email,
			organization: {
				organization_name: value.organization_name,
				organization_address: value.organization_address,
				inn: value.inn,
				terminals: [],
			},
		};
		regClient(requestData);
	};

	useEffect(() => {
		if (isSuccessRegClient) {
			setPage(3);
		}
	}, [isSuccessRegClient, setPage]);

	const handleSendCode = () => {
		postCode({ email: isEmail });
	};

	const handleCheckCode = (e) => { 
		if (e.target.value.length === 4) {
			postCheckCode({ code: watch('code') });
		}
	};

	return (
		<div className={styles.modal}>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<div className={styles.modalContainer}>
					<p className={styles.modalTitle}>Регистрация</p>
					<div className={styles.modalBlock}>
						<p>Данные администратора аккаунта</p>
						<Controller
							name="full_name"
							control={control}
							render={({ field }) => ( 
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="ФИО" placeholder="ФИО" />
									{errors?.full_name && (
										<Typography color="error" variant="caption">
											{errors?.full_name?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="email"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Email" placeholder="Введите email" />
									{errors?.email && (
										<Typography color="error" variant="caption">
											{errors?.email?.message}
										</Typography>
									)}
									{isErrorPostCode && (
										<Typography color="error" variant="caption">
											{/* @ts-ignore */}
											{postCodeError?.data?.email[0]}
										</Typography>
									)}
									{isEmail && (
										<div className={styles.textCode} onClick={handleSendCode}>
											Получить код
										</div>
									)}
								</div>
							)}
						/>
						{isSuccessPostCode && (
							<Controller
								name="code"
								control={control}
								render={({ field }) => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{/* @ts-ignore */}
										<InputText
											{...field}
											labelText="Введите код"
											type="number"
											placeholder="1111"
											onChange={(e) => {
												const value = e.target.value.slice(0, 4);
												field.onChange(value);
												handleCheckCode({ target: { value } });
											}}
											disabled={isSuccessCheckCode}
										/>
										{isSuccessCheckCode && <Typography variant="caption">Почта подтверждена</Typography>}
										{isErrorCheckCode && <Typography variant="caption">Неверный код</Typography>}
									</div>
								)}
							/>
						)}
						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Пароль" placeholder="Пароль" />
									{errors?.password && (
										<Typography color="error" variant="caption">
											{errors?.password?.message}
										</Typography>
									)}
								</div>
							)}
						/>
					</div>
					<div className={styles.modalBlock}>
						<p>Данные организации</p>
						<Controller
							name="organization_name"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Название организации" placeholder="Название" />
									{errors?.organization_name && (
										<Typography color="error" variant="caption">
											{errors?.organization_name?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="organization_address"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="Адрес организации" placeholder="Адрес" />
									{errors?.organization_address && (
										<Typography color="error" variant="caption">
											{errors?.organization_address?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="inn"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{/* @ts-ignore */}
									<InputText {...field} labelText="ИНН" placeholder="ИНН" />
									{errors?.inn && (
										<Typography color="error" variant="caption">
											{errors?.inn?.message}
										</Typography>
									)}
								</div>
							)}
						/>
					</div>
				</div>
			</FormWrapper>
			<div className={styles.modalFooter}>
				<Button disabled={!isSuccessCheckCode} variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
					Создать аккаунт
				</Button>
			</div>
		</div>
	);
};
