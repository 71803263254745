export const IconMessageText = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M16 22.7455H3C2.04 22.7455 1.25 21.9555 1.25 20.9955V7.99548C1.25 3.57548 3.58 1.24548 8 1.24548H16C20.42 1.24548 22.75 3.57548 22.75 7.99548V15.9955C22.75 20.4155 20.42 22.7455 16 22.7455ZM8 2.74548C4.42 2.74548 2.75 4.41548 2.75 7.99548V20.9955C2.75 21.1355 2.86 21.2455 3 21.2455H16C19.58 21.2455 21.25 19.5755 21.25 15.9955V7.99548C21.25 4.41548 19.58 2.74548 16 2.74548H8Z"
					fill={color}
				/>
				<path
					d="M17 10.2455H7C6.59 10.2455 6.25 9.90548 6.25 9.49548C6.25 9.08548 6.59 8.74548 7 8.74548H17C17.41 8.74548 17.75 9.08548 17.75 9.49548C17.75 9.90548 17.41 10.2455 17 10.2455Z"
					fill={color}
				/>
				<path
					d="M14 15.2455H7C6.59 15.2455 6.25 14.9055 6.25 14.4955C6.25 14.0855 6.59 13.7455 7 13.7455H14C14.41 13.7455 14.75 14.0855 14.75 14.4955C14.75 14.9055 14.41 15.2455 14 15.2455Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
