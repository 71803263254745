import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

export const useGetClientUserData = () => {
    // @ts-ignore
    const userData = useSelector((state) => state.me.userData);

    const orgs = useMemo(() => userData?.organizations || [], [userData])

    const getOrgById = useCallback((orgId: string) => {
        return orgId ? orgs.find(o => o.id === +orgId) || null : null;
    }, [userData]);

    return {
        orgs,
        getOrgById
    };
}