import { modelPathMatch } from './modelPathMatch';

export function handleSearchTypePermission(terminals, path, type) {
	if (path == 'main') {
		return true;
	} else {
		// Сопоставить модель и путь
		const model = modelPathMatch.find((item) => item.path === path)?.model;

		if (!model) return false;

		// Поиск типа в правах
		return terminals?.some(
			(terminal) =>
				terminal.permissions &&
				terminal.permissions.some(
					(permission) => permission.model.toLowerCase() === model.toLowerCase() && permission.permission_type.includes(type),
				),
		);
	}
}
