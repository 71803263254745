import styles from './Tabs.module.scss';
import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import InputText from '../../../../../../newComponents/Inputs/InputText/InputText';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormWrapper } from '../../../../../../newComponents/Form/FormWrapper';
import { useUpdateTerminalMutation } from '../../../../../../store/newApi/terminals/terminals';
import RequireCheckRights from '../../../../../../hoc/RequireCheckRights';

const createSchema = z.object({
	name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	address: z.string().optional(),
	max_teu: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	contact_number: z.string().optional(),
	additionalProp1: z.string().optional(),
	additionalProp2: z.string().optional(),
	additionalProp3: z.string().optional(),
});

const updateSchema = z.object({
	name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	address: z.string().optional(),
	max_teu: z.any().optional(),
	contact_number: z.string().optional(),
	additionalProp1: z.string().optional(),
	additionalProp2: z.string().optional(),
	additionalProp3: z.string().optional(),
});

export const TabTerminalInfo = ({ data, onBack, setCurrentStep, values, setValues }) => {
	const [updateTerminal] = useUpdateTerminalMutation();
	const resolver = useMemo(() => (data ? zodResolver(updateSchema) : zodResolver(createSchema)), [data]);
	const methods = useForm({
		defaultValues: {
			name: '',
			address: '',
			max_teu: '',
			contact_number: '',
			additionalProp1: '',
			additionalProp2: '',
			additionalProp3: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (data) {
				const payload = {
					name: value.name,
					address: value.address,
					max_teu: value.max_teu,
					contact_number: value.contact_number,
					schedule: {
						additionalProp1: value.additionalProp1,
						additionalProp2: value.additionalProp2,
						additionalProp3: value.additionalProp3,
					},
				};
				updateTerminal({ terminalId: data?.id, ...payload });
				onBack();
			} else {
				setValues(value);
				setCurrentStep(1);
			}
		},
		[data],
	);

	useEffect(() => {
		if (data) {
			if (data?.name) setValue('name', data.name);
			if (data?.address) setValue('address', data.address);
			if (data?.max_teu) setValue('max_teu', data.max_teu);
			if (data?.contact_number) setValue('contact_number', data.contact_number);
			if (data?.schedule?.additionalProp1) setValue('additionalProp1', data.schedule.additionalProp1);
			if (data?.schedule?.additionalProp2) setValue('additionalProp2', data.schedule.additionalProp2);
			if (data?.schedule?.additionalProp3) setValue('additionalProp3', data.schedule.additionalProp3);
		}
	}, [data, setValue]);

	useEffect(() => {
		if (values) {
			if (values?.name) setValue('name', values.name);
			if (values?.address) setValue('address', values.address);
			if (values?.max_teu) setValue('max_teu', values.max_teu);
			if (values?.contact_number) setValue('contact_number', values.contact_number);
			if (values?.schedule?.additionalProp1) setValue('additionalProp1', values.schedule.additionalProp1);
			if (values?.schedule?.additionalProp2) setValue('additionalProp2', values.schedule.additionalProp2);
			if (values?.schedule?.additionalProp3) setValue('additionalProp3', values.schedule.additionalProp3);
		}
	}, [values, setValue]);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<div className={styles.containerFormTemplate}>
					<Controller
						name="name"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Название терминала" placeholder="Введите название" />
								{errors?.name && (
									<Typography color="error" variant="caption">
										{errors?.name?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="address"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Адрес терминала" placeholder="Введите адрес" />
								{errors?.address && (
									<Typography color="error" variant="caption">
										{errors?.address?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="max_teu"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Вместимость терминала (TEU)" placeholder="Введите вместимость" />
								{errors?.max_teu && (
									<Typography color="error" variant="caption">
										{errors?.max_teu?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="contact_number"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<InputText {...field} labelText="Номер телефона" placeholder="Введите номер" />
								{errors?.contact_number && (
									<Typography color="error" variant="caption">
										{errors?.contact_number?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<div className={styles.scheduleContainer}>
						<Typography variant="btnMedium">График работы терминала</Typography>
						<InputText value={'Круглосуточно'} disabled labelText="Понедельник - воскресенье:" placeholder="08:00 - 20:00" />
						{/* <Controller
							name="additionalProp1"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Понедельник - пятница:" placeholder="08:00 - 20:00" />
								</div>
							)}
						/>
						<Controller
							name="additionalProp2"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Суббота:" placeholder="08:00 - 20:00" />
									{errors?.contact_number && (
										<Typography color="error" variant="caption">
											{errors?.contact_number?.message}
										</Typography>
									)}
								</div>
							)}
						/>
						<Controller
							name="additionalProp3"
							control={control}
							render={({ field }) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<InputText {...field} labelText="Воскресенье:" placeholder="08:00 - 20:00" />
								</div>
							)}
						/> */}
					</div>
				</div>
			</FormWrapper>
			<div style={{ justifyContent: 'flex-end', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>

				{data ? (
					<RequireCheckRights path="terminals" type="change">
						<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
							Сохранить
						</Button>
					</RequireCheckRights>
				) : (
					<Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>
						Далее
					</Button>
				)}
			</div>
		</div>
	);
};
