export const importContainerFields = [
	{
		label: 'Номер контейнера',
		key: 'container_number',
		alternateMatches: ['Номер контейнера', 'номер'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		example: 'FHD 4749164 6',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [
			{
				// Can be "required" / "unique" / "regex"
				rule: 'required',
				errorMessage: 'Name is required',
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: 'error',
			},
		],
	},
	{
		label: 'Размер контейнера',
		key: 'size',
		alternateMatches: ['Размер контейнера', 'размер'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		example: '20',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [
			{
				// Can be "required" / "unique" / "regex"
				rule: 'required',
				errorMessage: 'Name is required',
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: 'error',
			},
		],
	},
	{
		label: 'Тип',
		key: 'type',
		alternateMatches: ['тип'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		example: 'RF',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [
			{
				// Can be "required" / "unique" / "regex"
				rule: 'required',
				errorMessage: 'Name is required',
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: 'error',
			},
		],
	},
	{
		label: 'Линия',
		key: 'line',
		alternateMatches: ['линия'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		example: 'Fesco',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [
			{
				// Can be "required" / "unique" / "regex"
				rule: 'required',
				errorMessage: 'Name is required',
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: 'error',
			},
		],
	},
	{
		label: 'Букинг',
		key: 'booking',
		alternateMatches: ['букинг'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'input',
		},
		example: 'F83PS6069L4',
		// Can have multiple validations that are visible in Validation Step table.
		// validations: [
		//  {
		//    // Can be "required" / "unique" / "regex"
		//    rule: 'required',
		//    errorMessage: 'Name is required',
		//    // There can be "info" / "warning" / "error" levels. Optional. Default "error".
		//    level: 'error',
		//  },
		// ],
	},
	{
		label: 'Заполнен',
		key: 'is_filled',
		alternateMatches: ['заполнен'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'checkbox',
			// options: [
			//  { label: 'Да', value: 'true' },
			//  { label: 'Нет', value: 'false' },
			// ],
			booleanMatches: {
				Да: true,
				Нет: false,
			},
		},
		example: 'Да',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [],
	},
	{
		label: 'Опасный',
		key: 'is_dangerous',
		alternateMatches: ['опасный'],
		fieldType: {
			// There are 3 types - "input" / "checkbox" / "select".
			type: 'checkbox',
			booleanMatches: {
				Да: true,
				Нет: false,
			},
		},
		example: 'Нет',
		// Can have multiple validations that are visible in Validation Step table.
		validations: [],
	},
];
