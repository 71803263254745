export const IconNote2 = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M15.8101 20.1715C15.5501 20.1715 15.2801 20.1615 14.9901 20.1315C14.4701 20.0915 13.8801 19.9915 13.2701 19.8415L11.5901 19.4415C6.98007 18.3515 5.47007 15.9115 6.55007 11.3115L7.53007 7.12147C7.75007 6.17147 8.01007 5.40147 8.33007 4.76147C10.0501 1.21147 13.3401 1.53147 15.6801 2.08147L17.3501 2.47147C19.6901 3.02147 21.1701 3.89147 22.0001 5.22147C22.8201 6.55147 22.9501 8.26147 22.4001 10.6015L21.4201 14.7815C20.5601 18.4415 18.7701 20.1715 15.8101 20.1715ZM13.1201 3.24147C11.4501 3.24147 10.3901 3.93147 9.68007 5.41147C9.42007 5.95147 9.19007 6.62147 8.99007 7.46147L8.01007 11.6515C7.12007 15.4315 8.15007 17.0815 11.9301 17.9815L13.6101 18.3815C14.1501 18.5115 14.6601 18.5915 15.1201 18.6315C17.8401 18.9015 19.1901 17.7115 19.9501 14.4415L20.9301 10.2615C21.3801 8.33147 21.3201 6.98147 20.7201 6.01147C20.1201 5.04147 18.9401 4.38147 17.0001 3.93147L15.3301 3.54147C14.5001 3.34147 13.7601 3.24147 13.1201 3.24147Z"
					fill={color}
				/>
				<path
					d="M8.33005 22.2415C5.76005 22.2415 4.12005 20.7015 3.07005 17.4515L1.79005 13.5015C0.370052 9.10151 1.64005 6.62151 6.02005 5.20151L7.60005 4.69151C8.12005 4.53151 8.51005 4.42151 8.86005 4.36151C9.15005 4.30151 9.43005 4.41151 9.60005 4.64151C9.77005 4.87151 9.80005 5.17151 9.68005 5.43151C9.42005 5.96151 9.19005 6.63151 9.00005 7.47151L8.02005 11.6615C7.13005 15.4415 8.16005 17.0915 11.9401 17.9915L13.6201 18.3915C14.1601 18.5215 14.6701 18.6015 15.1301 18.6415C15.4501 18.6715 15.7101 18.8915 15.8001 19.2015C15.8801 19.5115 15.7601 19.8315 15.5001 20.0115C14.8401 20.4615 14.0101 20.8415 12.9601 21.1815L11.3801 21.7015C10.2301 22.0615 9.23005 22.2415 8.33005 22.2415ZM7.78005 6.21151L6.49005 6.63151C2.92005 7.78151 2.07005 9.46151 3.22005 13.0415L4.50005 16.9915C5.66005 20.5615 7.34005 21.4215 10.9101 20.2715L12.4901 19.7515C12.5501 19.7315 12.6001 19.7115 12.6601 19.6915L11.6001 19.4415C6.99005 18.3515 5.48005 15.9115 6.56005 11.3115L7.54005 7.12151C7.61005 6.80151 7.69005 6.49151 7.78005 6.21151Z"
					fill={color}
				/>
				<path
					d="M17.4904 10.5015C17.4304 10.5015 17.3704 10.4915 17.3004 10.4815L12.4504 9.25148C12.0504 9.15148 11.8104 8.74148 11.9104 8.34148C12.0104 7.94148 12.4204 7.70148 12.8204 7.80148L17.6704 9.03148C18.0704 9.13148 18.3104 9.54148 18.2104 9.94148C18.1304 10.2715 17.8204 10.5015 17.4904 10.5015Z"
					fill={color}
				/>
				<path
					d="M14.5601 13.8815C14.5001 13.8815 14.4401 13.8715 14.3701 13.8615L11.4601 13.1215C11.0601 13.0215 10.8201 12.6115 10.9201 12.2115C11.0201 11.8115 11.4301 11.5715 11.8301 11.6715L14.7401 12.4115C15.1401 12.5115 15.3801 12.9215 15.2801 13.3215C15.2001 13.6615 14.9001 13.8815 14.5601 13.8815Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
