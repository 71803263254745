import { Typography, Box, Grid, Divider, Chip } from '@mui/material';
import { Card } from '../../../../shared/ui/Card/Card';
import { format } from 'date-fns';

type ContainerCardProps = {
	id: number;
	number: string;
	status: string;
	line: string;
	size: number;
	type: string;
    client: string;
    booking: string;
    isFilled: boolean;
    isDangerous: boolean;
    isDamaged: boolean;
    terminal: string;
    createdAt: string;
};

export const ContainerCard = ({
	id,
    number,
	status,
    line,
    size,
    type,
    client,
    booking,
    isFilled,
    isDangerous,
    isDamaged,
    terminal,
    createdAt,
}: ContainerCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px 24px 12px', display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item>
							<Typography variant="subtitle1">№ {number}</Typography>
						</Grid>

						<Grid item>
							<Chip label={status} color="primary" />
						</Grid>
					</Grid>
                    <Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Терминал:</Typography>
							<Typography variant="body2">{terminal}</Typography>
						</Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Линия:</Typography>
							<Typography variant="body2">{line}</Typography>
						</Grid>
					</Grid>

					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Размер:</Typography>
							<Typography variant="body2">{size}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Тип:</Typography>
							<Typography variant="body2">{type}</Typography>
						</Grid>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Заполнен:</Typography>
							<Typography variant="body2">{isFilled ? 'Да' : 'Нет'}</Typography>
						</Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Опасный груз</Typography>
							<Typography variant="body2">{isDangerous ? 'Да' : 'Нет'}</Typography>
						</Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Поврежден:</Typography>
							<Typography variant="body2">{isDamaged ? 'Да' : 'Нет'}</Typography>
						</Grid>
					</Grid>
				</Box>
				<Divider />
				<Box
					sx={{
						padding: '16px 24px',
					}}
				>
					<Grid container sx={{ justifyContent: 'space-between' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Клиент</Typography>
							<Typography variant="overline">{client}</Typography>
						</Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
							<Typography variant="caption">Дата создания</Typography>
							<Typography variant="overline">{format(createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
