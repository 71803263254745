import { useCallback, useEffect } from "react";
import { useCreateContainerMutation } from "../../../../store/newApi/containers/containers";

export const useHandleCreate = ({ handleClose, refetchData, createdCallback, ...methods }) => {
    const { reset: resetForm } = methods;
    const [createContainer, { data: createdContainer, isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateContainerMutation }] = useCreateContainerMutation();

    useEffect(() => {
        if (createdContainer?.id)
            createdCallback(createdContainer?.id)
    }, [createdCallback, createdContainer]);

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreateContainerMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isCreateSuccess, handleClose, resetCreateContainerMutation, refetchData, resetForm]);

    const handleCreate = useCallback((value) => {
        createContainer({
            orgId: value.client,
            terminalId: value.terminal,
            container_number: value.containerNumber,
            size: value.size,
            type: value.type,
            booking: value.bookingNumber,
            is_filled: value.isFilled,
            is_dangerous: value.isDangerous,
            is_damaged: value.isDamaged,
            line: value.line ?? value.line
        });
    }, [createContainer]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}