import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetServiceListQuery } from '../../../../store/newApi/service/service';
import { Button } from '@mui/material';
 
import { formatPrice } from '../../../../helpers/formatText';
import { resetFilter, setFilter, getExportExcel } from '../../../../store/rtkSlices/tariffs.js';
import { useDispatch } from 'react-redux';
import { FILTER_TYPES } from '../../../../config/filter.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { TariffsForm } from '../../../../newComponents/Form/TariffsForm/TariffsForm.jsx';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplTableTariffs() {
	const dispatch = useDispatch();
	// Выбор строк
	const [rowSelection, setRowSelection] = useState({});

	const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

	const [fetchServiceList, { data, isLoading, isError }] = useLazyGetServiceListQuery();

	// колонки
	const columnsList = useMemo(
		() => [
			{
				accessorFn: (row) => row.name,
				id: 'name',
				header: 'Название',
				size: 150,
			},
			{
				accessorFn: (row) => row.description,
				id: 'description',
				header: 'Описание',
				size: 200,
			},
			{
				accessorFn: (row) => row.terminal_name,
				id: 'terminal',
				header: 'Терминал',
				size: 200,
			},
			{
				accessorFn: (row) => (row.price_display ? formatPrice(row.price_display) : '-'),
				id: 'price',
				header: 'Цена, ₽',
				size: 150,
			},
			{
				accessorFn: (row) => row.unit_display,
				id: 'unit',
				header: 'Единица',
				size: 150,
			},
		],
		[],
	);

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

	const handleCreate = useCallback(() => {
		setRowSelection({});
		setIsCreateFormOpen(true);
	}, []);

	const handleUpdate = useCallback(() => {
		setIsCreateFormOpen(true);
	}, []);

	// порядок колонок
	const columnOrdering = ['mrt-row-select', 'name', 'description', 'terminal', 'price', 'unit'];

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	const handleExportExcel = () => {
		let viewCols = '';
		if (Object.keys(rowSelection).length !== 0) {
			viewCols += 'id=' + Object.keys(rowSelection).join(',');
		}
		dispatch(getExportExcel(viewCols));
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Тарифы и ставки'}
				btnDesktop={[
					selectedRows.length > 0 && (
						<Button variant="contained" color="secondary" size="medium" onClick={handleUpdate}>
							Изменить
						</Button>
					),
					<Button variant="contained" color="primary" size="medium" onClick={handleCreate}>
						Добавить новую
					</Button>,
					<Button variant="contained" color="primary" size="medium" onClick={handleExportExcel}>
						Экспортировать
					</Button>,
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal_id',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: 'client_ids',
								title: 'Клиент',
								type: FILTER_TYPES.INPUTDROPDOWNCLIENT,
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<TableComponent
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
						fetchData={fetchServiceList}
						isLoading={isLoading}
						isError={isError}
						data={data}
						columnsList={columnsList}
						columnOrdering={columnOrdering}
						filterName="tariffsRtk"
					/>
					<TariffsForm isOpen={isCreateFormOpen} handleClose={() => setIsCreateFormOpen(false)} entityIds={selectedRows} />
				</div>
			</div>
		</div>
	);
}

export default AccountEmplTableTariffs;
