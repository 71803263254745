import { useCallback, useEffect } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { useParams } from 'react-router-dom'; 

export const useHandleUpdate = ({ ...methods }) => {
	let { terminalId, orderId, entryId } = useParams();
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdate }] = useUpdateEntryMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			resetUpdate();
			resetForm();
			// TODO: Вызвать нотификашку
		}

		return () => {
			resetForm();
		};
	}, [isUpdateSuccess, resetUpdate, resetForm]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			update({
				terminalId,
				orderId,
				pass_pk: entryId,
				...touchedFieldValues,
				vehicle: value?.vehicle,
			});
		},
		[update, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
