import { useEffect } from 'react'; 
import { useGetStorageByIdQuery } from '../../../../store/newApi/service/service';

export const useInitFormState = ({ ids, isReady, ...methods }) => {
	const { setValue } = methods;
	const { data } = useGetStorageByIdQuery(
		{ id: ids[0] },
		{
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		if (data && isReady) { 
			if (data.containerNumber) setValue('containerNumber', data.container_number);
			if (data.free_days_storage) setValue('free_days_storage', `${data.free_days_storage}`);
			if (data.total_price) setValue('total_price', `${data.total_price}`);
			if (data.date_from) setValue('date_from', data.date_from);
			if (data.date_to) setValue('date_to', data.date_to);
		}
	}, [data, setValue, isReady]);
};
