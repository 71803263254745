export const IconClock = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12 23.7277C6.07 23.7277 1.25 18.9077 1.25 12.9777C1.25 7.04766 6.07 2.22766 12 2.22766C17.93 2.22766 22.75 7.04766 22.75 12.9777C22.75 18.9077 17.93 23.7277 12 23.7277ZM12 3.72766C6.9 3.72766 2.75 7.87766 2.75 12.9777C2.75 18.0777 6.9 22.2277 12 22.2277C17.1 22.2277 21.25 18.0777 21.25 12.9777C21.25 7.87766 17.1 3.72766 12 3.72766Z"
					fill={color}
				/>
				<path
					d="M15.7096 16.9077C15.5796 16.9077 15.4496 16.8777 15.3296 16.7977L12.2296 14.9477C11.4596 14.4877 10.8896 13.4777 10.8896 12.5877V8.48767C10.8896 8.07767 11.2296 7.73767 11.6396 7.73767C12.0496 7.73767 12.3896 8.07767 12.3896 8.48767V12.5877C12.3896 12.9477 12.6896 13.4777 12.9996 13.6577L16.0996 15.5077C16.4596 15.7177 16.5696 16.1777 16.3596 16.5377C16.2096 16.7777 15.9596 16.9077 15.7096 16.9077Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
