import { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material'; 
import { IconSearch } from '../../../assets/Icons';

export type SearchInputProps = {
	onSearch: (searchTerm: string) => void;
};

export const SearchInput = ({ onSearch }: SearchInputProps) => {
	const [searchTerm, setSearchTerm] = useState('');

	const handleSearch = () => {
		onSearch(searchTerm);
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSearch(searchTerm);
		}
	};

	return (
		<TextField
			variant="outlined"
			placeholder="Поиск"
			value={searchTerm}
			onChange={(e) => setSearchTerm(e.target.value)}
			onKeyDown={handleKeyDown}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							disableRipple
							onClick={handleSearch}
							sx={{
								padding: '0 16px 0 0',
							}}
						>
							<IconSearch size={'24px'} color={'#718EBF'} />
						</IconButton>
					</InputAdornment>
				),
				sx: {
					borderRadius: '8px',
					height: '40px',
					paddingRight: '0',
					color: '#939DAC',
					backgroundColor: '#F5F7FA',
				},
			}}
			sx={{
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: '#F5F7FA',
						borderWidth: '2px',
					},
					'&:hover fieldset': {
						borderColor: '#F5F7FA',
					},
					'&.Mui-focused fieldset': {
						borderColor: '#F5F7FA',
					},
				},
				'& input:-webkit-autofill': {
					WebkitTextFillColor: (theme) => theme.palette.text.secondary,
					caretColor: (theme) => theme.palette.text.secondary,
					WebkitBoxShadow: '0 0 0px 1000px #F5F7FA inset',
					backgroundColor: 'transparent',
					color: (theme) => theme.palette.text.secondary,
				},
				input: {
					height: '40px',
					padding: '0 0 0 16px',
					fontSize: '15px',
					fontWeight: '500',
					lineHeight: '20px',
					letterSpacing: '0.04px',
					fontFamily: 'Inter',
				},
			}}
			fullWidth
		/>
	);
};
