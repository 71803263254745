import React, { useEffect, useState } from 'react';
import { TextField, Grid, IconButton, Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateRangeInput } from './DateRangeInput.styles.js';
import ru from 'date-fns/locale/ru';
import IconCalendar from '../../../components/Icons/IconCalendar.jsx';
import { format, parse } from 'date-fns';

const formatDateToString = (date) => {
	return date ? format(date, 'dd.MM.yyyy') : '';
};

const parseStringToDate = (str) => {
	const parsedDate = parse(str, 'dd.MM.yyyy', new Date());
	return isNaN(parsedDate) ? null : parsedDate;
};

const DateRangeInput = ({ startDate, endDate, setStartDate, setEndDate }) => {
	const [isCalendarOpen, setIsCalendarOpen] = useState(false);
	const [startInput, setStartInput] = useState(formatDateToString(startDate));
	const [endInput, setEndInput] = useState(formatDateToString(endDate));

	useEffect(() => {
		if (startDate === '' && endDate === '') {
			setStartInput('');
			setEndInput('');
		}
	}, [startDate, endDate]);

	const handleDateChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		setStartInput(formatDateToString(start));
		setEndInput(formatDateToString(end));
	};

	const handleStartInputChange = (e) => {
		const inputValue = e.target.value;
		setStartInput(inputValue);

		const parsedDate = parseStringToDate(inputValue);
		if (parsedDate) {
			setStartDate(parsedDate);
		}
	};

	const handleEndInputChange = (e) => {
		const inputValue = e.target.value;
		setEndInput(inputValue);

		const parsedDate = parseStringToDate(inputValue);
		if (parsedDate) {
			setEndDate(parsedDate);
		}
	};

	return (
		<Box sx={{ display: 'flex', position: 'relative', gap: '8px' }}>
			<Grid container sx={{ width: '100%', margin: 0, display: 'flex', gap: '8px' }}>
				<Grid item sx={{ width: 'calc(50% - 4px)' }}>
					<TextField
						variant="outlined"
						placeholder="с"
						value={startInput}
						fullWidth
						onChange={handleStartInputChange}
						sx={{
							...dateRangeInput.textField,
							...(startInput && dateRangeInput.valueTextField),
						}}
					/>
				</Grid>
				<Grid item sx={{ width: 'calc(50% - 4px)' }}>
					<TextField
						variant="outlined"
						placeholder="до"
						value={endInput}
						fullWidth
						onChange={handleEndInputChange}
						sx={{
							...dateRangeInput.textField,
							...(endInput && dateRangeInput.valueTextField),
						}}
					/>
				</Grid>
			</Grid>
			<IconButton sx={dateRangeInput.btnCalendar} onClick={() => setIsCalendarOpen(!isCalendarOpen)}>
				<IconCalendar color={'white'} />
			</IconButton>

			{isCalendarOpen && (
				<Box sx={dateRangeInput.calendar}>
					<DatePicker
						onClickOutside={() => setIsCalendarOpen(false)}
						onCalendarClose={() => setIsCalendarOpen(false)}
						selected={startDate}
						onChange={handleDateChange}
						startDate={startDate}
						endDate={endDate}
						selectsRange
						inline
						dateFormat="dd.MM.yyyy"
						locale={ru}
						className="custom-datepicker"
					/>
				</Box>
			)}
		</Box>
	);
};

export default DateRangeInput;
