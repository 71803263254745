import React, { useState, useEffect } from "react";
import Step1 from "./components/Step1/Step1";
import Step2 from "./components/Step2/Step2";
import { Header } from "../../../../components";
import NonActive from "./components/NonActive/NonActive";
import ContactManager from "./components/ContactManager/ContactManager";

function AuthClient() {
  const [page, setPage] = useState(0);

  localStorage.removeItem("adminRegStep");
  localStorage.removeItem("manRegClientStep");
  localStorage.removeItem("regClientStep");

  useEffect(() => {
    const savedStep = localStorage.getItem("authClientStep");
    if (savedStep) {
      setPage(parseInt(savedStep));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("authClientStep", page.toString());
  }, [page]);

  const pageDisplay = () => {
    if (page === 0) {
      return <Step1 setPage={setPage} />;
    } else if (page === 1) {
      return <Step2 setPage={setPage} />;
    } else if (page === 2) {
      return <NonActive setPage={setPage} />
    } else if (page === 3) {
      return <ContactManager setPage={setPage} />
    }
  };

  return (
    <div className="backImg">
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>{pageDisplay()}</div>
    </div>
  );
}

export default AuthClient;
