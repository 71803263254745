import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { buildQueryString } from '../../shared/ui/Filters/utils'; 

const initialState = {
	filter: {
		page: 1,
		page_size: 30,
	},
}; 

// API для экспорта в Excel
const getExportExcelApi = async (query, url) => {
	const fullUrl =
		query === '' && url === ''
			? '/api/service-order/storage/orders/export_excel'
			: `/api/service-order/storage/orders/export_excel/?${query}${url}`;

	const response = await fetch(fullUrl, {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
	}); 

	return response.blob(); 
}; 

export const getExportExcel = createAsyncThunk('storage/getExportExcel', async (query, { rejectWithValue, getState }) => {
	try {
		const state = getState();
		const filterQuery = buildQueryString(state.storageRtk.filter).replace(/page=\d+&page_size=\d+&?/, ''); 
		const blob = await getExportExcelApi(query ? query + '&' : '', filterQuery); 

		return blob;
	} catch (error) {
		return rejectWithValue(error.message);
	}
}); 

export const storageSlice = createSlice({
	name: 'storage',
	initialState,
	reducers: {
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
		resetFilter: (state) => {
			state.filter = initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getExportExcel.fulfilled, (state, action) => {
			// Обработка скачивания файла
			const blob = action.payload;
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);

			// Форматируем название файла
			const formattedDate = new Date()
				.toLocaleString('ru-RU', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
				})
				.replace(',', '');

			link.download = `Таблица Хранение_${formattedDate}.xlsx`;
			link.click();
		});
	},
}); 

export const { setFilter, resetFilter } = storageSlice.actions;
export default storageSlice.reducer;
