import { useCallback, useEffect } from "react";
import { DamageItemApi } from "../../../../../../../shared/entryreports/types"
import { RepairItem } from "../../../../../../../shared/ui/RepairItem/RepairItem";
import { useNavigate } from "react-router-dom";
import { INSPECTION_DAMAGE_EDIT_URL } from "../../../../../../../shared/inspection/pageUrls";
import { Preloader } from "../../../../../../../components";
import { Button } from "@mui/material";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";

type InspectionDamagesStepProps = {
    damages: DamageItemApi[];
    entryReportId: number;
    isFetching: boolean;
    disableEdit: boolean;
    refetchEntryReport: ({ id }) => void;
    onBack: () => void;
    onNext: () => void;
}

export const InspectionDamagesStep = ({ damages, entryReportId, isFetching, disableEdit, refetchEntryReport, onBack, onNext }: InspectionDamagesStepProps) => {
    const navigate = useNavigate();

    const handleDamageClick = useCallback((damageId) => {
        navigate(INSPECTION_DAMAGE_EDIT_URL(entryReportId, 2, damageId))
    }, [entryReportId]);

    useEffect(() => {
        if (entryReportId)
            refetchEntryReport({ id: entryReportId })
    }, [refetchEntryReport, entryReportId]);

    if (isFetching)
        // @ts-ignore
        return <Preloader bg={'true'} />

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                {damages.map(d => (
                    <RepairItem
                        component={(d.component || [])[0]}
                        side={d?.side}
                        damageName={d.damage.map(item => item.name).join(', ')}
                        onClick={() => handleDamageClick(d.uuid)}
                    />
                ))}
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Назад',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Продолжить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onNext
                    }
                ]}
            />
        </div>
    )
}