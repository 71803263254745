import { useCallback, useEffect, useMemo } from 'react';
import { filterTouchedFields } from '../../utils/filterTouchedFields';
import { useUpdateStorageMutation } from '../../../../store/newApi/service/service';

export const useHandleUpdate = ({ entityIds, handleClose, ...methods }) => {
	const {
		formState: { touchedFields },
		reset: resetForm,
	} = methods;
	const [updateStorage, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading, reset: resetUpdateStorageMutation }] =
		useUpdateStorageMutation();

	useEffect(() => {
		if (isUpdateSuccess) {
			handleClose();
			resetUpdateStorageMutation();
			resetForm();
			// TODO: Вызвать нотификашку
		}

		return () => {
			resetForm();
		};
	}, [isUpdateSuccess, handleClose, resetUpdateStorageMutation, resetForm]);

	const handleUpdate = useCallback(
		(value) => {
			const touchedFieldValues = filterTouchedFields(value, touchedFields);
			const payload = { 
				container_number: touchedFieldValues.containerNumber,
				free_days_storage: touchedFieldValues.free_days_storage,
				total_price: touchedFieldValues.total_price, 
				date_from: touchedFieldValues.date_from,
				date_to: touchedFieldValues.date_to,
			};
			updateStorage({ storage_order_pk: entityIds, ...payload });
		},
		[entityIds, updateStorage, touchedFields],
	);

	return {
		handleUpdate,
		isUpdateSuccess,
		isUpdateLoading,
	};
};
