import { memo, useCallback } from 'react';
import styles from './PermissionsTable.module.scss';
import { Button, Checkbox } from '@mui/material';

// selectedItems = { defaultPermissionsSelections: { permissionsMap, disabled }, selectedItems: { permissionsMap, disabled } }
export const PermissionsTable = memo(({ sections = [], selectedItems = {}, setSelectedItems }) => {
	const getPermissions = useCallback((section) => {
		if (selectedItems.selectedItems.permissionsMap[section]) {
			return { permissions: selectedItems.selectedItems.permissionsMap[section], disabled: selectedItems.selectedItems.disabled }
		}
		if (selectedItems.defaultPermissionsSelections.permissionsMap[section]) {
			return { permissions: selectedItems.defaultPermissionsSelections.permissionsMap[section], disabled: selectedItems.defaultPermissionsSelections.disabled }
		}
		return { permissions: { add: false, change: false, delete: false, view: false }, disabled: false };
	}, [selectedItems]);
	// Функция для выбора или снятия галочки
	const togglePermission = (section, type) => {
		const updatedPermissions = { ...selectedItems.selectedItems.permissionsMap };

		if (!updatedPermissions[section])
			updatedPermissions[section] = {
				add: false,
				change: false,
				delete: false,
				view: false,
			};

		if (type === 'view') {
			if (updatedPermissions[section][type]) {
				updatedPermissions[section] = {
					add: false,
					change: false,
					delete: false,
					view: false,
				};
			} else {
				updatedPermissions[section].view = true;
			}
		} else {
			updatedPermissions[section][type] = !updatedPermissions[section][type];

			if (updatedPermissions[section][type]) {
				updatedPermissions[section].view = true;
			}
		}

		setSelectedItems(updatedPermissions);
	};

	// Функция для массового выбора/снятия всех галочек в столбце
	const selectAll = (type) => {
		const allSelected = sections.every((section) => selectedItems[section][type]);

		const updatedPermissions = { ...selectedItems.selectedItem.permissionsMap };
		sections.forEach((section) => {
			updatedPermissions[section][type] = !allSelected;

			if (['add', 'change', 'delete'].includes(type) && !allSelected) {
				updatedPermissions[section].view = true;
			}

			if (type === 'view' && allSelected) {
				updatedPermissions[section] = {
					add: false,
					change: false,
					delete: false,
					view: false,
				};
			}
		});
		setSelectedItems(updatedPermissions);
	};

	// Проверка, выбраны ли все чекбоксы для определенного типа (например, "Удаление")
	const areAllSelected = (type) => {
		return sections?.every((section) => selectedItems && selectedItems[section] && selectedItems[section][type]);
	};

	return (
		<div>
			<div className={styles.permissionsTable}>
				<div className={styles.header}>
					<div className={styles.headerTitle}>Разделы</div>
					<div className={styles.headerBtns}>
						<Button
							size={'medium'}
							variant="contained"
							color={areAllSelected('add') ? 'secondary' : 'primary'}
							onClick={() => selectAll('add')}
						>
							Создание
						</Button>
						<Button
							size={'medium'}
							variant="contained"
							color={areAllSelected('change') ? 'secondary' : 'primary'}
							onClick={() => selectAll('change')}
						>
							Изменение
						</Button>
						<Button
							size={'medium'}
							variant="contained"
							color={areAllSelected('delete') ? 'secondary' : 'primary'}
							onClick={() => selectAll('delete')}
						>
							Удаление
						</Button>
						<Button
							size={'medium'}
							variant="contained"
							color={areAllSelected('view') ? 'secondary' : 'primary'}
							onClick={() => selectAll('view')}
						>
							Просмотр
						</Button>
					</div>
				</div>
				<div className={styles.table}>
					{sections.map((section) => {
						const currentSectionPermissions = getPermissions(section);
						return <div className={styles.row} key={section}>
							<div className={styles.sectionName}>{section}</div>
							<div className={styles.checkboxes}>
								<Checkbox
									checked={currentSectionPermissions?.permissions?.add}
									disabled={!currentSectionPermissions?.permissions?.add ? false : currentSectionPermissions.disabled}
									onChange={() => togglePermission(section, 'add')}
									sx={{
										color: '#5C53A7',
										'&.Mui-checked': {
											color: '#5C53A7',
										},
									}}
								/>
								<Checkbox
									checked={currentSectionPermissions?.permissions?.change}
									disabled={!currentSectionPermissions?.permissions?.change ? false : currentSectionPermissions.disabled}
									onChange={() => togglePermission(section, 'change')}
									sx={{
										color: '#5C53A7',
										'&.Mui-checked': {
											color: '#5C53A7',
										},
									}}
								/>
								<Checkbox
									checked={currentSectionPermissions?.permissions?.delete}
									disabled={!currentSectionPermissions?.permissions?.delete ? false : currentSectionPermissions.disabled}
									onChange={() => togglePermission(section, 'delete')}
									sx={{
										color: '#5C53A7',
										'&.Mui-checked': {
											color: '#5C53A7',
										},
									}}
								/>
								<Checkbox
									checked={currentSectionPermissions?.permissions?.view}
									disabled={!currentSectionPermissions?.permissions?.view ? false : currentSectionPermissions.disabled}
									onChange={() => togglePermission(section, 'view')}
									sx={{
										color: '#5C53A7',
										'&.Mui-checked': {
											color: '#5C53A7',
										},
									}}
								/>
							</div>
						</div>
					})}
				</div>
			</div>
		</div>
	);
});
