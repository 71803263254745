import { useEffect, useState } from 'react';
import { Header } from '../../../../components';
import styles from './RegistrationClient.module.css';
import { Button } from '@mui/material';
import { RegistrationClientForm } from './components/RegistrationClientForm';
import { IconArrowLeft } from '../../../../components/Icons';
import { useNavigate } from 'react-router-dom';
import { RegistrationClientManager } from './components/RegistrationClientManager';
import { RegistrationClientSuccess } from './components/RegistrationClientSuccess';

export const RegistrationClient = () => {
	const [page, setPage] = useState(0); 

	useEffect(() => {
		return setPage(0);
	}, []);

	const renderPage = () => {
		switch (page) {
			case 0:
				return (
					<div className={styles.modalContainer}>
						<p className={styles.modalTitle}>Регистрация</p>
						<Button variant="contained" color="primary" size="medium" onClick={() => setPage(1)}>
							С помощью менеджера
						</Button>
						<Button variant="contained" color="primary" size="medium" onClick={() => setPage(2)}>
							Самостоятельно
						</Button>
					</div>
				);
			case 1:
				return <RegistrationClientManager />;
			case 2:
				return <RegistrationClientForm setPage={setPage}/>;
			case 3:
				return <RegistrationClientSuccess />;
			default:
				return null;
		}
	};

	const handleBack = () => {
		if (page === 2) {
			setPage(0);
		} else {
			setPage(page - 1);
		}
	};

	return (
		<div className="backImg">
			<Header />
			<div className={styles.wrap}>
				<div className={styles.container}>
					{page !== 0 && page !== 3 && (
						<div className={styles.btnBack} onClick={handleBack}>
							<IconArrowLeft size={'medium'} color={'black'} />
						</div>
					)}
					<div className={styles.modal}>{renderPage()}</div>
				</div>
			</div>
		</div>
	);
};
