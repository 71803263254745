import React, { useEffect, useMemo } from 'react';
import styles from '../AccountEmpl.module.scss';
import { useParams } from 'react-router-dom';
import Header from '../../../../newComponents/Header/Header';
import { useLazyGetEntryQuery, useUpdateEntryMutation } from '../../../../store/newApi/entries/entries';
import { Box, Button } from '@mui/material';
import { Preloader } from '../../../../components';
import { account } from './Account.styles.js';
import { EntriesForm } from '../../../../newComponents/Form/EntriesForm/EntriesForm.jsx';
import { useNavigate } from 'react-router-dom';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import { PAGE_ENTRIES } from '../../../../shared/entries/pageUrls';
import { usePrintDocMutation } from '../../../../store/newApi/print/print';

function AccountEmplEntryPage() {
	let { terminalId, orderId, entryId } = useParams();
	const [trigger, { data, isLoading }] = useLazyGetEntryQuery({});
	const [updateEntry, { isSuccess: isCreateSuccess }] = useUpdateEntryMutation();
	const navigate = useNavigate();
	const [printDoc] = usePrintDocMutation();

	useEffect(() => {
		if (terminalId && orderId && entryId) {
			trigger({ terminalId, orderId, entryId });
		}
	}, [terminalId, orderId, entryId]);

	const handleChangeStatus = () => {
		updateEntry({ terminalId, orderId, pass_pk: entryId, status: 2 });
	};

	useEffect(() => {
		if (isCreateSuccess) {
			const timer = setTimeout(() => {
				navigate(PAGE_ENTRIES);
			}, 1000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [isCreateSuccess]);

	const getHeaderBtns = useMemo(() => {
		let btns = [
			<Button variant="contained" color="secondary" onClick={() => printDoc({
				template_id: 3,
				instance_id: data?.id
			})}>
				Распечатать
			</Button>
		];
		if (isEmpl() && data?.status == 0 || data?.status == 1) {
			btns.push(
				<Button variant="contained" color="primary" onClick={handleChangeStatus}>
					Активировать пропуск
				</Button>
			)
		} else {
			btns.push(data?.status_display)
		}
		return btns;
	}, [data]);

	return (
		<div className={styles.account__wrap}>
			<Header
				text={isLoading ? '' : !terminalId && !orderId && !entryId ? 'Создать пропуск' : `${data ? data?.name : ''}`}
				btnDesktop={getHeaderBtns}
				btnMobile={getHeaderBtns}
			/>
			{isLoading ? (
				<Box sx={account.preloader}>
					<Preloader />
				</Box>
			) : (
				<div className={styles.account__container}>
					<div className={styles.account__stack}>
						<EntriesForm isCreate={!terminalId && !orderId && !entryId} entry={data} />
					</div>
				</div>
			)}
		</div>
	);
}

export default AccountEmplEntryPage;
