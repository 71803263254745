import { useCallback, useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../../../../../newComponents/TableComponent/TableComponent';
import { useLazyGetContainersListQuery, useLinkContainersMutation, useUpdateContainersMutation } from '../../../../../../../store/newApi/containers/containers';
import styles from './ChooseContainerStep.module.css';
import { useGetContainerTableColumns } from '../../../../../../../shared/ui/Tables/hooks/columns/useGetContainerTableColumns'; 
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../../../../store/rtkSlices/containers';
import { ContainerForm } from '../../../../../../../newComponents/Form/ContainerForm/ContainerForm';
import { Button, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Preloader } from '../../../../../../../components';
import { LinkedContainerItem } from './LinkedContainerItem';
import { DateInput } from '../../../../../../../newComponents/Inputs/DateInput/DateInput';
import { ImportContainers } from '../../../../../../../shared/ui/ImportContainers/ImportContainers';
import { useHandleChangeContainersDate } from './hooks/useHandleChangeContainersDate';
import { PageFooterActions } from '../../../../../../../shared/ui/PageFooterActions/PageFooterActions';
import { Filters } from '../../../../../../../shared/ui/Filters/Filters';

type ChooseContainerStepProps = {
    orderId: number;
    terminalId: number;
    clientId: number;
    isFetchingOrder: boolean;
    isDelivery: boolean;
    enabledStoragePrice: boolean;
    isImportOpen: boolean;
    disabledCreateNewContainer: boolean;
    totalEtaPrice?: number;
    onCancel: () => void;
    onBack: () => void;
    onNext: () => void;
    refetchOrder: () => void;
    toggleIsOpen: (flag: boolean) => void;
}

/**
 * TODO
 * 1. Возвращать список контейнеров в заказе
 */
export const ChooseContainerStep = ({
    orderId,
    terminalId,
    clientId,
    isFetchingOrder,
    isDelivery,
    enabledStoragePrice,
    disabledCreateNewContainer,
    isImportOpen,
    totalEtaPrice,
    toggleIsOpen,
    onCancel,
    refetchOrder,
    onBack,
    onNext,
}: ChooseContainerStepProps) => {
    // @ts-ignore
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
    const dispatch = useDispatch();
    const [fetchContainers, { data, isLoading, isFetching: isFetchingContainers, isError }] = useLazyGetContainersListQuery({});
    const [fetchLinkedContainers, { data: linkedContainers, isFetching: isLinkedContainersLoading }] = useLazyGetContainersListQuery({});
    const [linkContainers, { isSuccess: isLinkingSuccess, isLoading: isLinkInProgress, reset: resetLink }] = useLinkContainersMutation();
    const [updateContainer, { isSuccess: isUpdatingContainerSuccess, isLoading: isUpdatingContainerFetching, reset: resetUpdatingContainer }] = useUpdateContainersMutation();
    const handleFetchLinkedContainers = useCallback(() => {
        fetchLinkedContainers({ order_id: orderId, page_size: 100 });
    }, [fetchLinkedContainers, orderId]);

    const [rowSelection, setRowSelection] = useState({});
    const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

    const { columnsList, columnOrdering } = useGetContainerTableColumns();

    const selectedContainersIds = useMemo(() => Object.keys(rowSelection), [rowSelection]);
    const linkedContainersId = useMemo(() => linkedContainers?.results?.map(c => c.id) || [], [linkedContainers]);

    const { handleDateChange } = useHandleChangeContainersDate({ updateCallback: () => {
            handleFetchLinkedContainers();
            refetchOrder()
        }
    });

    const defaultContainerFilters = useMemo(() => ({ client_id: clientId, terminal_id: terminalId }), [clientId, terminalId])

    const handleSetFilter = useCallback((filters) => {
        dispatch(setFilter({ ...defaultContainerFilters, ...filters }));
    }, [defaultContainerFilters])

    const handleResetFilter = useCallback(() => {
        dispatch(setFilter({ ...defaultContainerFilters, page: 1, page_size: 30 }));
    }, [defaultContainerFilters])

    const handleLinkContainers = useCallback(() => {
        linkContainers({ containerIds: [...new Set([...selectedContainersIds, ...linkedContainersId])], terminalId, clientId, orderId })
    }, [linkContainers, orderId, terminalId, clientId, selectedContainersIds, linkedContainersId]);

    const handleUnlinkContainer = useCallback((id: number) => {
        linkContainers({ containerIds: linkedContainersId.filter(c => c !== id), terminalId, clientId, orderId })
    }, [linkContainers, orderId, terminalId, clientId, linkedContainersId]);

    const linkCreatedContainer = useCallback((ids: number[]) => {
        linkContainers({ containerIds: [...new Set([...linkedContainersId, ...ids])], terminalId, clientId, orderId })
    }, [linkContainers, terminalId, clientId, orderId, linkedContainersId]);

    const handleUpdateOrderStatus = useCallback(() => {
        onNext()
    }, [onNext]);

    useEffect(() => {
        handleFetchLinkedContainers();
    }, [orderId, isDelivery, handleFetchLinkedContainers]);

    useEffect(() => {
        if (isLinkingSuccess || isUpdatingContainerSuccess) {
            handleFetchLinkedContainers();
            fetchContainers({ ...defaultContainerFilters, page: 1, page_size: 30 });
            setRowSelection({})
            refetchOrder()
            resetLink();
            resetUpdatingContainer();
        }
    }, [
        isLinkingSuccess,
        resetLink,
        handleFetchLinkedContainers,
        isDelivery,
        orderId,
        isUpdatingContainerSuccess,
        resetUpdatingContainer,
        defaultContainerFilters,
        fetchContainers,
        refetchOrder
    ]);

    useEffect(() => {
        dispatch(setFilter({ ...defaultContainerFilters }));
    }, [defaultContainerFilters]);

    if (isFetchingOrder)
        // @ts-ignore
        return <Preloader bg={'true'} />

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', gap: '20px', flexGrow: 1, justifyContent: 'space-between' }}>
            <div className={styles.container}>
                <div className={styles.sideBlock}>
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <Filters 
                            onSetFilter={handleSetFilter}
                            onResetFilter={handleResetFilter}
                            enabled={false}
                            parameters={[]}
                        />
                        {!disabledCreateNewContainer && <Button variant="contained" color="primary" size="medium" onClick={() => setIsCreateFormOpen(true)}>
                            <AddIcon />
                        </Button>}
                        {selectedContainersIds.length > 0 && <Button variant="contained" color="secondary" size="medium" onClick={handleLinkContainers}>
                            Добавить <ChevronRightIcon />
                        </Button>}
                    </div>
                    <TableComponent
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        fetchData={fetchContainers}
                        isLoading={isLoading}
                        isFetching={isFetchingContainers}
                        isError={isError}
                        data={data}
                        columnsList={columnsList}
                        columnOrdering={columnOrdering}
                        filterName="containersRtk"
                        resetFilters={() => dispatch(setFilter({ ...defaultContainerFilters }))}
                    />
                    <ContainerForm
                        isOpen={isCreateFormOpen}
                        handleClose={() => setIsCreateFormOpen(false)}
                        entityIds={[]}
                        refetchData={() => fetchContainers({ ...defaultContainerFilters, page: 1, page_size: 30 })}
                        notEditableValues={{ client: clientId, terminal: terminalId }}
                        createdCallback={(containerId) => linkCreatedContainer([containerId])}
                    />
                </div>
                <div className={styles.sideBlock}>
                    {(isLinkInProgress || isLinkedContainersLoading) && <Preloader bg={'true'} small />}
                    {!isLinkInProgress && !isLinkedContainersLoading && <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between' }}>
                        <Typography variant="h6">
                            {`Добавленные контейнеры ${linkedContainers?.count || ''}`}
                        </Typography>
                        {!disabledCreateNewContainer && enabledStoragePrice && <div style={{ width: '200px' }}>
                            <DateInput value={linkedContainers?.results[0]?.eta_checkout} onChange={(value) => handleDateChange(value, linkedContainersId)} placeholder="Дата вывоза" />
                        </div>}
                    </div>}
                    {!!totalEtaPrice && <div>{`Общая стоимость хранения: ${totalEtaPrice} ₽`}</div>}
                    <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', marginTop: '10px' }}>
                        {linkedContainers?.results?.map(c => (
                            <LinkedContainerItem
                                id={c.id}
                                containerNumber={c.container_number}
                                onRemove={handleUnlinkContainer}
                                storagePrice={c.eta_storage_price}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Отменить создание',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onCancel
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Назад',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    },
                    {
                        text: 'Продолжить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        onClick: handleUpdateOrderStatus
                    }
                ]}
            />
            <ImportContainers
                isOpen={isImportOpen}
                toggleIsOpen={toggleIsOpen}
                defaultCreateFields={{
                    terminalId: +terminalId,
                    orgId: clientId
                }}
                importedCallback={(containerIds) => linkCreatedContainer(containerIds)}
                disableNavigation={isFetchingOrder}
            />
        </div>
    )
}