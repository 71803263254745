import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetRepairGroupByIdQuery, useUpdateRepairGroupMutation } from "../../../../../../../store/newApi/service/service";
import RViewerJS from "viewerjs-react";
import ImageUploading from 'react-images-uploading';
import { IconPlus } from '../../../../../../../components/Icons';
import { format } from 'date-fns';

import styles from './ChooseServicesStep.module.css'
import { useDeleteImageMutation, useUploadImageMutation } from "../../../../../../../store/newApi/images/images";
import { UploadedImageItem } from "../../../../../../../shared/ui/UploadImage/components/UploadedImageItem/UploadedImageItem";
import { IMAGES_FILE_PATH } from "../../../../../../../shared/ui/UploadImage/constants";
import { UploadedImage } from "../../../../../../../shared/ui/UploadImage/UploadImage";
import { Preloader } from "../../../../../../../components";

export const RepairPhotoFix = ({ repairGroupId, disabled, handleSetPhotoFix }) => {
	const [uploadImage, { isLoading: isFetchingUpload, reset: resetUpload }] = useUploadImageMutation();
    const [updateRepairGroup, { data: updatedRepairGroup, isSuccess, reset: resetUpdate }] = useUpdateRepairGroupMutation();
    const [fetchRepairGroup, { data: repairGroupData }] = useLazyGetRepairGroupByIdQuery();
    const [deleteImage, { isLoading: isFetchingDeleteImage, reset: resetDeleteImage }] = useDeleteImageMutation();

    const fixPictures = useMemo(() => repairGroupData?.fix_pictures || {} || [], [repairGroupData])
    const fixPicturesArr = useMemo(() => Object.entries(fixPictures) || [], [fixPictures])

    useEffect(() => {
        fetchRepairGroup({ id: repairGroupId })
    }, [fetchRepairGroup, repairGroupId]);

    useEffect(() => {
        handleSetPhotoFix(fixPicturesArr)
    }, [handleSetPhotoFix, fixPicturesArr])

    const handleSaveImages = useCallback(({ url, filename }) => {
        const createdAt = format(new Date(), 'dd-MM-yyyy');
        updateRepairGroup({
            id: repairGroupId, fix_pictures: {
                ...fixPictures,
                [`${createdAt}`]: [...(fixPictures[createdAt] || []), {url: url, filename: filename}]
            }
        }).then((data) => {
            fetchRepairGroup({ id: repairGroupId })
            resetUpdate()
        })
    }, [repairGroupId, updateRepairGroup, fixPictures])

    const handleRemovePic = useCallback((date, pickIndex, filename) => {
        deleteImage({ filename }).then(() => {
            resetDeleteImage();
            const filteredPicks = fixPictures[date].filter((item) => item?.filename !== filename);
            let updatedPicks = {
                ...fixPictures,
            }
            if (filteredPicks.length > 0)
                updatedPicks = {...updatedPicks, [date]: filteredPicks}
            else
                delete updatedPicks[date]

            updateRepairGroup({
                id: repairGroupId,
                fix_pictures: updatedPicks
            }).then(() => {
                fetchRepairGroup({ id: repairGroupId })
                resetUpdate()
            });
		});
    }, [fixPictures, updateRepairGroup, repairGroupId]);

    const onChange = async (imageList, addUpdateIndex) => {
		if (imageList.length > 0) {
			const formData = new FormData();
			// @ts-ignore
			formData.append('file_path', IMAGES_FILE_PATH.REPAIRFIX);
			formData.append('file', imageList[0]?.file);
			uploadImage({ formData }).then(({ data }: { data: UploadedImage }) => {
                resetUpload()
                handleSaveImages({ filename: data.filename, url: data.url });
            })
		}
	};

    const isLoadingImages = useMemo(() => isFetchingUpload || isFetchingDeleteImage, [isFetchingUpload, isFetchingDeleteImage])

    return (
        <div>
            {/* @ts-ignore */}
            <ImageUploading
                multiple
                onChange={onChange}
                maxNumber={10}
                dataURLKey="data_url"
                acceptType={['jpg', 'png', 'jpeg']}
            >
                {({ imageList, onImageUpload, isDragging, dragProps }) => (
                    <>

                        {!disabled && <div
                            className={`${styles.uploadImageWrapper} ${styles.photoContainer} ${isDragging ? styles.dragging : ''}`}
                            {...dragProps}
                        >
                            <button className={styles.uploadButton} onClick={onImageUpload}>
                                {'Загрузить фото'}
                                {/* @ts-ignore */}
                                <IconPlus color={'black'} size={'large'} />
                            </button>
                        </div>}
                        {fixPicturesArr.length === 0 && <span>Нет добавленных фото</span>}
                        {/* @ts-ignore */}
                        {isLoadingImages && <Preloader bg={'true'} />}
                        {/* @ts-ignore */}
                        {fixPicturesArr.length > 0 && <RViewerJS className={styles.viewer}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                {fixPicturesArr.map(([date, imgs], dateIndex) => {
                                    return (
                                        <div key={`image_wrapper_${dateIndex}`} className={styles.onePhotoCard}>
                                            <span>{date}</span>
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                {/* @ts-ignore */}
                                                {imgs.map((img, pickIndex) => {
                                                    return (
                                                        <UploadedImageItem
                                                            disabled={disabled}
                                                            filename={img?.filename}
                                                            url={img?.url}
                                                            handleDelete={(filename) => handleRemovePic(date, pickIndex, filename)}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </RViewerJS>}
                    </>
                )}
            </ImageUploading>
        </div>
    )
}