import api from '../../api.js';

export const containers = api.injectEndpoints({
	endpoints: (builder) => ({
		uploadImage: builder.mutation({
			query: ({ formData }) => {
				return {
					url: `/api/file_management/upload/`,
					method: 'POST',
					body: formData,
				}
			}
		}),
		deleteImage: builder.mutation({
			query: ({ filename }) => {
				return {
					url: `/api/file_management/delete/${filename}`,
					method: 'DELETE',
				}
			}
		}),
	}),
});

export const {
    useUploadImageMutation,
	useDeleteImageMutation,
} = containers;
