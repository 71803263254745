export const IconUserOctagon = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12.0002 23.7306C11.3302 23.7306 10.6502 23.5606 10.0502 23.2106L4.11016 19.7806C2.91016 19.0806 2.16016 17.7906 2.16016 16.4006V9.56064C2.16016 8.17064 2.91016 6.88064 4.11016 6.18064L10.0502 2.75065C11.2502 2.05065 12.7402 2.05065 13.9502 2.75065L19.8902 6.18064C21.0902 6.88064 21.8402 8.17064 21.8402 9.56064V16.4006C21.8402 17.7906 21.0902 19.0806 19.8902 19.7806L13.9502 23.2106C13.3502 23.5606 12.6702 23.7306 12.0002 23.7306ZM12.0002 3.73063C11.5902 3.73063 11.1702 3.84063 10.8002 4.05063L4.86016 7.48063C4.12016 7.91063 3.66016 8.70064 3.66016 9.56064V16.4006C3.66016 17.2506 4.12016 18.0506 4.86016 18.4806L10.8002 21.9106C11.5402 22.3406 12.4602 22.3406 13.2002 21.9106L19.1402 18.4806C19.8802 18.0506 20.3402 17.2606 20.3402 16.4006V9.56064C20.3402 8.71064 19.8802 7.91063 19.1402 7.48063L13.2002 4.05063C12.8302 3.84063 12.4102 3.73063 12.0002 3.73063Z"
					fill={color}
				/>
				<path
					d="M11.9999 12.7307C10.2999 12.7307 8.91992 11.3507 8.91992 9.6507C8.91992 7.9507 10.2999 6.57074 11.9999 6.57074C13.6999 6.57074 15.0799 7.9507 15.0799 9.6507C15.0799 11.3507 13.6999 12.7307 11.9999 12.7307ZM11.9999 8.07074C11.1299 8.07074 10.4199 8.7807 10.4199 9.6507C10.4199 10.5207 11.1299 11.2307 11.9999 11.2307C12.8699 11.2307 13.5799 10.5207 13.5799 9.6507C13.5799 8.7807 12.8699 8.07074 11.9999 8.07074Z"
					fill={color}
				/>
				<path
					d="M16 18.3907C15.59 18.3907 15.25 18.0507 15.25 17.6407C15.25 16.2607 13.79 15.1308 12 15.1308C10.21 15.1308 8.75 16.2607 8.75 17.6407C8.75 18.0507 8.41 18.3907 8 18.3907C7.59 18.3907 7.25 18.0507 7.25 17.6407C7.25 15.4307 9.38 13.6308 12 13.6308C14.62 13.6308 16.75 15.4307 16.75 17.6407C16.75 18.0507 16.41 18.3907 16 18.3907Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
