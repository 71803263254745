export const IconBox = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M12.0001 13.2966C11.8701 13.2966 11.7401 13.2666 11.6201 13.1966L2.79008 8.08656C2.44008 7.87656 2.31008 7.41656 2.52008 7.05656C2.73008 6.69656 3.19008 6.57656 3.55008 6.78656L12.0001 11.6766L20.4001 6.81656C20.7601 6.60656 21.2201 6.73656 21.4301 7.08656C21.6401 7.44656 21.5101 7.90656 21.1601 8.11656L12.3901 13.1966C12.2601 13.2566 12.1301 13.2966 12.0001 13.2966Z"
					fill={color}
				/>
				<path
					d="M12 22.3566C11.59 22.3566 11.25 22.0166 11.25 21.6066V12.5366C11.25 12.1266 11.59 11.7866 12 11.7866C12.41 11.7866 12.75 12.1266 12.75 12.5366V21.6066C12.75 22.0166 12.41 22.3566 12 22.3566Z"
					fill={color}
				/>
				<path
					d="M11.9996 22.7466C11.1196 22.7466 10.2496 22.5566 9.55965 22.1766L4.21965 19.2066C2.76965 18.4066 1.63965 16.4766 1.63965 14.8166V9.16658C1.63965 7.50658 2.76965 5.58658 4.21965 4.77658L9.55965 1.81658C10.9296 1.05658 13.0696 1.05658 14.4396 1.81658L19.7796 4.78658C21.2297 5.58658 22.3596 7.51658 22.3596 9.17658V14.8266C22.3596 16.4866 21.2297 18.4066 19.7796 19.2166L14.4396 22.1766C13.7496 22.5566 12.8796 22.7466 11.9996 22.7466ZM11.9996 2.74658C11.3696 2.74658 10.7496 2.87658 10.2896 3.12658L4.94965 6.09658C3.98965 6.62658 3.13965 8.06658 3.13965 9.16658V14.8166C3.13965 15.9166 3.98965 17.3566 4.94965 17.8966L10.2896 20.8666C11.1996 21.3766 12.7996 21.3766 13.7096 20.8666L19.0496 17.8966C20.0096 17.3566 20.8596 15.9266 20.8596 14.8166V9.16658C20.8596 8.06658 20.0096 6.62658 19.0496 6.08658L13.7096 3.11658C13.2496 2.87658 12.6296 2.74658 11.9996 2.74658Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
