import { format, parseISO } from "date-fns";
import { useCallback, useEffect } from "react";
import { useUpdateContainersMutation } from "../../../../../../../../store/newApi/containers/containers";

export const useHandleChangeContainersDate = ({ updateCallback }) => {
    const [updateContainer, { isSuccess: isUpdatingContainerSuccess, isLoading: isUpdatingContainerFetching, reset: resetUpdatingContainer }] = useUpdateContainersMutation();

    const handleDateChange = useCallback((value: string, linkedContainerIds: number[]) => {
        updateContainer({ eta_checkout: format(parseISO(value), "yyyy-MM-dd"), container_ids: linkedContainerIds })
    }, [updateContainer]);

    useEffect(() => {
        if (isUpdatingContainerSuccess) {
            updateCallback();
            resetUpdatingContainer();
        }
    }, [updateCallback, resetUpdatingContainer, isUpdatingContainerSuccess]);

    return {
        handleDateChange
    }
}