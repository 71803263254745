export const IconTag2 = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12.0701 22.9665C10.6601 22.9665 9.24009 22.4265 8.17009 21.3565L3.64009 16.8265C2.54009 15.7265 1.96009 14.2065 2.03009 12.6565L2.27009 7.64653C2.38009 5.25653 4.27009 3.36653 6.67009 3.24653L11.6701 3.00653C13.2301 2.93653 14.7401 3.51653 15.8401 4.61653L20.3701 9.14653C22.5201 11.2965 22.5201 14.8065 20.3701 16.9565L15.9801 21.3465C14.9001 22.4265 13.4901 22.9665 12.0701 22.9665ZM4.70009 15.7565L9.23009 20.2865C10.8001 21.8565 13.3501 21.8565 14.9201 20.2865L19.3101 15.8965C20.8801 14.3265 20.8801 11.7765 19.3101 10.2065L14.7701 5.68653C13.9701 4.88653 12.8801 4.46653 11.7301 4.51653L6.73009 4.75653C5.11009 4.82653 3.84009 6.09653 3.76009 7.71653L3.52009 12.7265C3.47009 13.8465 3.90009 14.9565 4.70009 15.7565Z"
					fill={color}
				/>
				<path
					d="M9.5 13.7365C7.71 13.7365 6.25 12.2765 6.25 10.4865C6.25 8.69651 7.71 7.23651 9.5 7.23651C11.29 7.23651 12.75 8.69651 12.75 10.4865C12.75 12.2765 11.29 13.7365 9.5 13.7365ZM9.5 8.73651C8.54 8.73651 7.75 9.52651 7.75 10.4865C7.75 11.4465 8.54 12.2365 9.5 12.2365C10.46 12.2365 11.25 11.4465 11.25 10.4865C11.25 9.52651 10.46 8.73651 9.5 8.73651Z"
					fill={color}
				/>
				<path
					d="M13.0004 18.7365C12.8104 18.7365 12.6204 18.6665 12.4704 18.5165C12.1804 18.2265 12.1804 17.7465 12.4704 17.4565L16.4704 13.4565C16.7604 13.1665 17.2404 13.1665 17.5304 13.4565C17.8204 13.7465 17.8204 14.2265 17.5304 14.5165L13.5304 18.5165C13.3804 18.6665 13.1904 18.7365 13.0004 18.7365Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
