import { Controller, useForm } from "react-hook-form"
import { FormWrapper } from "../../../../../../newComponents/Form/FormWrapper"
import InputText from "../../../../../../newComponents/Inputs/InputText/InputText";
import { useEffect } from "react";
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useGetTerminalsListQuery } from "../../../../../../store/newApi/terminals/terminals";
import { useNavigate } from "react-router-dom";

type OrgFormProps = {
    data: {
        organization_address: string;
        organization_name: string;
        inn: string;
        terminals: { id: number; name: string }[]
    }
}

export const OrgForm = ({ data }: OrgFormProps) => {
    const methods = useForm({
        defaultValues: {
            organization_address: "",
            organization_name: "",
            inn: "",
            terminalIds: [],
        }
    });
    const { control, setValue, watch } = methods;

    const selectedTerminals = watch('terminalIds');

    const { terminalOptions } = useGetTerminalsListQuery({}, {
        selectFromResult: ({ data }) => ({
            terminalOptions: data?.map((item) => ({
				value: item.id,
				label: item.name,
			})) || []
        })
    });

    useEffect(() => {
        if (data) {
            setValue('organization_name', data.organization_name);
            setValue('organization_address', data.organization_address);
            setValue('inn', data.inn);
            setValue('terminalIds', data.terminals.map(t => t.id));
        }
    }, [data]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
            <FormWrapper onSubmit={() => { }} {...methods}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', flexWrap: 'wrap' }}>
                    <Controller
                        name="organization_name"
                        control={control}
                        render={({ field }) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
                                <InputText {...field} disabled labelText="Название" placeholder="Название" />
                            </div>
                        )}
                    />
                    <Controller
                        name="inn"
                        control={control}
                        render={({ field }) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
                                <InputText {...field} disabled labelText="ИНН" placeholder="ИНН" />
                            </div>
                        )}
                    />
                    <Controller
                        name="organization_address"
                        control={control}
                        render={({ field }) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
                                <InputText {...field} disabled labelText="Адрес" placeholder="Адрес" />
                            </div>
                        )}
                    />
                </div>
                <div style={{ marginTop: '8px' }}>
                    <Typography variant="body1">Теминалы</Typography>
                    <FormGroup>
                        {terminalOptions.map((terminal) => (
                            <FormControlLabel
                                key={terminal.value}
                                control={
                                    <Checkbox
                                        checked={selectedTerminals.includes(terminal.value)}
                                        disabled
                                        sx={{
                                            width: '24px',
                                            height: '24px',
                                            padding: '20px',
                                            color: '#5C53A7',
                                            '&.Mui-checked': {
                                                color: '#5C53A7',
                                            },
                                        }}
                                    />
                                }
                                label={terminal.label}
                            />
                        ))}
                    </FormGroup>
                </div>
            </FormWrapper>
        </div>
    )
}