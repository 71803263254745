import { useEffect, useState, forwardRef } from 'react';
import { Autocomplete, TextField, Button, Box, InputAdornment } from '@mui/material';
import './VehicleInputAutocomplete.scss';
import {
	useCreateVehicleMutation,
	useLazyGetVahicleByIdQuery,
	useLazyGetVehiclesListQuery,
} from '../../../../store/newApi/vehicles/vehicles';
import { ArrowForwardIos } from '@mui/icons-material';

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
	return (
		<div className="autocomplete-popper" {...props}>
			<ul className="autocomplete-listbox" {...props} ref={ref} />
		</div>
	);
});

export const VehicleInputAutocomplete = ({ value, onChange }) => {
	const [fetchVehicles, { data = [], isLoading, isSuccess }] = useLazyGetVehiclesListQuery();
	const [fetchVehicleById] = useLazyGetVahicleByIdQuery();
	const [create, { isSuccess: isCreateSuccess }] = useCreateVehicleMutation();
	const [options, setOptions] = useState([]);
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [openList, setOpenList] = useState(false);

	useEffect(() => {
		const fetchVehicleDetails = async () => {
			if (value) {
				const vehicleData = await fetchVehicleById({ id: value }).unwrap();
				if (vehicleData) {
					setSelectedVehicle(vehicleData);
					setInputValue(vehicleData.registration_number);
				}
			}
		};
		fetchVehicleDetails();
	}, [value, fetchVehicleById]);

	useEffect(() => {
		fetchVehicles({ page: 1, page_size: 30 });
	}, [fetchVehicles]);

	useEffect(() => {
		if (isSuccess && openList) {
			setOptions(data.results);
		}
	}, [isSuccess, data, openList]);

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	useEffect(() => {
		if (inputValue) {
			fetchVehicles({ page: 1, page_size: 30, search: inputValue });
		} else {
			fetchVehicles({ page: 1, page_size: 30 });
		}
	}, [inputValue]);

	const handleOptionChange = (event, newValue) => {
		setSelectedVehicle(newValue);
		onChange(newValue);
	};

	const handleIfNoOptions = async () => {
		const createdVehicle = await create({ registration_number: inputValue }).unwrap();
		if (createdVehicle) {
			setOptions((prevOptions) => [...prevOptions, createdVehicle]);
			setSelectedVehicle(createdVehicle);
			onChange(createdVehicle);
		}
	};

	const handleInputClick = () => {
		setOpenList(true);
	};

	const handleCloseList = () => {
		setOpenList(false);
	};

	return (
		<div className="autocomplete-wrapper">
			<Autocomplete
				multiple={false}
				value={selectedVehicle}
				onChange={handleOptionChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				open={openList}
				onOpen={handleInputClick}
				onClose={handleCloseList}
				getOptionLabel={(option) => (option ? option.registration_number : '')}
				options={options}
				loading={isLoading}
				loadingText={<Box className="loading-text">Загрузка...</Box>}
				noOptionsText={
					openList &&
					isSuccess &&
					options.length === 0 && (
						<Box sx={{ padding: '8px' }}>
							<Button color="primary" variant="contained" onClick={handleIfNoOptions}>
								Добавить автомобиль
							</Button>
						</Box>
					)
				}
				PopperComponent={ListboxComponent}
				renderOption={(props, option) => (
					<li className="autocomplete-option" {...props}>
						{option.registration_number}
					</li>
				)}
				renderInput={(params) => (
					<TextField
						className={selectedVehicle ? 'autocomplete-input selected' : 'autocomplete-input'}
						{...params}
						placeholder="Выберите автомобиль"
						onClick={handleInputClick}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputAdornment position="end">
									<ArrowForwardIos
										style={{
											width: 16,
											height: 16,
											color: (theme) => theme.palette.text.secondary,
											transform: openList ? 'rotate(90deg)' : 'rotate(0deg)',
											transition: 'transform 0.3s ease',
											cursor: 'pointer',
										}}
									/>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</div>
	);
};
