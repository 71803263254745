import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CollapseBlock } from '../../CollapseBlock/CollapseBlock';
import InputText from '../../Inputs/InputText/InputText';
import { DrawerModal } from '../../DrawerModal/DrawerModal';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormWrapper } from '../FormWrapper';
import { useHandleCreate } from './hooks/useHandleCreate';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { ClientInputAutocompleteController } from '../Fields/ClientInputAutocompleteController';
import { useInitFormState } from './hooks/useInitFormState';
import { isClient } from '../../../shared/auth/isClient';
import { DateInput } from '../../Inputs/DateInput/DateInput';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';

const createSchema = z.object({
	containerNumber: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	client: isClient() ? z.number().optional() : z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	free_days_storage: z.string().optional(),
	total_price: z.string().optional(),
	client_tariff: isClient()
		? z.string().optional()
		: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	date_from: z.string().optional(),
	date_to: z.string().optional(),
});

const updateSchema = z.object({
	containerNumber: z.number().optional(),
	client: z.number().optional(),
	free_days_storage: z.string().optional(),
	client_tariff: z.string().optional(),
	date_from: z.string().optional(),
	date_to: z.string().optional(),
});

export const StorageForm = ({ isOpen, handleClose, entityIds = [] }) => {
	const isCreate = useMemo(() => entityIds.length === 0, [entityIds]);
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);

	const methods = useForm({
		defaultValues: {
			containerNumber: undefined,
			client: undefined,
			free_days_storage: undefined,
			total_price: undefined,
			client_tariff: undefined,
			date_from: undefined,
			date_to: undefined,
		},
		resolver: resolver,
	});

	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods, handleClose });
	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods, entityIds, handleClose });
	useInitFormState({ ...methods, ids: entityIds, isReady: isOpen });

	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = methods;
	console.log(errors);

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) handleCreate(value);
			else handleUpdate(value);
		},
		[handleCreate, handleUpdate, isCreate],
	); 

	const selectClientId = watch('client'); 

	return (
		<DrawerModal
			isOpen={isOpen}
			handleClose={handleClose}
			actions={
				<Button isLoading={isCreateLoading || isUpdateLoading} onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
					Сохранить
				</Button>
			}
		>
			<Typography variant="subtitle1">{isCreate ? 'Добавление' : 'Редактирование'}</Typography>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				{isCreate && <ClientInputAutocompleteController />}
				{isCreate && (
					<CollapseBlock defaultOpen={!!errors.containerNumber} title={<span>№ Контейнера</span>}>
						<Controller
							name="containerNumber"
							control={control}
							render={({ field }) => (
								<>
									<ContainerInputAutocomplete
										{...field}
										disabled={!selectClientId}
										searchParams={selectClientId && { client_id: selectClientId }}
									/>
									{errors?.containerNumber && (
										<Typography color="error" variant="caption">
											{errors?.containerNumber?.message}
										</Typography>
									)}
								</>
							)}
						/>
					</CollapseBlock>
				)}
				{isCreate && (
					<CollapseBlock title="Тариф клиента">
						<Controller
							name="client_tariff"
							control={control}
							render={({ field }) => <InputText {...field} type="number" placeholder="Тариф клиента" />}
						/>
					</CollapseBlock>
				)}
				{!isCreate && (
					<CollapseBlock title="Стоимость">
						<Controller
							name="total_price"
							control={control}
							render={({ field }) => <InputText {...field} type="number" placeholder="Стоимость" />}
						/>
					</CollapseBlock>
				)}
				<CollapseBlock title="Бесплатные дни">
					<Controller
						name="free_days_storage"
						control={control}
						render={({ field }) => <InputText {...field} type="number" placeholder="Бесплатные дни" />}
					/>
				</CollapseBlock>
				<CollapseBlock title="Дата начала">
					<Controller
						name="date_from"
						control={control}
						render={({ field }) => (
							<DateInput {...field} onChange={(value) => setValue('date_from', value, { shouldTouch: true })} />
						)}
					/>
				</CollapseBlock>
				<CollapseBlock title="Дата окончания">
					<Controller
						name="date_to"
						control={control}
						render={({ field }) => (
							<DateInput {...field} onChange={(value) => setValue('date_to', value, { shouldTouch: true })} />
						)}
					/>
				</CollapseBlock>
			</FormWrapper>
		</DrawerModal>
	);
};
