import { FormProvider } from "react-hook-form"
import styles from './FormWrapper.module.css';

export const FormWrapper = ({ children, onSubmit, ...methods }) => {
    return (
        <FormProvider {...methods}>
            <form className={styles.containerForm} onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    )
}