export const IconArrowDown = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
				<path
					d="M5.29463 7.20537C5.13191 7.04265 4.86809 7.04265 4.70537 7.20537C4.54265 7.36809 4.54265 7.63191 4.70537 7.79463L5.29463 7.20537ZM10 12.5L9.70537 12.7946C9.86809 12.9573 10.1319 12.9573 10.2946 12.7946L10 12.5ZM15.2946 7.79463C15.4573 7.63191 15.4573 7.36809 15.2946 7.20537C15.1319 7.04265 14.8681 7.04265 14.7054 7.20537L15.2946 7.79463ZM4.70537 7.79463L9.70537 12.7946L10.2946 12.2054L5.29463 7.20537L4.70537 7.79463ZM10.2946 12.7946L15.2946 7.79463L14.7054 7.20537L9.70537 12.2054L10.2946 12.7946Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

