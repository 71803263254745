import { Button, ButtonProps } from "@mui/material"

type BtnType = ButtonProps & { text: string };

export type PageFooterActionsProps = {
    leftBtns?: BtnType[];
    rightBtns?: BtnType[];
}

export const PageFooterActions = ({ leftBtns = [], rightBtns = [] }: PageFooterActionsProps) => {
    return (
        <div style={{
            justifyContent: 'space-between',
            width: '100%',
            display: 'flex',
            padding: '5px 11px',
            position: 'sticky',
            bottom: 0,
            background: '#fff',
            zIndex: '1000',
            borderTop: '1px solid #ddd',
            maxHeight: '50px',
        }}>
            <div style={{ display: 'flex', gap: '5px' }}>
                {leftBtns.map(({ text, ...rest }) => (
                    <Button {...rest}>
                        {text}
                    </Button>
                ))}
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
                {rightBtns.map(({ text, ...rest }) => (
                    <Button {...rest}>
                        {text}
                    </Button>
                ))}
            </div>
        </div>
    )
}