import { useCallback, useEffect } from "react";
import { useCreateCargosMutation } from "../../../../store/newApi/cargos/cargos";

export const useHandleCreate = ({ handleClose, refetchData, ...methods }) => {
    const { reset: resetForm } = methods;
    const [createCargo, { isSuccess: isCreateSuccess, isLoading: isCreateLoading, reset: resetCreateCargoMutation }] = useCreateCargosMutation();

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
            resetCreateCargoMutation();
            resetForm();
            refetchData();
            // TODO: Вызвать нотификашку
        }

        return () => {
            resetForm();
        }
    }, [isCreateSuccess, handleClose, resetCreateCargoMutation, resetForm, refetchData]);

    const handleCreate = useCallback((value) => {
        console.log(value);
        createCargo({ ...value });
    }, [createCargo]);

    return {
        handleCreate,
        isCreateSuccess,
        isCreateLoading
    }
}