import api from '../../api.js';
 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';

export const groups = api.injectEndpoints({
	endpoints: (builder) => ({
		getGroupsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/erp/group/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getGroupsById: builder.query({
			query: (groupId) => { 

				return {
					url: `/api/erp/group/${groupId}`,
					method: 'GET',
				};
			},
		}),

		updateGroup: builder.mutation({
			query: ({ groupId, ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/group/${groupId}/update/`,
					method: 'PUT',
					body: { 
						...validatedData
					},
				}
			}
		}),
		createGroup: builder.mutation({
			query: ({ ...payload }) => { 
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/erp/group/create/`,
					method: 'POST',
					body: { 
						...validatedData
					},
				}
			}
		}),
	}),
});

export const { useGetGroupsListQuery, useLazyGetGroupsListQuery, useLazyGetGroupsByIdQuery, useCreateGroupMutation, useUpdateGroupMutation } = groups;
