export const IconSetting = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12.0002 23.6186C11.3302 23.6186 10.6502 23.4686 10.1202 23.1586L4.62023 19.9886C2.38023 18.4786 2.24023 18.2486 2.24023 15.8786V10.0986C2.24023 7.72857 2.37023 7.49857 4.57023 6.00857L10.1102 2.80857C11.1602 2.19857 12.8102 2.19857 13.8602 2.80857L19.3802 5.98857C21.6202 7.49857 21.7602 7.72857 21.7602 10.0986V15.8686C21.7602 18.2386 21.6302 18.4686 19.4302 19.9586L13.8902 23.1586C13.3502 23.4686 12.6702 23.6186 12.0002 23.6186ZM12.0002 3.85857C11.5802 3.85857 11.1702 3.93857 10.8802 4.10857L5.38023 7.28857C3.75023 8.38857 3.75023 8.38857 3.75023 10.0986V15.8686C3.75023 17.5786 3.75023 17.5786 5.42023 18.7086L10.8802 21.8586C11.4702 22.1986 12.5402 22.1986 13.1302 21.8586L18.6302 18.6786C20.2502 17.5786 20.2502 17.5786 20.2502 15.8686V10.0986C20.2502 8.38857 20.2502 8.38857 18.5802 7.25857L13.1202 4.10857C12.8302 3.93857 12.4202 3.85857 12.0002 3.85857Z"
					fill={color}
				/>
				<path
					d="M12 16.7385C9.93 16.7385 8.25 15.0585 8.25 12.9885C8.25 10.9185 9.93 9.23853 12 9.23853C14.07 9.23853 15.75 10.9185 15.75 12.9885C15.75 15.0585 14.07 16.7385 12 16.7385ZM12 10.7385C10.76 10.7385 9.75 11.7485 9.75 12.9885C9.75 14.2285 10.76 15.2385 12 15.2385C13.24 15.2385 14.25 14.2285 14.25 12.9885C14.25 11.7485 13.24 10.7385 12 10.7385Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
