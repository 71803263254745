import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetGroupsListQuery } from '../../../../store/newApi/groups/groups.js';
import Preloader from '../../../../components/Preloader/Preloader';
 
import { useDispatch, useSelector } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/createJob.js';
import { useNavigate } from 'react-router-dom';
import { JobCard } from '../../../../entities/job/ui/JobCard/JobCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplJobTitle() {
	const [trigger, { data, isLoading }] = useLazyGetGroupsListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const filters = useSelector((state) => state['createJobRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const navigate = useNavigate();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data];
				} else return [...prevData, ...data];
			});
			setFetching(false);
		}
	}, [data]);

	const dispatch = useDispatch();

	const handleNewTitle = () => {
		navigate('/accountEmpl/createJob/create');
	};

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const handleNavigate = (id) => {
		navigate(`/accountEmpl/createJob/${id}`);
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Должности'}
				btnDesktop={[
					<Button variant="contained" color="primary" size="medium" onClick={handleNewTitle}>
						Добавить должность
					</Button>,
				]}
				filter={<Filters enabled={false} onSetFilter={handleSetFilter} onResetFilter={handleResetFilter} />}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}> 
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop)} key={index} onClick={() => handleNavigate(card.id)}>
									<JobCard name={card?.name} />
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplJobTitle;
