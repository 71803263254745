export const IconMessageQuestion = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M8 22.3194C7.72 22.3194 7.42998 22.2494 7.16998 22.1094C6.59998 21.8094 6.25 21.2094 6.25 20.5694V19.1495C3.23 18.8395 1.25 16.6194 1.25 13.4394V7.43945C1.25 3.99945 3.56 1.68945 7 1.68945H17C20.44 1.68945 22.75 3.99945 22.75 7.43945V13.4394C22.75 16.8794 20.44 19.1894 17 19.1894H13.23L8.96997 22.0295C8.67997 22.2195 8.34 22.3194 8 22.3194ZM7 3.17944C4.42 3.17944 2.75 4.84944 2.75 7.42944V13.4295C2.75 16.0095 4.42 17.6795 7 17.6795C7.41 17.6795 7.75 18.0195 7.75 18.4295V20.5595C7.75 20.6895 7.83 20.7495 7.88 20.7795C7.93001 20.8095 8.03001 20.8395 8.14001 20.7695L12.59 17.8095C12.71 17.7295 12.86 17.6795 13.01 17.6795H17.01C19.59 17.6795 21.26 16.0095 21.26 13.4295V7.42944C21.26 4.84944 19.59 3.17944 17.01 3.17944H7Z"
					fill={color}
				/>
				<path
					d="M11.9998 12.1095C11.5898 12.1095 11.2498 11.7695 11.2498 11.3595V11.1495C11.2498 9.98954 12.0998 9.41952 12.4198 9.19952C12.7898 8.94952 12.9098 8.77953 12.9098 8.51953C12.9098 8.01953 12.4998 7.6095 11.9998 7.6095C11.4998 7.6095 11.0898 8.01953 11.0898 8.51953C11.0898 8.92953 10.7498 9.26953 10.3398 9.26953C9.92984 9.26953 9.58984 8.92953 9.58984 8.51953C9.58984 7.18953 10.6698 6.1095 11.9998 6.1095C13.3298 6.1095 14.4098 7.18953 14.4098 8.51953C14.4098 9.65953 13.5698 10.2295 13.2598 10.4395C12.8698 10.6995 12.7498 10.8695 12.7498 11.1495V11.3595C12.7498 11.7795 12.4098 12.1095 11.9998 12.1095Z"
					fill={color}
				/>
				<path
					d="M12 14.5995C11.58 14.5995 11.25 14.2595 11.25 13.8495C11.25 13.4395 11.59 13.0995 12 13.0995C12.41 13.0995 12.75 13.4395 12.75 13.8495C12.75 14.2595 12.42 14.5995 12 14.5995Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
