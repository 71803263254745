export const IconCubeScan = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M22 9.74756C21.59 9.74756 21.25 9.40756 21.25 8.99756V6.99756C21.25 4.41756 19.58 2.74756 17 2.74756H7C4.42 2.74756 2.75 4.41756 2.75 6.99756V8.99756C2.75 9.40756 2.41 9.74756 2 9.74756C1.59 9.74756 1.25 9.40756 1.25 8.99756V6.99756C1.25 3.55756 3.56 1.24756 7 1.24756H17C20.44 1.24756 22.75 3.55756 22.75 6.99756V8.99756C22.75 9.40756 22.41 9.74756 22 9.74756Z"
					fill={color}
				/>
				<path
					d="M17 22.7476H7C3.56 22.7476 1.25 20.4376 1.25 16.9976V14.9976C1.25 14.5876 1.59 14.2476 2 14.2476C2.41 14.2476 2.75 14.5876 2.75 14.9976V16.9976C2.75 19.5776 4.42 21.2476 7 21.2476H17C19.58 21.2476 21.25 19.5776 21.25 16.9976V14.9976C21.25 14.5876 21.59 14.2476 22 14.2476C22.41 14.2476 22.75 14.5876 22.75 14.9976V16.9976C22.75 20.4376 20.44 22.7476 17 22.7476Z"
					fill={color}
				/>
				<path
					d="M12.0006 13.0775C11.8706 13.0775 11.7406 13.0475 11.6206 12.9775L6.3206 9.91748C5.9606 9.70748 5.8406 9.24751 6.0506 8.88751C6.2606 8.52751 6.7206 8.40749 7.0706 8.61749L11.9906 11.4675L16.8806 8.63751C17.2406 8.42751 17.7006 8.55747 17.9006 8.90747C18.1106 9.26747 17.9806 9.7275 17.6306 9.9375L12.3706 12.9775C12.2606 13.0375 12.1306 13.0775 12.0006 13.0775Z"
					fill={color}
				/>
				<path
					d="M12 18.5175C11.59 18.5175 11.25 18.1775 11.25 17.7675V12.3275C11.25 11.9175 11.59 11.5775 12 11.5775C12.41 11.5775 12.75 11.9175 12.75 12.3275V17.7675C12.75 18.1775 12.41 18.5175 12 18.5175Z"
					fill={color}
				/>
				<path
					d="M12.0002 18.7476C11.4202 18.7476 10.8502 18.6176 10.3902 18.3676L7.19023 16.5876C6.23023 16.0576 5.49023 14.7876 5.49023 13.6876V10.2976C5.49023 9.2076 6.24023 7.93764 7.19023 7.39764L10.3902 5.61761C11.3102 5.10761 12.6902 5.10761 13.6102 5.61761L16.8102 7.39764C17.7702 7.92764 18.5102 9.1976 18.5102 10.2976V13.6876C18.5102 14.7776 17.7602 16.0476 16.8102 16.5876L13.6102 18.3676C13.1502 18.6176 12.5802 18.7476 12.0002 18.7476ZM11.1202 6.93761L7.92023 8.71758C7.43023 8.98758 6.99023 9.7476 6.99023 10.2976V13.6876C6.99023 14.2476 7.43023 14.9976 7.92023 15.2676L11.1202 17.0476C11.5802 17.2976 12.4202 17.2976 12.8802 17.0476L16.0802 15.2676C16.5702 14.9976 17.0102 14.2376 17.0102 13.6876V10.2976C17.0102 9.7376 16.5702 8.98758 16.0802 8.71758L12.8802 6.93761C12.4202 6.68761 11.5802 6.68761 11.1202 6.93761Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
