import { useEffect, useRef, useState } from 'react';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

function BtnDropdown({ mainButtonText, dropdownItems, ...rest }) {
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuWidth, setMenuWidth] = useState('auto');
	const buttonRef = useRef(null);
	const open = Boolean(anchorEl);

	useEffect(() => {
		if (buttonRef.current) {
			setMenuWidth(buttonRef.current.offsetWidth);
		}
	}, [anchorEl]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
			 	{...rest}
				ref={buttonRef}
				size={isDesktop ? "medium" : "small"}
				variant="contained"
				color="primary"
				className={`${open && 'actived'}`}
				onClick={handleClick}
				endIcon={
					<ArrowForwardIos
						style={{
							width: 12,
							height: 12,
							transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
							transition: 'transform 0.3s ease',
						}}
					/>
				}
			>
				{mainButtonText}
			</Button>
			<Menu
				elevation={0}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						minWidth: menuWidth,
					},
				}}
			>
				{dropdownItems.map(({ text, onClick, disabled = false }, index) => (
					<MenuItem
						disableRipple
						disabled={disabled}
						key={index}
						onClick={() => {
							onClick();
							handleClose();
						}}
					>
						{text}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
export default BtnDropdown;
