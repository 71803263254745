import { Controller, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormWrapper } from '../FormWrapper';
import InputText from '../../Inputs/InputText/InputText';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useInitFormState } from './hooks/useInitFormState';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHandleUpdate } from './hooks/useHandleUpdate';
import { useHandleCreate } from './hooks/useHandleCreate';
import { entriesForm } from './EntriesForm.style';
import { useNavigate } from 'react-router-dom';
import { ContainerInputAutocomplete } from '../Fields/ContainerInputAutocomplete';
import { useLazyGetContainerByIdQuery } from '../../../store/newApi/containers/containers';
import { isEmpl } from '../../../shared/auth/isEmpl';
import { isClient } from '../../../shared/auth/isClient';
import { VehicleInputAutocomplete } from '../Fields/VenicleInputAutoComplete/VehicleInputAutocomplete';
import { PAGE_ENTRIES } from '../../../shared/entries/pageUrls';

const createSchema = z.object({
	container_number: z.number({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	vehicle_driver: z.string().optional(),
	vehicle: z.any().optional(),
	vehicle_trailer_number: z.any().optional(),
	seal_number: z.any().optional(),
});

const updateSchema = z.object({
	container_number: z.string().optional(),
	vehicle_driver: z.string().optional(),
	vehicle: z.any().optional(),
	vehicle_trailer_number: z.any().optional(),
	seal_number:z.any().optional(),
});

export const EntriesForm = ({ isCreate = false, entry }) => {
	const resolver = useMemo(() => (isCreate ? zodResolver(createSchema) : zodResolver(updateSchema)), [isCreate]);
	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));
	const navigate = useNavigate();

	const methods = useForm({
		defaultValues: {
			container_number: undefined,
			vehicle_driver: undefined,
			vehicle: undefined,
			vehicle_trailer_number: undefined,
			seal_number: undefined,
		},
		resolver: resolver,
	});

	const { handleUpdate, isUpdateLoading } = useHandleUpdate({ ...methods });
	const { handleCreate, isCreateLoading } = useHandleCreate({ ...methods });

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods;

	const onSubmit = useCallback(
		(value) => {
			if (isCreate) {
				// handleCreate(value);
				console.log(value);
			} else {
				handleUpdate(value);
				navigate(PAGE_ENTRIES);
			} 
		},
		[handleCreate, handleUpdate, isCreate],
	);

	useInitFormState({ isCreate, ...methods });

	const [fetchContainer, { container, loading: loadingClients }] = useLazyGetContainerByIdQuery({
		selectFromResult: ({ data }) => ({
			container: data,
		}),
	});

	return (
		<FormWrapper onSubmit={onSubmit} {...methods}>
			<Grid container sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер контейнера</Typography>
					<Controller
						name="container_number"
						control={control}
						render={({ field }) =>
							isCreate ? (
								<ContainerInputAutocomplete {...field} />
							) : (
								<InputText {...field} disabled placeholder="Номер контейнера" />
							)
						}
					/>
				</Grid>

				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Водитель</Typography>
					<Controller
						name="vehicle_driver"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Водитель" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер прицепа</Typography>
					<Controller
						name="vehicle_trailer_number"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Номер прицепа" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер пломбы</Typography>
					<Controller
						name="seal_number"
						control={control}
						render={({ field }) => <InputText {...field} placeholder="Номер пломбы" />}
					/>
				</Grid>
				<Grid item sx={{ width: isDesktop ? 'calc(50% - 4px)' : '100%' }}>
					<Typography variant="body1">Номер автомобиля</Typography>
					<Controller
						name="vehicle"
						control={control}
						render={({ field }) => (
							<VehicleInputAutocomplete value={field.value} onChange={(newVehicle) => field.onChange(newVehicle?.id)} />
						)}
					/>
				</Grid>
			</Grid>
			<Box sx={entriesForm(isDesktop)}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => navigate(`${isEmpl() ? '/accountEmpl/entries' : isClient() ? '/accountClient/entries' : ''}`)}
				>
					Назад
				</Button>
				<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
					Сохранить
				</Button>
			</Box>
		</FormWrapper>
	);
};
