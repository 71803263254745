import styles from './TabInfo.module.css';
import { Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useHandleUpdate } from '../hooks/useHandleUpdate';
import { useInitFormState } from '../hooks/useInitFormState';
import { FormWrapper } from '../../../../../../../newComponents/Form/FormWrapper';
import InputText from '../../../../../../../newComponents/Inputs/InputText/InputText';
import { useGetPermissions } from '../../../../../../../shared/auth/hooks/useGetPermissions';
import { useCreateOrgClientMutation, useUpdateOrgClientMutation } from '../../../../../../../store/newApi/organizations/organizations';

const createSchema = z.object({
	full_name: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	password: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
	email: z.string({ required_error: 'Поле обязательно' }).min(1, { message: 'Поле обязательно' }),
});

const updateSchema = z.object({
	full_name: z.string().optional(),
	password: z.string().optional(),
	email: z.string().optional(),
});

export const OrgEmplForm = ({ data, onBack }) => {
	const { isClient, orgId } = useGetPermissions();

	const resolver = useMemo(() => (data ? zodResolver(updateSchema) : zodResolver(createSchema)), [data]);

	const methods = useForm({
		defaultValues: {
			full_name: '',
			password: '',
			email: '',
		},
		resolver: resolver,
	});

	const {
		handleSubmit,
        control,
		setValue,
		formState: { errors },
	} = methods;

	const [createOrgClient] = useCreateOrgClientMutation();
	const [updateOrgClient] = useUpdateOrgClientMutation();

	useEffect(() => {
		if (data) {
			if (data?.full_name) setValue('full_name', data.full_name);
			if (data?.email) setValue('email', data.email); 
		}
	}, [data, setValue]);

	const onSubmit = useCallback(
		(value) => {
			if (data) updateOrgClient({ userId: data.id, ...value }).then(() => onBack());
			if (!data) createOrgClient({ ...value, user_type: 2, is_confirmed: true, org: orgId }).then(() => onBack())
		},
		[data],
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', flexGrow: 1, justifyContent: 'space-between' }}>
			<FormWrapper onSubmit={onSubmit} {...methods}>
				<div className={styles.containerFormTemplate}>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="Email" placeholder="Введите email" />
								{errors?.email && (
									<Typography color="error" variant="caption">
										{errors?.email?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="full_name"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="ФИО" placeholder="ФИО" />
								{errors?.full_name && (
									<Typography color="error" variant="caption">
										{errors?.full_name?.message}
									</Typography>
								)}
							</div>
						)}
					/>
					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* @ts-ignore */}
								<InputText {...field} labelText="Пароль" placeholder="Пароль" />
								{errors?.password && (
									<Typography color="error" variant="caption">
										{errors?.password?.message}
									</Typography>
								)}
							</div>
						)}
					/>
				</div>
			</FormWrapper>
			<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
				<Button variant="outlined" color="primary" size="medium" onClick={onBack}>
					Назад
				</Button>
				{!data && <Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>Создать</Button>}
				{data && <Button variant="contained" color="primary" size="medium" onClick={handleSubmit(onSubmit)}>Сохранить</Button>}
			</div>
		</div>
	);
};
