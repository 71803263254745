import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Button } from '@mui/material';
import RequireCheckRights from '../../../../hoc/RequireCheckRights';
import { useNavigate } from 'react-router-dom';
import { CREATE_INSPECTION_DELIVERY_URL, CREATE_INSPECTION_RECIEV_URL } from '../../../../shared/inspection/pageUrls';
import { useDispatch } from 'react-redux';
import { changepostEntryreports_success } from '../../../../store/slices/containerAcceptanceSlice';

function AccountEmplMain() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div className={styles.account__wrap}>
			<Header text={'Главная'} />
			<div className={styles.account__container}>
				<div className={styles.account__stack}>
					<div className={styles.account__stackBtns}>
						<RequireCheckRights path="entries" type="view">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									navigate('/accountEmpl/entries');
								}}
							>
								Список пропусков
							</Button>
						</RequireCheckRights>

						<RequireCheckRights path="inspection" type="add">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									navigate(CREATE_INSPECTION_RECIEV_URL);
									dispatch(changepostEntryreports_success(false));
								}}
							>
								Добавить акт осмотра на прием
							</Button>
						</RequireCheckRights>

						<RequireCheckRights path="inspection" type="add">
							<Button variant="contained" color="primary" onClick={() => navigate(CREATE_INSPECTION_DELIVERY_URL)}>
								Добавить акт осмотра на выдачу
							</Button>
						</RequireCheckRights>

						<RequireCheckRights path="repair" type="add">
							<Button variant="contained" color="primary" onClick={() => navigate('/accountEmpl/createRepair')}>
								Добавить акт ремонта
							</Button>
						</RequireCheckRights>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AccountEmplMain;
