import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ORDER_INFO_URL } from "../../../../../../../shared/orders/pageUrls";
import { TerminalInputAutocomplete } from "../../../../../../../newComponents/Form/Fields/TerminalInputAutocomplete";
import { ClientInputAutocomplete } from "../../../../../../../newComponents/Form/Fields/ClientInputAutocomplete";
import { useCreateOrderMutation } from "../../../../../../../store/newApi/orders/orders";
import { isClient } from "../../../../../../../shared/auth/isClient";
import { PageFooterActions } from "../../../../../../../shared/ui/PageFooterActions/PageFooterActions";

export const ChooseTerminalStep = ({ onBack, orderType, defaultOrderData }) => {
    const isClientAuth = isClient();
    const navigate = useNavigate();
    const [createOrder, { data, isLoading, isSuccess, reset: resetCreateOrder }] = useCreateOrderMutation();
    const [terminalId, setTerminalId] = useState(null);
    const [clientId, setClientId] = useState(null);

    const disabledNext = useMemo(() => (!isClientAuth && (!terminalId || !clientId)) || (isClientAuth && !terminalId), [terminalId, clientId, isClientAuth]);
    const isCreateNewFlow = useMemo(() => !defaultOrderData, [defaultOrderData]);

    const onHandleCreateOrder = useCallback(() => {
        createOrder({ terminalId, status: 0, org_id: clientId, order_type: +orderType })
    }, [terminalId, clientId, orderType, createOrder]);

    const handleGoNext = useCallback(() => {
        if (data && data.terminal.id && data.id)
            navigate(ORDER_INFO_URL(data.terminal.id, data.id, 1))
    }, [data, navigate]);

    const onHandleContinueBtn = useCallback(() => {
        if (isCreateNewFlow)
            onHandleCreateOrder()
        else
            navigate(ORDER_INFO_URL(defaultOrderData?.terminal?.id, defaultOrderData?.id, 1))
    }, [isCreateNewFlow, defaultOrderData, onHandleCreateOrder]);

    useEffect(() => {
        if (!!defaultOrderData) {
            setTerminalId(defaultOrderData?.terminal?.id);
            setClientId(defaultOrderData?.client?.id);
        }
    }, [defaultOrderData]);

    useEffect(() => {
        if (isSuccess) {
            handleGoNext()
            resetCreateOrder();
        }
    }, [isSuccess, resetCreateOrder, handleGoNext]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            flexGrow: 1,
            justifyContent: 'space-between',
        }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', maxWidth: '500px', padding: '10px' }}>
                <TerminalInputAutocomplete labelText='Терминал' disabled={!isCreateNewFlow} value={terminalId} onChange={(value) => setTerminalId(value)} />
                {!isClientAuth && <ClientInputAutocomplete labelText='Клиент' disabled={!isCreateNewFlow} value={clientId} onChange={(value) => setClientId(value)} />}
            </div>
            <PageFooterActions
                leftBtns={[
                    {
                        text: 'Отменить создание',
                        variant: 'outlined',
                        color: 'primary',
                        size: 'medium',
                        onClick: onBack
                    }
                ]}
                rightBtns={[
                    {
                        text: 'Продолжить',
                        variant: 'contained',
                        color: 'primary',
                        size: 'medium',
                        disabled: disabledNext,
                        onClick: onHandleContinueBtn
                    }
                ]}
            />
        </div>
    )
}