import React, { useEffect, useRef, useState } from 'react';
import styles from '../AccountEmpl.module.scss';
import Header from '../../../../newComponents/Header/Header';
import { Grid, Container, Box, Button } from '@mui/material';
import { account, accountGridItemThird } from './Account.styles.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyGetTransportersListQuery } from '../../../../store/newApi/organizations/organizations.js';
import Preloader from '../../../../components/Preloader/Preloader';
 
import { useSelector, useDispatch } from 'react-redux';
import { resetFilter, setFilter } from '../../../../store/rtkSlices/transporters.js';
import { useGetTerminalsListQuery } from '../../../../store/newApi/terminals/terminals.js';
import { FILTER_TYPES } from '../../../../config/filter';
import { Link, useNavigate } from 'react-router-dom';
import { PAGE_TRANSPORTER_BY_ID } from '../../../../shared/transporters/pageUrls';
import { isEmpl } from '../../../../shared/auth/isEmpl';
import RequireCheckRights from '../../../../hoc/RequireCheckRights';
import { isClient } from '../../../../shared/auth/isClient';
import { TransporterCard } from '../../../../entities/transporter/ui/TransporterCard/TransporterCard';
import { Filters } from '../../../../shared/ui/Filters/Filters';

function AccountEmplTransporters() {
	const dispatch = useDispatch();
	const [trigger, { data, isLoading }] = useLazyGetTransportersListQuery({});
	const [dataCards, setDataCards] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const containerRef = useRef(null);
	const [fetching, setFetching] = useState(false);
	const filters = useSelector((state) => state['transportersRtk']?.filter) ?? {};
	const [filtering, setFiltering] = useState(false);
	const navigate = useNavigate(); 

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'));

	// Самая первая загрузка
	useEffect(() => {
		trigger({ page: 1, page_size: 20 });
		setCurrentPage(1);
	}, []);

	useEffect(() => {
		const { page, page_size, ...restFilters } = filters;
		if (Object.keys(restFilters).length === 0) return;
		trigger({ ...filters, page: 1, page_size: 20, uniq: Math.random() });
		setCurrentPage(1);
		setFiltering(true);
	}, [filters]);

	// Infinite scroll
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current && !fetching && !isLoading) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

				if (scrollTop + clientHeight >= scrollHeight - 5) {
					if (data?.count > dataCards?.length) {
						setFetching(true);
						const nextPage = currentPage + 1;
						trigger({ ...filters, page: nextPage, page_size: 20 });
						setCurrentPage(nextPage);
					}
				}
			}
		};

		const container = containerRef.current;
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, fetching, isLoading, data, dataCards]);

	// Добавление подгруженных данных после скролла
	useEffect(() => {
		if (data && data.results) {
			setDataCards((prevData) => {
				if (filtering) {
					setFiltering(false);
					return [...data.results];
				} else return [...prevData, ...data.results];
			});
			setFetching(false);
		}
	}, [data]); 

	const handleSetFilter = (filters) => {
		dispatch(setFilter(filters));
	};

	const handleResetFilter = () => {
		dispatch(resetFilter());
	};

	const { data: dataTerminals } = useGetTerminalsListQuery({});
	const [elements, setElements] = useState([]);

	useEffect(() => {
		if (Array.isArray(dataTerminals)) {
			const newElements = dataTerminals.map((item) => ({
				value: item.id,
				label: item.name,
			}));
			setElements(newElements);
		}
	}, [dataTerminals]);

	const handleNewOrg = () => {
		navigate('/accountEmpl/transporters/create');
	};

	return (
		<div className={styles.account__wrap}>
			<Header
				text={'Перевозчики'}
				btnDesktop={[
					isEmpl() && (
						<RequireCheckRights path="transporters" type="add">
							<Button variant="contained" color="primary" size="medium" onClick={handleNewOrg}>
								Добавить перевозчика
							</Button>
						</RequireCheckRights>
					),
					isClient() && (
						<Button variant="contained" color="primary" size="medium" onClick={handleNewOrg}>
							Добавить перевозчика
						</Button>
					),
				]}
				btnMobile={[
					isEmpl() && (
						<RequireCheckRights path="transporters" type="add">
							<Button variant="contained" color="primary" size="small" onClick={handleNewOrg}>
								Добавить
							</Button>
						</RequireCheckRights>
					),
					isClient() && (
						<Button variant="contained" color="primary" size="small" onClick={handleNewOrg}>
							Добавить
						</Button>
					),
				]}
				filter={
					<Filters
						onSetFilter={handleSetFilter}
						onResetFilter={handleResetFilter}
						parameters={[
							{
								name: 'terminal',
								title: 'Терминал',
								type: FILTER_TYPES.INPUTDROPDOWN,
								elements: elements,
							},
							{
								name: ['created_at_after', 'created_at_before'],
								title: 'Дата создания',
								type: FILTER_TYPES.INPUTDATERANGE,
								elements: [],
							},
						]}
					/>
				}
			/>
			<div className={styles.account__container}>
				<Container ref={containerRef} className={styles.account__collection} sx={account.container}> 
					{isLoading ? (
						<Box sx={account.preloader}>
							<Preloader />
						</Box>
					) : (
						<Grid container>
							{dataCards?.map((card, index) => (
								<Grid item sx={accountGridItemThird(isDesktop)} key={index}>
									<Link to={PAGE_TRANSPORTER_BY_ID(card?.id)}>
										<TransporterCard
											name={card?.organization_name}
											inn={card?.inn}
											email={card?.contact_email}
										/>
									</Link>
								</Grid>
							))}
						</Grid>
					)}
				</Container>
			</div>
		</div>
	);
}

export default AccountEmplTransporters;
