import { useEffect, useMemo } from "react";
import { useGetCargoByIdQuery } from "../../../../store/newApi/cargos/cargos";

export const useInitFormState = ({ ids, isReady, ...methods }) => {
    const { setValue } = methods;

    const isSingleUpdate = useMemo(() => ids.length === 1, [ids]);
    
    const { data } = useGetCargoByIdQuery({ id: ids[0] }, {
        skip: !isSingleUpdate,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (data && isReady && isSingleUpdate) {
            setValue('cell', data.cell)
            setValue('gng_code', data.gng_code)
            setValue('cargo', data.cargo)
            setValue('package', data.package)
            setValue('client', data.client)
            setValue('package_count', `${data.package_count}`)
            setValue('is_pallet', data.is_pallet)
            setValue('gross', `${data.gross}`)
            setValue('terminal', data.terminal)
            setValue('eta_checkout', data.eta_checkout)
        }
    }, [data, setValue, isReady, isSingleUpdate]);
}