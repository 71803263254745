export const IconClipboardText = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M15 12.9485H8C7.59 12.9485 7.25 12.6085 7.25 12.1985C7.25 11.7885 7.59 11.4485 8 11.4485H15C15.41 11.4485 15.75 11.7885 15.75 12.1985C15.75 12.6085 15.41 12.9485 15 12.9485Z"
					fill={color}
				/>
				<path
					d="M12.38 16.9485H8C7.59 16.9485 7.25 16.6085 7.25 16.1985C7.25 15.7885 7.59 15.4485 8 15.4485H12.38C12.79 15.4485 13.13 15.7885 13.13 16.1985C13.13 16.6085 12.79 16.9485 12.38 16.9485Z"
					fill={color}
				/>
				<path
					d="M14 6.74854H10C9.04 6.74854 7.25 6.74854 7.25 3.99854C7.25 1.24854 9.04 1.24854 10 1.24854H14C14.96 1.24854 16.75 1.24854 16.75 3.99854C16.75 4.95854 16.75 6.74854 14 6.74854ZM10 2.74854C9.01 2.74854 8.75 2.74854 8.75 3.99854C8.75 5.24854 9.01 5.24854 10 5.24854H14C15.25 5.24854 15.25 4.98854 15.25 3.99854C15.25 2.74854 14.99 2.74854 14 2.74854H10Z"
					fill={color}
				/>
				<path
					d="M15 22.7486H9C3.38 22.7486 2.25 20.1686 2.25 15.9986V9.99859C2.25 5.43859 3.9 3.48859 7.96 3.27859C8.36 3.25859 8.73 3.56859 8.75 3.98859C8.77 4.40859 8.45 4.74859 8.04 4.76859C5.2 4.92859 3.75 5.77859 3.75 9.99859V15.9986C3.75 19.6986 4.48 21.2486 9 21.2486H15C19.52 21.2486 20.25 19.6986 20.25 15.9986V9.99859C20.25 5.77859 18.8 4.92859 15.96 4.76859C15.55 4.74859 15.23 4.38859 15.25 3.97859C15.27 3.56859 15.63 3.24859 16.04 3.26859C20.1 3.48859 21.75 5.43859 21.75 9.98859V15.9886C21.75 20.1686 20.62 22.7486 15 22.7486Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
