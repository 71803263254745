 import { buildQueryString } from '../../../shared/ui/Filters/utils.js';
import { getValidatedFields } from '../../../shared/api/getValidatedFields.js';
import api from '../../api.js';

export const terminals = api.injectEndpoints({
	endpoints: (builder) => ({
		getTerminalsList: builder.query({
			query: (params) => {
				let queryParams = { ...params };

				delete queryParams.uniq;

				return {
					url: `/api/terminal/list/?${buildQueryString(queryParams)}`,
					method: 'GET',
				};
			},
		}),
		getTerminalDetail: builder.query({
			query: (terminalId) => {
				return {
					url: `/api/terminal/${terminalId}/detail`,
					method: 'GET',
				};
			},
		}),

		updateTerminal: builder.mutation({
			query: ({ terminalId, ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/terminal/${terminalId}/update/`,
					method: 'PUT',
					body: {
						...validatedData,
					},
				};
			},
		}),
		createTerminal: builder.mutation({
			query: ({ ...payload }) => {
				const validatedData = getValidatedFields(payload);
				return {
					url: `/api/terminal/create/`,
					method: 'POST',
					body: {
						...validatedData,
					},
				};
			},
		}),
	}),
});

export const {
	useGetTerminalsListQuery,
	useLazyGetTerminalsListQuery,
	useLazyGetTerminalDetailQuery,
	useUpdateTerminalMutation,
	useCreateTerminalMutation,
} = terminals;
