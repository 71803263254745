import React from 'react';
import styles from './SideStyle.module.scss';
import UniversalBlock from '../components/UniversalBlock';
import { useSelector } from 'react-redux';
import { isBlockChoosen } from './helpers/isBlockChoosen';
import { sideTypeToName } from './helpers/sideTypeToName';
import { panelNameToPanelAvailableCodes } from './helpers/panelNameToPanelAvailableCodes';

function FrontEnd({ feet, selectedSide, setSmthSelected, selectedAreas, selectedDamageDetails=[], damagesList = [] }) {
	// const { damagesList, selectedSide } = useSelector((state) => state.containerAccept);
	// const { placement_code_description } = useSelector((state) => state.containerAccept.selectedSide);

	const { placement_code_description, code, components } = selectedSide;

	return (
		<div className={styles.mainBlock_new}>
			<div className={styles.mapRowNumber_new}>
				<p className={styles.topNumber_new}></p> {/* Empty cell for alignment */}
				{placement_code_description[0].parts
					.slice()
					.reverse()
					.map((part) => (
						<p key={part} className={styles.topNumber_new}>
							{part}
						</p>
					))}
			</div>
			{placement_code_description.map(({ option, parts }) => (
				<div key={option} className={styles.mapRow_new}>
					<p className={styles.sideLabel_new}>{option}</p>
					{parts
						.slice()
						.reverse()
						.map((part, index) => (
							<UniversalBlock
								key={index}
								type={getType(option, part)}
								sideIndex={code}
								sideName={sideTypeToName(code)}
								divider={option}
								part={part.toString()}
								partCode={panelNameToPanelAvailableCodes(components, code, option, part)}
								choosen={isBlockChoosen(damagesList, code, option, part.toString())}
								onClick={setSmthSelected}
								selectedAreas={selectedAreas}
							/>
						))}
				</div>
			))}
		</div>
	);
}

function getType(option, part) {
	if (option === 'H' && part === 1) {
		return 'angular-HR';
	} else if (option === 'H' && part === 4) {
		return 'angular-HL';
	} else if (option === 'G' && part === 1) {
		return 'angular-BR';
	} else if (option === 'G' && part === 4) {
		return 'angular-BL';
	} else if (option === 'H' && (part === 2 || part === 3)) {
		return `beam-horizontal-H`;
	} else if (option === 'G' && (part === 2 || part === 3)) {
		return `beam-horizontal-B`;
	} else if ((option === 'T' && part === 1) || (option === 'B' && part === 1)) {
		return 'beam-vertical-R';
	} else if ((option === 'T' && part === 4) || (option === 'B' && part === 4)) {
		return 'beam-vertical-L';
	} else {
		return 'mainPanel';
	}
}

export default FrontEnd;
