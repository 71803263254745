export const drawerModal = {
	wrapper: {
		position: 'relative',
		marginBottom: '8px',
	},

	drawer: {
		'& .MuiModal-backdrop': {
			backgroundColor: 'transparent',
		},
		'& .MuiDrawer-paper': {
			boxShadow: '0px 4px 4px 0px #00000040',
		},
	},

	content: {
		color: (theme) => theme.palette.text.secondary,
		width: '380px',
		height: 1,
		overflow: 'auto',

		'&::-webkit-scrollbar': {
			width: '3px',
		},

		'&::-webkit-scrollbar-track': {
			backgroundColor: '#ffdebc',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ff8e16',
		},
	},

	btnClose: {
		position: 'fixed',
		top: '12px',
		right: '388px',
		zIndex: '1301',
		width: '44px',
		height: '44px',
		borderRadius: '8px',
		border: '1px solid',
		borderColor: (theme) => theme.palette.border.primaryHover,
		backgroundColor: '#ffffff',

		'&:hover': {
			backgroundColor: '#ffffff',
		},
	},

	actions: {
		padding: '16px',
		position: 'sticky',
		bottom: '0',
		right: '0',
		backgroundColor: '#ffffff',
		boxShadow: '0px 4px 15px 0px #0000004D',
		display: 'flex',
		gap: '8px',
	},
};
