import { useCallback, useEffect } from 'react';
import { useLazyGetContainersListQuery } from '../../../store/newApi/containers/containers';
import { InputAutocompleteAsync } from '../../Inputs/InputAutocomplete/InputAutocompleteAsync';

export const ContainerInputAutocomplete = ({ value, onChange, searchParams, ...rest }) => {
	const [trigger, { containersOptions = [], loading: loadingClients }] = useLazyGetContainersListQuery({
		selectFromResult: ({ data }) => ({
			containersOptions: data?.results?.map((t) => ({ label: t.container_number, id: t.id })),
		}),
	});
	const handleSearch = useCallback(
		(value) => {
			trigger({ ...searchParams, search: value });
		},
		[trigger, searchParams],
	);
	useEffect(() => {
		trigger({ ...searchParams });
	}, [searchParams, trigger])

	return (
		<div>
			<InputAutocompleteAsync
				{...rest}
				value={value}
				fetchFn={handleSearch}
				placeholder="Выберите или введите вручную"
				options={containersOptions}
				loading={loadingClients}
				onChange={onChange}
			/>
		</div>
	);
};
