import { useMemo } from "react";
import { isClient } from "../../../../auth/isClient"
import { formatInitialDate } from "../../../../../helpers/formatDate";
import { LINK_CLIENT_BY_ID } from "../../../../clients/pageUrls";

export const useGetContainerTableColumns = () => {
    const isClientUser = isClient();

	const columnsList = useMemo(() => {
		const baseColumns = [
			{
				accessorFn: (row) => row.status_display,
				id: 'status',
				header: 'Статус',
				size: 192,
			},
			{
				accessorFn: (row) => row.line_display,
				id: 'line',
				header: 'Линия',
				size: 232,
			},
			{
				accessorFn: (row) => row.container_number,
				id: 'container_number',
				header: '№ контейнера',
				size: 230,
			},
			{
				accessorFn: (row) => row.size_display,
				id: 'size',
				header: 'Размер',
				size: 200,
			},
			{
				accessorFn: (row) => row.type_display,
				id: 'type',
				header: 'Тип',
				size: 180,
			},
			{
				accessorFn: (row) => row.booking,
				id: 'booking',
				header: 'Букинг',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_filled ? 'Да' : '-'),
				id: 'is_filled',
				header: 'Заполнен',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_dangerous ? 'Да' : '-'),
				id: 'is_dangerous',
				header: 'Опасный груз',
				size: 280,
			},
			{
				accessorFn: (row) => (row.is_damaged ? 'Да' : '-'),
				id: 'is_damaged',
				header: 'Поврежден',
				size: 280,
			},
			{
				accessorFn: (row) => row.terminal_display,
				id: 'terminal',
				header: 'Терминал',
				size: 230,
			},
			{
				accessorFn: (row) => row.created_at && formatInitialDate(row.created_at),
				id: 'created_at',
				header: 'Дата создания',
				size: 230,
			},
		]; 

		if (!isClientUser) {
			baseColumns.push({
				accessorFn: (row) => {
					const url = LINK_CLIENT_BY_ID(row.client);
					return (
						<a href={url} target="_blank" rel="noopener noreferrer nofollow">
							{row.client_display}
						</a>
					);
				},
				id: 'org',
				header: 'Клиент',
				size: 280,
                // @ts-ignore
				sortDescFirst: false,
			});
		}

		return baseColumns;
	}, []);

    const columnOrdering = [
        'mrt-row-select',
        'status',
        'line',
        'container_number',
        'size',
        'type',
        'org',
        'booking',
        'is_filled',
        'is_dangerous',
        'is_damaged',
        'terminal',
        'created_at',
    ];

    return { columnsList, columnOrdering };
}