export const IconElement3 = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M19.77 12.2257H15.73C13.72 12.2257 12.75 11.3357 12.75 9.49571V4.95571C12.75 3.11571 13.73 2.22571 15.73 2.22571H19.77C21.78 2.22571 22.75 3.11571 22.75 4.95571V9.48571C22.75 11.3357 21.77 12.2257 19.77 12.2257ZM15.73 3.72571C14.39 3.72571 14.25 4.10571 14.25 4.95571V9.48571C14.25 10.3457 14.39 10.7157 15.73 10.7157H19.77C21.11 10.7157 21.25 10.3357 21.25 9.48571V4.95571C21.25 4.09571 21.11 3.72571 19.77 3.72571H15.73Z"
					fill={color}
				/>
				<path
					d="M19.77 23.7257H15.73C13.72 23.7257 12.75 22.7457 12.75 20.7457V16.7057C12.75 14.6957 13.73 13.7257 15.73 13.7257H19.77C21.78 13.7257 22.75 14.7057 22.75 16.7057V20.7457C22.75 22.7457 21.77 23.7257 19.77 23.7257ZM15.73 15.2257C14.55 15.2257 14.25 15.5257 14.25 16.7057V20.7457C14.25 21.9257 14.55 22.2257 15.73 22.2257H19.77C20.95 22.2257 21.25 21.9257 21.25 20.7457V16.7057C21.25 15.5257 20.95 15.2257 19.77 15.2257H15.73Z"
					fill={color}
				/>
				<path
					d="M8.27 12.2257H4.23C2.22 12.2257 1.25 11.3357 1.25 9.49571V4.95571C1.25 3.11571 2.23 2.22571 4.23 2.22571H8.27C10.28 2.22571 11.25 3.11571 11.25 4.95571V9.48571C11.25 11.3357 10.27 12.2257 8.27 12.2257ZM4.23 3.72571C2.89 3.72571 2.75 4.10571 2.75 4.95571V9.48571C2.75 10.3457 2.89 10.7157 4.23 10.7157H8.27C9.61 10.7157 9.75 10.3357 9.75 9.48571V4.95571C9.75 4.09571 9.61 3.72571 8.27 3.72571H4.23Z"
					fill={color}
				/>
				<path
					d="M8.27 23.7257H4.23C2.22 23.7257 1.25 22.7457 1.25 20.7457V16.7057C1.25 14.6957 2.23 13.7257 4.23 13.7257H8.27C10.28 13.7257 11.25 14.7057 11.25 16.7057V20.7457C11.25 22.7457 10.27 23.7257 8.27 23.7257ZM4.23 15.2257C3.05 15.2257 2.75 15.5257 2.75 16.7057V20.7457C2.75 21.9257 3.05 22.2257 4.23 22.2257H8.27C9.45 22.2257 9.75 21.9257 9.75 20.7457V16.7057C9.75 15.5257 9.45 15.2257 8.27 15.2257H4.23Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
