export const IconPeople = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M18.0003 8.8936C17.9703 8.8936 17.9503 8.8936 17.9203 8.8936H17.8703C15.9803 8.8336 14.5703 7.37359 14.5703 5.57359C14.5703 3.73359 16.0703 2.24359 17.9003 2.24359C19.7303 2.24359 21.2303 3.74359 21.2303 5.57359C21.2203 7.38359 19.8103 8.8436 18.0103 8.90359C18.0103 8.89359 18.0103 8.8936 18.0003 8.8936ZM17.9003 3.7336C16.8903 3.7336 16.0703 4.5536 16.0703 5.5636C16.0703 6.5536 16.8403 7.3536 17.8303 7.3936C17.8403 7.3836 17.9203 7.3836 18.0103 7.3936C18.9803 7.3436 19.7303 6.5436 19.7403 5.5636C19.7403 4.5536 18.9203 3.7336 17.9003 3.7336Z"
					fill={color}
				/>
				<path
					d="M18.0105 16.2635C17.6205 16.2635 17.2305 16.2335 16.8405 16.1635C16.4305 16.0935 16.1605 15.7035 16.2305 15.2935C16.3005 14.8835 16.6905 14.6135 17.1005 14.6835C18.3305 14.8935 19.6305 14.6635 20.5005 14.0835C20.9705 13.7735 21.2205 13.3835 21.2205 12.9935C21.2205 12.6035 20.9605 12.2235 20.5005 11.9135C19.6305 11.3335 18.3105 11.1035 17.0705 11.3235C16.6605 11.4035 16.2705 11.1235 16.2005 10.7135C16.1305 10.3035 16.4005 9.91349 16.8105 9.84349C18.4405 9.55349 20.1305 9.86348 21.3305 10.6635C22.2105 11.2535 22.7205 12.0935 22.7205 12.9935C22.7205 13.8835 22.2205 14.7335 21.3305 15.3335C20.4205 15.9335 19.2405 16.2635 18.0105 16.2635Z"
					fill={color}
				/>
				<path
					d="M5.97047 8.89359C5.96047 8.89359 5.95047 8.89359 5.95047 8.89359C4.15047 8.83359 2.74047 7.37358 2.73047 5.57358C2.73047 3.73358 4.23047 2.23358 6.06047 2.23358C7.89047 2.23358 9.39047 3.73358 9.39047 5.56358C9.39047 7.37358 7.98047 8.83359 6.18047 8.89359L5.97047 8.14359L6.04047 8.89359C6.02047 8.89359 5.99047 8.89359 5.97047 8.89359ZM6.07047 7.39359C6.13047 7.39359 6.18047 7.39358 6.24047 7.40358C7.13047 7.36358 7.91047 6.56358 7.91047 5.57358C7.91047 4.56358 7.09047 3.74358 6.08047 3.74358C5.07047 3.74358 4.25047 4.56358 4.25047 5.57358C4.25047 6.55358 5.01047 7.34358 5.98047 7.40358C5.99047 7.39358 6.03047 7.39359 6.07047 7.39359Z"
					fill={color}
				/>
				<path
					d="M5.96 16.2636C4.73 16.2636 3.55 15.9336 2.64 15.3336C1.76 14.7436 1.25 13.8936 1.25 12.9936C1.25 12.1036 1.76 11.2536 2.64 10.6636C3.84 9.8636 5.53 9.55361 7.16 9.84361C7.57 9.91361 7.84 10.3036 7.77 10.7136C7.7 11.1236 7.31 11.4036 6.9 11.3236C5.66 11.1036 4.35 11.3336 3.47 11.9136C3 12.2236 2.75 12.6036 2.75 12.9936C2.75 13.3836 3.01 13.7736 3.47 14.0836C4.34 14.6636 5.64 14.8936 6.87 14.6836C7.28 14.6136 7.67 14.8936 7.74 15.2936C7.81 15.7036 7.54 16.0936 7.13 16.1636C6.74 16.2336 6.35 16.2636 5.96 16.2636Z"
					fill={color}
				/>
				<path
					d="M12.0003 16.3636C11.9703 16.3636 11.9503 16.3636 11.9203 16.3636H11.8703C9.98031 16.3036 8.57031 14.8436 8.57031 13.0436C8.57031 11.2036 10.0703 9.71356 11.9003 9.71356C13.7303 9.71356 15.2303 11.2136 15.2303 13.0436C15.2203 14.8536 13.8103 16.3136 12.0103 16.3736C12.0103 16.3636 12.0103 16.3636 12.0003 16.3636ZM11.9003 11.2036C10.8903 11.2036 10.0703 12.0236 10.0703 13.0336C10.0703 14.0236 10.8403 14.8236 11.8303 14.8636C11.8403 14.8536 11.9203 14.8536 12.0103 14.8636C12.9803 14.8136 13.7303 14.0136 13.7403 13.0336C13.7403 12.0336 12.9203 11.2036 11.9003 11.2036Z"
					fill={color}
				/>
				<path
					d="M11.9993 23.7436C10.7993 23.7436 9.5993 23.4336 8.6693 22.8036C7.7893 22.2136 7.2793 21.3736 7.2793 20.4736C7.2793 19.5836 7.7793 18.7236 8.6693 18.1336C10.5393 16.8936 13.4693 16.8936 15.3293 18.1336C16.2093 18.7236 16.7193 19.5636 16.7193 20.4636C16.7193 21.3536 16.2193 22.2136 15.3293 22.8036C14.3993 23.4236 13.1993 23.7436 11.9993 23.7436ZM9.4993 19.3936C9.0293 19.7036 8.7793 20.0936 8.7793 20.4836C8.7793 20.8736 9.0393 21.2536 9.4993 21.5636C10.8493 22.4736 13.1393 22.4736 14.4893 21.5636C14.9593 21.2536 15.2093 20.8636 15.2093 20.4736C15.2093 20.0836 14.9493 19.7036 14.4893 19.3936C13.1493 18.4836 10.8593 18.4936 9.4993 19.3936Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
