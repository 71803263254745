import zIndex from "@mui/material/styles/zIndex";

export const dateRangeInput = {
	textField: {
		backgroundColor: (theme) => theme.palette.background.input,
		borderRadius: '12px',
		border: '2px solid',
		borderColor: (theme) => theme.palette.border.primary,
		padding: '5px 16px',
		color: (theme) => theme.palette.text.secondary,
		transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
		input: {
			padding: '0px',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.08px',
			color: (theme) => theme.palette.text.placeholder,
		},

		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover': {
			backgroundColor: (theme) => theme.palette.background.inputHover,
			borderColor: (theme) => theme.palette.border.primaryHover,
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: (theme) => theme.palette.border.primaryFocus,
		},
		'& input::placeholder': {
			color: (theme) => theme.palette.text.placeholder,
			opacity: 1,
		},
	},
	calendar: {
		zIndex: 100,
		position: 'absolute',
		top: '45px',
		right: '0',
	},
	btnCalendar: {
		backgroundColor: '#5C53A7',
		borderRadius: '8px',
		width: '36px',

		'&:hover': {
			backgroundColor: '#5C53A7',
		},
	},
	valueTextField: {
		backgroundColor: (theme) => theme.palette.background.inputFocus,
		input: {
			padding: '0px',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '24px',
			letterSpacing: '0.08px',
			color: (theme) => theme.palette.text.secondary,
		},
	},
};
