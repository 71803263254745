import { Box, IconButton, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from "react";

export const CollapseBlock = ({ title, children, defaultOpen = false }) => {
    const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};
    useEffect(() => {
        if (defaultOpen)
            setIsOpen(defaultOpen);
    }, [defaultOpen]);
    return <>
        <Box sx={{ display: 'flex', alignItems: 'center', '&:hover': { cursor: 'pointer' } }} onClick={toggleOpen}>
            <Typography variant="alertTitle" style={{ flexGrow: 1 }}>
                {title}
            </Typography>
            <IconButton>{isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Box>
        {isOpen && children}
    </>
}