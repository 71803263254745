import { Typography, Box, Grid, Chip } from '@mui/material';
import { IconUser } from '../../../../components/Icons';
import { Card } from '../../../../shared/ui/Card/Card';

type EmplCardProps = {
	name: string;
	job: string;
	terminal: string;
	login: string;
};

export const EmplCard = ({ name, job, terminal, login }: EmplCardProps) => {
	return (
		<Card>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
					<Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
						<Grid
							container
							sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center', flexWrap: 'nowrap' }}
						>
							<Grid item>
								<Box
									sx={{
										backgroundColor: '#F5F7FA',
										borderRadius: '8px',
										minWidth: '48px',
										height: '48px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<IconUser size={'largeS'} color={'core-dark-60'} />
								</Box>
							</Grid>
							<Grid item>
								<Typography variant="subtitle1">{name}</Typography>
							</Grid>
						</Grid>

						<Grid item>
							<Chip label={job} />
						</Grid>
					</Box>

					<Grid container sx={{ gap: '12px' }}>
						<Grid item>
							<Chip label={`Терминал: ${terminal}`} />
						</Grid>
					</Grid>
					<Grid container sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
						<Grid item sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
							<Typography variant="body1">Логин:</Typography>
							<Typography variant="body2">{login}</Typography>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Card>
	);
};
