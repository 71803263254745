export const IconTag = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 25" fill="none">
				<path
					d="M12.0701 22.9655C10.6601 22.9655 9.24009 22.4255 8.17009 21.3555L3.64009 16.8255C2.54009 15.7255 1.96009 14.2055 2.03009 12.6555L2.27009 7.65553C2.38009 5.26553 4.27009 3.37553 6.67009 3.25553L11.6701 3.01553C13.2201 2.95553 14.7401 3.52553 15.8401 4.62553L20.3701 9.15553C22.5201 11.3055 22.5201 14.8155 20.3701 16.9655L15.9801 21.3555C14.9001 22.4255 13.4901 22.9655 12.0701 22.9655ZM4.70009 15.7555L9.23009 20.2855C9.99009 21.0455 11.0001 21.4655 12.0701 21.4655C13.1401 21.4655 14.1501 21.0455 14.9101 20.2855L19.3001 15.8955C20.0601 15.1355 20.4801 14.1255 20.4801 13.0555C20.4801 11.9855 20.0601 10.9755 19.3001 10.2155L14.7701 5.68553C13.9701 4.88553 12.8601 4.45553 11.7401 4.51553L6.74009 4.75553C5.11009 4.82553 3.84009 6.09553 3.76009 7.71553L3.52009 12.7155C3.47009 13.8455 3.90009 14.9555 4.70009 15.7555Z"
					fill={color}
				/>
				<path
					d="M9.5 13.7355C7.71 13.7355 6.25 12.2755 6.25 10.4855C6.25 8.69553 7.71 7.23553 9.5 7.23553C11.29 7.23553 12.75 8.69553 12.75 10.4855C12.75 12.2755 11.29 13.7355 9.5 13.7355ZM9.5 8.73553C8.54 8.73553 7.75 9.52553 7.75 10.4855C7.75 11.4455 8.54 12.2355 9.5 12.2355C10.46 12.2355 11.25 11.4455 11.25 10.4855C11.25 9.52553 10.46 8.73553 9.5 8.73553Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
