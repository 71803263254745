export const IconShieldSearch = ({ size, color }) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
				<path
					d="M12.0004 22.7444C10.8704 22.7444 9.79039 22.4144 8.98039 21.8044L4.68039 18.5944C3.54039 17.7444 2.65039 15.9744 2.65039 14.5544V7.11445C2.65039 5.57445 3.78039 3.93445 5.23039 3.39445L10.2204 1.52445C11.2104 1.15445 12.7704 1.15445 13.7604 1.52445L18.7604 3.39445C20.2104 3.93445 21.3404 5.57445 21.3404 7.11445V10.5444C21.3404 10.9544 21.0004 11.2944 20.5904 11.2944C20.1804 11.2944 19.8404 10.9544 19.8404 10.5444V7.11445C19.8404 6.20445 19.0904 5.12445 18.2304 4.79445L13.2404 2.92445C12.5804 2.67445 11.4104 2.67445 10.7504 2.92445L5.76039 4.80445C4.90039 5.12445 4.15039 6.20445 4.15039 7.12445V14.5544C4.15039 15.5044 4.82039 16.8344 5.57039 17.3944L9.87039 20.6044C10.4204 21.0144 11.1904 21.2444 11.9904 21.2444C12.4004 21.2444 12.7404 21.5844 12.7404 21.9944C12.7404 22.4044 12.4104 22.7444 12.0004 22.7444Z"
					fill={color}
				/>
				<path
					d="M16 20.7445C13.38 20.7445 11.25 18.6145 11.25 15.9945C11.25 13.3745 13.38 11.2445 16 11.2445C18.62 11.2445 20.75 13.3745 20.75 15.9945C20.75 18.6145 18.62 20.7445 16 20.7445ZM16 12.7545C14.21 12.7545 12.75 14.2145 12.75 16.0045C12.75 17.7945 14.21 19.2545 16 19.2545C17.79 19.2545 19.25 17.7945 19.25 16.0045C19.25 14.2145 17.79 12.7545 16 12.7545Z"
					fill={color}
				/>
				<path
					d="M21 21.9945C20.93 21.9945 20.87 21.9845 20.8 21.9745C20.74 21.9645 20.68 21.9445 20.62 21.9145C20.56 21.8945 20.5 21.8645 20.44 21.8245C20.39 21.7845 20.34 21.7445 20.29 21.7045C20.11 21.5145 20 21.2545 20 20.9945C20 20.8645 20.03 20.7345 20.08 20.6145C20.13 20.4945 20.2 20.3845 20.29 20.2845C20.66 19.9145 21.34 19.9145 21.71 20.2845C21.8 20.3845 21.87 20.4945 21.92 20.6145C21.97 20.7345 22 20.8645 22 20.9945C22 21.2545 21.89 21.5145 21.71 21.7045C21.52 21.8845 21.26 21.9945 21 21.9945Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};
